import ReportReason from '@pkgs/shared/enums/ReportReason';

const LABEL_MAP: Record<ValueOf<typeof ReportReason>, string> = {
	[ReportReason.UGLY]: 'It’s just ugly',
	[ReportReason.COPYRIGHT_INFRINGEMENT]: 'Copyright infringement',
	[ReportReason.NUDITY]: 'Nudity',
	[ReportReason.OFFENSIVE]: 'Offensive',
} as const;

export const getReportReasonLabel = (reason: ValueOf<typeof ReportReason>) => {
	return LABEL_MAP[reason] || '';
};
