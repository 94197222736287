import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type InviteUsersToBoardMutationMutation,
	type InviteUsersToBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import BoardCollaboratorFragment from './fragments/BoardCollaboratorFragment';
import InviteFragment from './fragments/InviteFragment';

const InviteUsersToBoardMutation: TypedDocumentNode<
	InviteUsersToBoardMutationMutation,
	InviteUsersToBoardMutationMutationVariables
> = gql`
	${BoardCollaboratorFragment}
	${InviteFragment}

	mutation InviteUsersToBoardMutation($input: InviteUsersToBoardInput!) {
		inviteUsersToBoard(input: $input) {
			_id
			collaborators {
				...BoardCollaboratorFragment
			}
			invites {
				...InviteFragment
			}
		}
	}
`;

export default InviteUsersToBoardMutation;
