import SVPaypalPaymentButtonContainer from '@apps/www/src/www/containers/SVPaypalPaymentButtonContainer';
import SVStripePaymentButtonContainer from '@apps/www/src/www/containers/SVStripePaymentButtonContainer';
import { sendGTMEvent } from '@next/third-parties/google';
import { SVButtonSIZES } from '@pkgs/shared-client/components/SVButton';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import config from '@pkgs/shared-client/config';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BillingCycle from '@pkgs/shared/enums/BillingCycle';
import PaymentButtonType from '@pkgs/shared/enums/PaymentButtonType';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import { getPlanMonthlyExtraUnitPrice, getUpgradePageTierConfig } from '@pkgs/shared/plans';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const _SVCheckoutSubscriptionModal = ({ tier }: { tier: ValueOf<typeof SubscriptionTier> }) => {
	const close = useModalClose();
	const [billingCycle, setBillingCycle] = useState<ValueOf<typeof BillingCycle>>(
		BillingCycle.YEARLY,
	);

	const planConfig = getUpgradePageTierConfig(config, tier);
	const plan = planConfig.getPlan(billingCycle);

	const handleAnnualClick = useEventCallback(() => {
		setBillingCycle(BillingCycle.YEARLY);
	});

	const handleMonthlyClick = useEventCallback(() => {
		setBillingCycle(BillingCycle.MONTHLY);
	});

	useEffect(() => {
		if (plan) {
			sendGTMEvent('start_checkout');
		}
	}, [plan]);

	const yearlyPlan = planConfig.getPlan(BillingCycle.YEARLY);
	const monthlyPlan = planConfig.getPlan(BillingCycle.MONTHLY);

	// Close modal if no plan (like ENTERPRISE)
	if (!plan || !yearlyPlan || !monthlyPlan) {
		close();
		return null;
	}

	const yearlyExtraUnitPrice = getPlanMonthlyExtraUnitPrice(yearlyPlan);
	const monthlyExtraUnitPrice = getPlanMonthlyExtraUnitPrice(monthlyPlan);

	return (
		<>
			<SVModal.Title>Choose a billing cycle</SVModal.Title>
			<div className="-sm:gap-4 flex w-full flex-col gap-6">
				<div
					onClick={handleAnnualClick}
					className={twMerge(
						' -sm:p-4 -sm:rounded-md flex cursor-pointer items-center justify-between gap-6 rounded-2xl border-[1.5px] p-8',
						billingCycle === BillingCycle.YEARLY
							? 'disabled border-gray-400'
							: 'border-separator cursor-pointer',
					)}
				>
					<div className="-sm:gap-0 flex flex-col gap-2">
						<h3 className="type-subtitle font-semibold">Pay Annually</h3>
						{yearlyExtraUnitPrice !== 0 ? (
							<p className="type-small text-secondary">
								${yearlyExtraUnitPrice} each extra seat
							</p>
						) : null}
					</div>
					<div className="text-right">
						<span className="type-subtitle font-semibold">
							{planConfig.getMonthlyPrice(BillingCycle.YEARLY)}&nbsp;/&nbsp;mo
						</span>
						<span className="type-label text-brand block">
							{planConfig.getDiscount(BillingCycle.YEARLY)}
							&nbsp;
						</span>
					</div>
				</div>
				<div
					onClick={handleMonthlyClick}
					className={twMerge(
						'border-separator -sm:p-4 -sm:rounded-md flex cursor-pointer items-center justify-between gap-6 rounded-2xl border-[1.5px] p-8',
						billingCycle === BillingCycle.MONTHLY
							? 'disabled border-gray-400'
							: 'border-separator cursor-pointer',
					)}
				>
					<div className="-sm:gap-0 flex flex-col gap-2">
						<h3 className="type-subtitle font-semibold">Pay Monthly</h3>
						{monthlyExtraUnitPrice !== 0 ? (
							<p className="type-small text-secondary">
								${monthlyExtraUnitPrice} each extra seat
							</p>
						) : null}
					</div>
					<div className="text-right">
						<span className="type-subtitle font-semibold">
							{planConfig.getMonthlyPrice(BillingCycle.MONTHLY)}&nbsp;/&nbsp;mo
						</span>
						<span className="type-label text-brand block">
							{planConfig.getDiscount(BillingCycle.MONTHLY)}
							&nbsp;
						</span>
					</div>
				</div>
			</div>
			<div className="-sm:flex-col -sm:items-stretch flex w-full items-center gap-4 pt-2">
				<div className="flex-1">
					<SVStripePaymentButtonContainer
						id="pay-with-card"
						size={SVButtonSIZES.PAYMENT}
						className="w-full rounded-full"
						buttonType={PaymentButtonType.SUBSCRIBE}
						plan={billingCycle === BillingCycle.MONTHLY ? monthlyPlan : yearlyPlan}
					/>
				</div>
				<div className="flex-1">
					<SVPaypalPaymentButtonContainer
						className="w-full"
						buttonType={PaymentButtonType.SUBSCRIBE}
						plan={billingCycle === BillingCycle.MONTHLY ? monthlyPlan : yearlyPlan}
					/>
				</div>
			</div>
		</>
	);
};

const SVCheckoutSubscriptionModal = SVModal.create(_SVCheckoutSubscriptionModal, {
	className: 'w-[480px] -md:w-full',
});

export default SVCheckoutSubscriptionModal;
