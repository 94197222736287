import { useMutation } from '@apollo/client';
import {
	type Board,
	type BoardCollaborator,
	type InviteFragmentFragment,
	type Team,
	type User,
} from '@apps/www/src/__generated__/graphql';
import UpdateBoardUserRoleMutation from '@apps/www/src/www/queries/UpdateBoardUserRoleMutation';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import IconCaretSVG from '@pkgs/shared-client/img/icon-caret-inlined.svg';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import clsx from 'clsx';
import React from 'react';
import SVA from './SVA';
import SVAvatar from './SVAvatar';
import SVButton, { SVButtonSIZES, SVButtonUSES } from './SVButton';
import SVCheck from './SVCheck';
import SVFlexSpacer from './SVFlexSpacer';
import SVIconButton from './SVIconButton';
import SVLink from './SVLink';

type UserFragment = {
	_id: User['_id'];
	name: User['name'];
	avatarURL: User['avatarURL'];
	username: User['username'];
	url: User['url'];
};

// TODO: Double check if { user: UserFragment } is necessary here, BoardCollaborator might be enough
type LikeUser = UserFragment | { user: UserFragment } | InviteFragmentFragment | BoardCollaborator;

const _Status = (props: OnlyChildrenProps) => (
	<div className="type-label text-muted flex-shrink-0" {...props} />
);

const _ItemWrapper = ({
	children,
	...props
}: React.PropsWithChildren<
	EmptyObject | { item?: LikeUser; onSelect: (item: LikeUser) => void | undefined }
>) => (
	<li
		className={clsx(
			'flex items-center justify-between space-x-4 text-left',
			Boolean('onSelect' in props && props.onSelect) && 'group cursor-pointer',
		)}
		onClick={
			'item' in props && props.item && props.onSelect
				? () => {
						if (props.item) {
							props.onSelect?.(props.item);
						}
				  }
				: undefined
		}
	>
		{children}
	</li>
);

const firstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const _ToggleUserPrivileges = ({
	role,
	userID,
	boardID,
}: {
	role: ValueOf<typeof BoardUserRole>;
	userID: User['_id'];
	boardID: Board['_id'];
}) => {
	const [updateBoardUserRole] = useMutation(UpdateBoardUserRoleMutation);

	const handleUpdateBoardUserRole = (
		targetRole: ValueOf<typeof BoardUserRole>,
		userID: User['_id'],
		boardID: Board['_id'],
	) => {
		updateBoardUserRole({
			variables: {
				input: {
					boardID,
					userID,
					role: targetRole,
				},
			},
		});
	};

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			positionStrategy={SVDropdown.POSITION_STRATEGIES.DYNAMIC}
			renderTrigger={({ isOpen: _, ...props }) => (
				<div className="type-label text-muted flex-center flex space-x-1">
					<_Status>{firstLetter(role)}</_Status>{' '}
					<span>
						<SVIconButton
							iconClassName="w-[12px] h-[12px] min-w-[12px] min-h-[12px] text-muted"
							src={IconCaretSVG}
							{...props}
						/>
					</span>
				</div>
			)}
			className="cursor-pointer"
			renderContent={() => (
				<SVDropdownContent.Links>
					{Object.keys(BoardUserRole).map(
						(roleMap) =>
							BoardUserRole[roleMap] !== role &&
							BoardUserRole[roleMap] !== BoardUserRole.OWNER && (
								<SVDropdownContent.Links.Item
									onClick={() =>
										handleUpdateBoardUserRole(
											BoardUserRole[roleMap],
											userID,
											boardID,
										)
									}
									key={roleMap}
								>
									Make {firstLetter(BoardUserRole[roleMap])}
								</SVDropdownContent.Links.Item>
							),
					)}
				</SVDropdownContent.Links>
			)}
		/>
	);
};

const _ItemUser = ({
	item,
	isInvitation,
	isSelect,
}: {
	item: LikeUser;
	isInvitation?: boolean;
	isSelect?: boolean;
}) => {
	const user: UserFragment | null =
		'user' in item && item.user ? item.user : (item as UserFragment);

	return (item as InviteFragmentFragment).email ? (
		<span className={clsx('flex h-12 min-w-0 items-center', isInvitation && 'opacity-60')}>
			<span className="truncate">{(item as InviteFragmentFragment).email}</span>
		</span>
	) : (
		<SVA
			Component={SVLink}
			className={clsx(
				'group flex min-w-0 items-center space-x-2',
				isSelect && 'pointer-events-none',
			)}
			to={user.url}
			target="_blank"
		>
			<SVAvatar
				src={user.avatarURL}
				className={clsx('h-12 w-12', isInvitation && 'opacity-60')}
			/>
			<span className={clsx('flex min-w-0 flex-col', isInvitation && 'opacity-60')}>
				<span className="group-hover:text-muted truncate">{user.name}</span>
				<span className="type-small text-muted truncate group-hover:text-gray-500">{`@${user.username}`}</span>
			</span>
		</SVA>
	);
};

const SelectItem = ({
	item,
	isSelected,
	onSelect,
}: {
	item: LikeUser;
	isSelected: boolean;
	onSelect: ((item: LikeUser) => void) | undefined;
}) => (
	<_ItemWrapper item={item} onSelect={onSelect}>
		<_ItemUser item={item} isSelect={true} />
		<SVFlexSpacer />
		<SVCheck className="flex-shrink-0" isSelected={isSelected} />
	</_ItemWrapper>
);

type Props = {
	item: LikeUser;
	isInvitation?: boolean;
	authUserID: User['_id'];
	acceptURL?: Board['acceptURL'] | Team['acceptURL'];
	rejectURL?: Board['rejectURL'] | Team['rejectURL'];
	leaveURL?: string;
	onRemove?: (_item: LikeUser) => void;
	boardID?: Board['_id'];
};

const Item = ({
	item,
	isInvitation,
	authUserID,
	acceptURL,
	rejectURL,
	leaveURL,
	onRemove,
	boardID,
}: Props) => {
	const user: UserFragment | null =
		'user' in item && item.user ? item.user : (item as UserFragment);

	return (
		<_ItemWrapper>
			<_ItemUser item={item} isInvitation={isInvitation} />
			<SVFlexSpacer />
			{!isInvitation &&
				authUserID === user._id &&
				leaveURL &&
				(item as BoardCollaborator).role !== BoardUserRole.OWNER && (
					<SVButton
						className="flex-shrink-0"
						Component={SVLink}
						to={leaveURL}
						size={SVButtonSIZES.TINY}
						use={SVButtonUSES.PRIMARY}
					>
						Leave board
					</SVButton>
				)}
			{(item as BoardCollaborator).role ? (
				(item as BoardCollaborator).role === BoardUserRole.OWNER ||
				!onRemove ||
				authUserID === user._id ? (
					<_Status>{firstLetter((item as BoardCollaborator).role)}</_Status>
				) : (
					<_ToggleUserPrivileges
						role={(item as BoardCollaborator).role}
						userID={user._id}
						boardID={boardID as Board['_id']}
					/>
				)
			) : (
				isInvitation &&
				(authUserID === user._id && acceptURL && rejectURL ? (
					<div className="flex items-center space-x-2">
						<SVButton
							Component={SVLink}
							to={acceptURL}
							size={SVButtonSIZES.TINY}
							use={SVButtonUSES.PRIMARY}
						>
							Accept
						</SVButton>
						<SVButton Component={SVLink} to={rejectURL} size={SVButtonSIZES.TINY}>
							Reject
						</SVButton>
					</div>
				) : (
					<_Status>Pending</_Status>
				))
			)}
			{onRemove &&
				authUserID !== user._id &&
				(item as BoardCollaborator).role !== BoardUserRole.OWNER && (
					<SVIconButton
						iconClassName="w-[12px] h-[12px] min-w-[12px] min-h-[12px]"
						src={IconCloseSVG}
						label="Remove user"
						onClick={onRemove.bind(null, item)}
					/>
				)}
		</_ItemWrapper>
	);
};

const SVModalUsersList = ({ children }: OnlyChildrenProps) => (
	<ul className="-sm:w-full block w-[380px] space-y-4">{children}</ul>
);

SVModalUsersList.SelectItem = SelectItem;
SVModalUsersList.Item = Item;

export default SVModalUsersList;
