import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type DeleteBoardMutationMutation,
	type DeleteBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import BoardFragment from './fragments/BoardFragment';

const DeleteBoardMutation: TypedDocumentNode<
	DeleteBoardMutationMutation,
	DeleteBoardMutationMutationVariables
> = gql`
	${BoardFragment}

	mutation DeleteBoardMutation($input: DeleteBoardInput!) {
		deleteBoard(input: $input) {
			auth {
				user {
					_id
					boards {
						...BoardFragment
					}
					boardsCount
					itemsCount
				}
				settings {
					_id
					needsUpgradeReason
				}
			}
			assets {
				_id
				ownBoards {
					_id
				}
			}
			deletedItemIDs
		}
	}
`;

export default DeleteBoardMutation;
