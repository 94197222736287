import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type UpdateBoardMutationMutation,
	type UpdateBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const updateBoardMutation: TypedDocumentNode<
	UpdateBoardMutationMutation,
	UpdateBoardMutationMutationVariables
> = gql`
	mutation UpdateBoardMutation($input: UpdateBoardInput!) {
		updateBoard(input: $input) {
			board {
				_id
				isPrivate
				isHidden
				name
				description
				isTeamEditorAnEditor
			}
			auth {
				user {
					_id
					boardsCount
					itemsCount
				}
				settings {
					_id
					needsUpgradeReason
				}
			}
		}
	}
`;

export default updateBoardMutation;
