import { useQuery } from '@apollo/client';
import SVNav from '@apps/www/src/www/components/SVNav';
import SVNotificationsIconContainer from '@apps/www/src/www/containers/SVNotificationsIconContainer';
import { emitTopNavBottomPosition } from '@apps/www/src/www/helpers/topNavBottomPositionEmitter';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import useAuthHasAtLeastProPrivileges from '@apps/www/src/www/hooks/useAuthHasAtLeastProPrivileges';
import useAuthHasEnterprisePrivileges from '@apps/www/src/www/hooks/useAuthHasEnterprisePrivileges';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthSubscriptionIsExpired from '@apps/www/src/www/hooks/useAuthSubscriptionIsExpired';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useCanSeeBillingPage from '@apps/www/src/www/hooks/useCanSeeBillingPage';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useIsSmallNav from '@apps/www/src/www/hooks/useIsSmallNav';
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import SVNewBoardModal from '@apps/www/src/www/modals/SVNewBoardModal';
import SiteQuery from '@apps/www/src/www/queries/SiteQuery';
import { type RootState } from '@apps/www/src/www/reducers';
import { toggleEditing } from '@apps/www/src/www/reducers/grid';
import { addFiles } from '@apps/www/src/www/reducers/gridUpload';
import { closeNav, openGridSettings, openGridUpload, openNav } from '@apps/www/src/www/reducers/ui';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVStickyBar from '@pkgs/shared-client/components/SVStickyBar';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const _SVNavContainer = () => {
	const stickyBarRef = useRef<SVStickyBar>(null);
	const isLoggedIn = useIsLoggedIn();
	const [isDarkMode, setDarkMode] = usePersistentSetting(PersistentSettingKeys.DARK_MODE);
	const [_, setOnboardOpen] = useUIState(UIStateKeys.MODAL_ONBOARD_OPEN);
	const canSeeBillingPage = useCanSeeBillingPage();
	const hasProPrivileges = useAuthHasAtLeastProPrivileges();
	const authUser = useAuthUser(['avatarURL', 'url', 'name', 'itemsCount']);
	const authSettings = useAuthSettings(['email', 'unreadNotificationsCount']);
	const hasExpiredSubscription = Boolean(useAuthSubscriptionIsExpired());
	const [showGridControls] = useUIState(UIStateKeys.SHOW_GRID_CONTROLS);
	const router = useRouter();
	const { isEditing, isNavOpen } = useSelector((state: RootState) => ({
		isEditing: state.grid.isEditing,
		isNavOpen: state.ui.isNavOpen,
	}));
	const dispatch = useDispatch();
	const team = useAuthTeam();
	const userHasEnterprisePrivileges = useAuthHasEnterprisePrivileges();

	const { data } = useQuery(SiteQuery, {
		// Skip if not logged in, we can still render the page for non-logged in users.
		skip: !isLoggedIn,
	});
	const site = data?.site;

	const isSmallNav = useIsSmallNav();

	const showUpgrade =
		router.asPath.indexOf('/upgrade/') === -1 &&
		router.asPath.indexOf('/billing/') === -1 &&
		!isEditing;

	const handleToggleDarkMode = useEventCallback(() => {
		setDarkMode(!isDarkMode);
	});

	const handleOpenOnboard = useEventCallback(() => {
		setOnboardOpen(true);
	});

	const handleAddFiles = useEventCallback((files: FileList) =>
		dispatch(addFiles(Array.from(files))),
	);
	const handleCloseNav = useEventCallback(() => dispatch(closeNav()));
	const handleOpenGridSettings = useEventCallback(() => dispatch(openGridSettings()));
	const handleOpenNav = useEventCallback(() => dispatch(openNav()));
	const handleToggleEditing = useEventCallback(() => dispatch(toggleEditing()));

	const handleOpenGridUpload = useAllowedFeatureCheckEvent(AllowedFeature.ADD_ITEM, () =>
		dispatch(openGridUpload()),
	);

	const handleNewBoardClick = useAllowedFeatureCheckEvent(AllowedFeature.ADD_BOARD, () =>
		SVModal.open(SVNewBoardModal, {
			ownershipType: 'user',
		}),
	);

	const handleHeightChange = useEventCallback((height: number) => {
		if (stickyBarRef.current) {
			stickyBarRef.current.resizeSpacer(height);
		}
	});

	const handleBottomPositionChange = useEventCallback((position: number) => {
		emitTopNavBottomPosition(position);
	});

	return (
		<SVStickyBar
			ref={stickyBarRef}
			position={isSmallNav ? SVStickyBar.POSITIONS.BOTTOM : SVStickyBar.POSITIONS.TOP}
			TopSpacerComponent={SVNav.TopSpacer}
			isOpen={isNavOpen}
			onBottomPositionChange={handleBottomPositionChange}
			render={({ isAtTop }) => (
				<SVNav
					isSmallNav={isSmallNav}
					isDarkMode={isDarkMode}
					isEditing={isEditing}
					showGridControls={showGridControls}
					onHeightChange={handleHeightChange}
					isLogged={isLoggedIn}
					isAtTop={isAtTop}
					isNavOpen={isNavOpen}
					locationPath={router.asPath}
					onNewBoardClick={handleNewBoardClick}
					onCloseNav={handleCloseNav}
					onOpenGridSettings={handleOpenGridSettings}
					onOpenGridUpload={handleOpenGridUpload}
					onOpenNav={handleOpenNav}
					onOpenOnboard={handleOpenOnboard}
					onSelectFiles={handleAddFiles}
					onToggleDarkMode={handleToggleDarkMode}
					onToggleEditing={handleToggleEditing}
					showUpgrade={showUpgrade}
					userAvatarURL={authUser?.avatarURL || null}
					userCanSeeBillingPage={canSeeBillingPage}
					userHasProPrivileges={hasProPrivileges}
					userHasEnterprisePrivileges={userHasEnterprisePrivileges}
					userHasExpiredSubscription={hasExpiredSubscription}
					userURL={authUser?.url || ''}
					userName={authUser?.name || ''}
					userItemsCount={authUser?.itemsCount || null}
					NotificationsComponent={SVNotificationsIconContainer}
					team={team}
					site={site || null}
					userEmail={authSettings?.email || ''}
					unreadNotificationsCount={authSettings?.unreadNotificationsCount || 0}
				/>
			)}
		/>
	);
};

const SVNavContainer = React.memo(_SVNavContainer);

export default SVNavContainer;
