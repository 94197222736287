import React, { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const WIDTHS = {
	DEFAULT: 'default',
	COMPACT: 'compact',
	FULL: 'full',
} as const;

const WIDTHS_VALUES = {
	[WIDTHS.DEFAULT]: 'mx-[var(--page-margin)]',
	[WIDTHS.COMPACT]: 'mx-[calc(var(--page-margin)*4)]',
	[WIDTHS.FULL]: 'mx-[0]',
};

type Props = React.PropsWithChildren<{
	className?: string;
	width?: ValueOf<typeof WIDTHS>;
	style?: React.HTMLProps<HTMLDivElement>['style'];
	id?: React.HTMLProps<HTMLDivElement>['id'];
}>;

const SVPageMargins = ({ className, width = WIDTHS.DEFAULT, style, children, id }: Props) => {
	const containerRef = useRef<HTMLDivElement>(null);

	return (
		<div
			className={twMerge('flex-grow', WIDTHS_VALUES[width], className)}
			style={style}
			id={id}
			ref={containerRef}
		>
			{children}
		</div>
	);
};

export default SVPageMargins;
