import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type RecentSearchesQueryQuery,
	type RecentSearchesQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const RecentSearchesQuery: TypedDocumentNode<
	RecentSearchesQueryQuery,
	RecentSearchesQueryQueryVariables
> = gql`
	query RecentSearchesQuery {
		recentSearches {
			_id
			query
			deleted
		}
	}
`;

export default RecentSearchesQuery;
