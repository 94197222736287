/**
 * Use this hook to check if user has permission to perform an action that is
 * restricted to users with specific subscription plans.
 */
import useAuthActiveSubscriptionTiers from '@apps/www/src/www/hooks/useAuthActiveSubscriptionTiers';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import config from '@pkgs/shared-client/config';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import subscriptionTiersHasSubscriptionTierPrivileges from '@pkgs/shared/helpers/subscriptionTiersHasSubscriptionTierPrivileges';

// Returns true if user is allowed to perform given action
export const PERMISSION_MAP: Record<
	ValueOf<typeof AllowedFeature>,
	(info: {
		tiers: Array<ValueOf<typeof SubscriptionTier>>;
		itemsCount: number;
		boardsCount: number;
	}) => boolean
> = {
	[AllowedFeature.ADD_ITEM]: ({ tiers, itemsCount }) =>
		itemsCount < config.payment.maxFreeItems ||
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.ADD_BOARD]: ({ tiers, boardsCount }) =>
		boardsCount < config.payment.maxFreeBoards ||
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.PRIVATE_ITEM]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.PRIVATE_BOARD]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.SHARED_BOARD]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.PRINT]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.RIGHT_CLICK]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.AUTO_PLAY_GIF]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.DROPBOX_BACKUP]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.PRINT_GRID]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.FULL_FEED]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
	[AllowedFeature.MANAGE_TEAM]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.TEAM),
	[AllowedFeature.CREATE_SITE]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.SITE_MAKER),
	[AllowedFeature.HIDE_ASSETS]: ({ tiers }) =>
		subscriptionTiersHasSubscriptionTierPrivileges(tiers, SubscriptionTier.PRO),
};

export enum ALLOWED_STATES {
	ALLOWED = 'ALLOWED',
	NOT_ALLOWED = 'NOT_ALLOWED',
	LOGGED_OUT = 'LOGGED_OUT',
}

export default function useAllowedFeatureState(
	allowedFeature: ValueOf<typeof AllowedFeature> | null | undefined,
) {
	const isLoggedIn = useIsLoggedIn();
	const authUser = useAuthUser(['itemsCount', 'boardsCount']);
	const tiers = useAuthActiveSubscriptionTiers();

	if (allowedFeature) {
		const isAllowed = PERMISSION_MAP[allowedFeature];

		if (!isLoggedIn || !authUser) {
			return ALLOWED_STATES.LOGGED_OUT;
		} else if (
			!isAllowed({
				tiers,
				itemsCount: authUser?.itemsCount || 0,
				boardsCount: authUser?.boardsCount || 0,
			})
		) {
			return ALLOWED_STATES.NOT_ALLOWED;
		}
	}

	return ALLOWED_STATES.ALLOWED;
}
