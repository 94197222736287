import { type ClientConfigType } from '@pkgs/shared/config/getClientConfig';
import {
	GRID_COLUMNS,
	GRID_SPACINGS,
	VIEWPORTS,
	type GridColumnsConfigID,
	type GridSpacingsConfigID,
	type ViewportNameType,
} from '@pkgs/shared/constants';

declare const ___SAVEE_CONFIG;
declare const ___SAVEE_CONFIG_OVERWRITE;

let config: ClientConfigType = ___SAVEE_CONFIG;

if (typeof ___SAVEE_CONFIG_OVERWRITE === 'object' && ___SAVEE_CONFIG_OVERWRITE != null) {
	config = ___SAVEE_CONFIG_OVERWRITE;
}

if (config.env == 'development' || config.env == 'production-remote') {
	// const hostname =
	// 	global.location && global.location.hostname
	// 		? global.location.hostname
	// 		: 'localhost';

	// config.baseURL = config.baseURL.split('localhost').join(hostname);
	// config.staticURL = config.staticURL.split('localhost').join(hostname);
	// config.apiURL = config.apiURL.split('localhost').join(hostname);
	// config.graphqlURL = config.graphqlURL.split('localhost').join(hostname);
	// config.storageURL = config.storageURL.split('localhost').join(hostname);

	if (global.location && global.location.port === '6006') {
		config.staticURL = global.location.origin + '/';
	}
}

export default config;

// Helpers

export function spacingsRatioToConfigID(ratio: number): GridSpacingsConfigID {
	const spacings = GRID_SPACINGS;

	return (spacings[0].id +
		Math.floor(
			ratio * (spacings[spacings.length - 1].id - spacings[0].id),
		)) as GridSpacingsConfigID;
}

export function spacingsConfigIDToRatio(value: GridSpacingsConfigID): number {
	const spacings = GRID_SPACINGS;

	return (value - spacings[0].id) / (spacings[spacings.length - 1].id - spacings[0].id);
}

export function columnsRatioToConfigID(
	viewportName: ViewportNameType,
	ratio: number,
): GridColumnsConfigID {
	const columns = GRID_COLUMNS;
	const viewport = VIEWPORTS[viewportName];

	if (!viewport.minColumns || !viewport.maxColumns) {
		return columns[viewport.columns].id;
	}

	const minColumnConfig = columns.find((columnConfig) => {
		return columnConfig.id == viewport.minColumns;
	});

	const midColumnConfig = columns.find((columnConfig) => {
		return columnConfig.id == viewport.columns;
	});

	const maxColumnConfig = columns.find((columnConfig) => {
		return columnConfig.id == viewport.maxColumns;
	});

	const minColumnIndex = minColumnConfig ? columns.indexOf(minColumnConfig) : 0;
	const midColumnIndex = midColumnConfig ? columns.indexOf(midColumnConfig) : columns.length;
	const maxColumnIndex = maxColumnConfig ? columns.indexOf(maxColumnConfig) : columns.length;

	let columnIndex = midColumnIndex;

	if (ratio <= 0.5) {
		columnIndex =
			minColumnIndex + Math.round((ratio / 0.5) * (midColumnIndex - minColumnIndex));
	} else {
		columnIndex =
			midColumnIndex + Math.round(((ratio - 0.5) / 0.5) * (maxColumnIndex - midColumnIndex));
	}

	return columns[columnIndex].id;
}

export function columnsConfigIDToRatio(
	viewportName: ViewportNameType,
	value: GridColumnsConfigID,
): number {
	const columns = GRID_COLUMNS;
	const viewport = VIEWPORTS[viewportName];

	if (!viewport.minColumns || !viewport.maxColumns) {
		return 0.5;
	}

	const minColumnConfig = columns.find((columnConfig) => {
		return columnConfig.id == viewport.minColumns;
	});

	const midColumnConfig = columns.find((columnConfig) => {
		return columnConfig.id == viewport.columns;
	});

	const maxColumnConfig = columns.find((columnConfig) => {
		return columnConfig.id == viewport.maxColumns;
	});

	const valueColumnConfig = columns.find((columnConfig) => {
		return columnConfig.id == value;
	});

	const minColumnIndex = minColumnConfig ? columns.indexOf(minColumnConfig) : 0;
	const midColumnIndex = midColumnConfig ? columns.indexOf(midColumnConfig) : columns.length;
	const maxColumnIndex = maxColumnConfig ? columns.indexOf(maxColumnConfig) : columns.length;
	const valueColumnIndex = valueColumnConfig ? columns.indexOf(valueColumnConfig) : 0;

	let ratio = 0;

	if (midColumnConfig?.id !== undefined && value <= midColumnConfig.id) {
		ratio = (0.5 * (valueColumnIndex - minColumnIndex)) / (midColumnIndex - minColumnIndex);
	} else {
		ratio =
			0.5 + (0.5 * (valueColumnIndex - midColumnIndex)) / (maxColumnIndex - midColumnIndex);
	}

	return ratio;
}
