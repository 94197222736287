import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useCanSeeUpgradeWarning from '@apps/www/src/www/hooks/useCanSeeUpgradeWarning';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import NeedsUpgradeReason from '@pkgs/shared/enums/NeedsUpgradeReason';

type Info = {
	reason: ValueOf<typeof NeedsUpgradeReason>;
};

const useUpgradeWarning = (): Info | null => {
	const isLoggedIn = useIsLoggedIn();
	const authSettings = useAuthSettings(['needsUpgradeReason']);
	const canSeeUpgradeWarning = useCanSeeUpgradeWarning();

	if (canSeeUpgradeWarning && isLoggedIn && authSettings) {
		if (authSettings.needsUpgradeReason) {
			if (authSettings.needsUpgradeReason === NeedsUpgradeReason.RENEW) {
				return {
					reason: authSettings.needsUpgradeReason,
				};
			} else {
				return {
					reason: authSettings.needsUpgradeReason,
				};
			}
		}
	}

	return null;
};

export default useUpgradeWarning;
