/**
 * This hook encapsulates all the logic for saving items. Use it whenever rendering a save button.
 * Don't forget to wrap the component in SVWithTooltipErrorBoundary and pass down the
 * `showTooltipError` function to trigger the tooltip when there's an error.
 */
import { useApolloClient, useMutation } from '@apollo/client';
import { ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import AddItemsToBoardMutation from '@apps/www/src/www/queries/AddItemsToBoardMutation';
import BoardItemsQuery from '@apps/www/src/www/queries/BoardItemsQuery';
import DeleteItemsMutation from '@apps/www/src/www/queries/DeleteItemsMutation';
import RemoveItemsFromBoardMutation from '@apps/www/src/www/queries/RemoveItemsFromBoardMutation';
import SaveItemsMutation from '@apps/www/src/www/queries/SaveItemsMutation';
import TeamFeedItemsQuery from '@apps/www/src/www/queries/TeamFeedItemsQuery';
import fieldNameFromQuery from '@pkgs/shared-client/helpers/fieldNameFromQuery';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useEffect } from 'react';

export default function useSaveItem({
	items,
	showTooltipError,
}: {
	items: ItemFragmentFragment[];
	showTooltipError: (error: Error | undefined) => void;
}) {
	const client = useApolloClient();
	const isLoggedIn = useIsLoggedIn();
	const authUser = useAuthUser(['username']);

	const [saveItems, { loading: saveLoading, error: saveError }] = useMutation(SaveItemsMutation);
	const [removeItemsFromBoard, { error: removeItemsFromBoardError }] = useMutation(
		RemoveItemsFromBoardMutation,
	);
	const [addItemsToBoard, { loading: addItemsToBoardLoading, error: addItemsToBoardError }] =
		useMutation(AddItemsToBoardMutation);
	const [deleteItems, { loading: deleteLoading, error: deleteError }] =
		useMutation(DeleteItemsMutation);

	const isSaved = !items.some((item) => !item.asset.isSaved); // true, unless there is one unsaved item
	const itemsCount = items.reduce((sum, item) => sum + item.asset.itemsCount, 0);
	const enableLongPress = isLoggedIn && !isSaved;
	const anyError = saveError || deleteError || removeItemsFromBoardError || addItemsToBoardError;

	// This controls if the `Save` button is loading or not, `removeItemsFromBoardLoading` is omitted on purpose.
	const anyLoading = saveLoading || deleteLoading || addItemsToBoardLoading;

	const toggleSave = useEventCallback(async (boardID: string | null = null) => {
		if (!items.length) {
			return;
		}

		if (authUser && boardID) {
			const areItemsInBoard = items.every((item) =>
				item.asset.ownBoards.some((board) => board._id === boardID),
			);

			if (areItemsInBoard) {
				// If `boardID` is provided, just remove from board, no need to delete
				await removeItemsFromBoard({
					variables: {
						input: {
							itemIDs: items.map((item) => item._id),
							boardID,
						},
					},
				});
			} else {
				await addItemsToBoard({
					variables: {
						input: {
							itemIDs: items.map((item) => item._id),
							boardID,
						},
					},
				});
			}

			// Evict board items query from cache
			const fieldName = fieldNameFromQuery(BoardItemsQuery, { boardID });

			if (fieldName) {
				client.cache.evict({
					id: 'ROOT_QUERY',
					fieldName,
					broadcast: false,
				});
			}
		} else if (!isSaved || !authUser) {
			await saveItems({
				variables: {
					input: {
						itemIDs: items.map((item) => item._id),
						boardID,
					},
				},
			});

			if (authUser) {
				// Remove team items feed query from cache to refetch when necessary.
				// In case the team feed includes the user own saves
				const fieldName = fieldNameFromQuery(TeamFeedItemsQuery);
				if (fieldName) {
					client.cache.evict({
						id: 'ROOT_QUERY',
						fieldName,
						broadcast: false,
					});
				}
			}
		} else {
			const { data } = await deleteItems({
				variables: {
					input: {
						itemIDs: items.map((item) => item._id),
					},
				},
			});

			// Evict own item from cache so lists get updated
			data?.deleteItems.deletedItemIDs.forEach((deletedItemID) => {
				client.cache.evict({
					id: client.cache.identify({ __typename: 'Item', _id: deletedItemID }),
				});
			});
		}

		client.cache.gc();
	});

	useEffect(() => {
		showTooltipError(anyError);
	}, [showTooltipError, anyError]);

	return {
		isSaved,
		itemsCount,
		isLoading: anyLoading,
		toggleSave,
		enableLongPress,
	};
}
