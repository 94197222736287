import childrenOnlyText from '@pkgs/shared-client/helpers/childrenOnlyText';
import matchLocation from '@pkgs/shared-client/helpers/matchLocation';
import IconLoadingSVG from '@pkgs/shared-client/img/icon-loading-inlined.svg';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import SVKeyboardKey, { formatTitleWithKeys, type KeyboardKeys } from './SVKeyboardKey';
import SVLink, { type Props as SVLinkProps } from './SVLink';
import SVToggle from './SVToggle';

const USES = {
	// Default: Used on the grid edit bar
	RETRACTABLE_BAR: 'retractable-bar',

	// Used on the main nav bar
	FEEDNAV: 'feednav',

	// Used on user page header tabs (Saves, Boards, Following, Followers)
	PAGENAV: 'pagenav',

	// Used on detail nav bar and search tabs (Saves, Users)
	TABSNAV: 'tabnav',
} as const;

// Only affects tabs nav
const TABSNAV_MAX_WIDTH_CLASSES = [
	'max-w-[150px] -smp:max-w-[100px]',
	'max-w-[300px] -smp:max-w-[200px]',
	'max-w-[450px] -smp:max-w-[300px]',
	'max-w-[600px] -smp:max-w-[400px]',
];

type ItemProps = React.PropsWithChildren<
	{
		use?: ValueOf<typeof USES>;
		keys?: KeyboardKeys;
		isLoading?: boolean;
		isCurrent?: boolean;
		isPressed?: boolean;
		isDisabled?: boolean;
		to?: SVLinkProps['to'] | undefined;
		title?: React.HTMLProps<HTMLButtonElement>['title'];
		target?: SVLinkProps['target'];
	} & (
		| ({
				Component: typeof SVLink;
		  } & React.ComponentPropsWithoutRef<typeof SVLink>)
		| {
				Component?: 'button';
				onClick: (event: React.UIEvent) => void;
		  }
	)
>;

const SVActionsItem = React.forwardRef<HTMLElement, ItemProps>(
	(
		{
			Component = 'button',
			keys,
			use = USES.RETRACTABLE_BAR,
			isLoading,
			isCurrent,
			isPressed,
			isDisabled,
			onClick,
			target,
			title,
			children,
			...props
		},
		forwardedRef,
	) => {
		if (isCurrent && use === USES.FEEDNAV) {
			Component = 'button';
		}

		return (
			<li
				// @ts-expect-error fix this later
				ref={forwardedRef}
				className={clsx(
					'flex-center relative flex-grow',
					(isLoading || isCurrent || isDisabled) && use !== USES.FEEDNAV && 'disabled',
				)}
			>
				<div className={clsx(use === USES.RETRACTABLE_BAR && 'px-3')}>
					{isLoading && (
						<span
							className="flex-center pointer-events-none absolute inset-0"
							key="wrapper"
							role="progressbar"
						>
							<IconLoadingSVG className="scale-75" />
						</span>
					)}
					<div
						className={clsx(
							'duration-over transition-opacity ease-out',
							isLoading && 'opacity-30',
						)}
						key="label"
					>
						{keys && keys.length && onClick && (
							<SVKeyboardKey key="keys" keys={keys} onTrigger={onClick} />
						)}
						{/* @ts-expect-error */}
						<Component
							className={twMerge(
								clsx(
									'duration-over relative box-border block font-normal leading-none transition-colors ease-out',

									// Type
									use === USES.RETRACTABLE_BAR && 'type-base',
									use === USES.PAGENAV && 'type-base',
									use === USES.FEEDNAV && 'type-pagenav',
									use === USES.TABSNAV && 'type-subnav',

									// Color
									use === USES.RETRACTABLE_BAR
										? 'text-secondary'
										: 'text-gray-400',

									// Hover
									use === USES.RETRACTABLE_BAR
										? 'hover:text-muted'
										: 'hover:text-gray-200',

									// States
									(isCurrent || isPressed) &&
										(use === USES.RETRACTABLE_BAR
											? 'font-normal text-gray-600'
											: 'text-primary font-medium'),
									isDisabled && 'text-gray-700',
								),
							)}
							key="button"
							title={formatTitleWithKeys(title || childrenOnlyText(children), keys)}
							onClick={onClick}
							scroll={Component === SVLink ? false : undefined}
							target={target}
							{...props}
						>
							{children}
						</Component>
					</div>
				</div>
			</li>
		);
	},
);

const SVActionsNavItem = React.forwardRef<
	HTMLLIElement,
	React.PropsWithChildren<{
		to: SVLinkProps['to'];
		matchURL?: SVLinkProps['to'];
		target?: SVLinkProps['target'];
	}>
>(({ to, matchURL, ...props }, forwardedRef) => {
	const router = useRouter();

	return (
		<SVActionsItem
			ref={forwardedRef}
			Component={SVLink}
			to={to}
			isCurrent={
				!!(
					matchLocation(router.asPath, String(to)) ||
					(matchURL && matchLocation(router.asPath, String(matchURL)))
				)
			}
			{...props}
		/>
	);
});

const SVActionsItemToggle = React.forwardRef<HTMLLIElement, ItemProps>(
	({ children, ...props }, forwardedRef) => (
		<SVActionsItem ref={forwardedRef} {...props}>
			<SVToggle
				isPressed={props.isPressed || false}
				isDisabled={props.isDisabled}
				size={SVToggle.SIZES.SMALL}
			>
				{children}
			</SVToggle>
		</SVActionsItem>
	),
);

const SVActions = ({
	use = USES.RETRACTABLE_BAR,
	className,
	children,
}: React.PropsWithChildren<{
	use?: ValueOf<typeof USES>;
	className?: string;
}>) => {
	// Pass down `use` prop to children
	const newChildren: React.ReactNode[] =
		React.Children.map(children, (child, index) => {
			if (React.isValidElement(child)) {
				return React.cloneElement(child, {
					key: `item ${index}`,
					// @ts-expect-error ?
					use,
				});
			}
		}) || [];

	const childrenCount = React.Children.count(children);

	return (
		<ul
			className={twMerge(
				clsx(
					'-smp:min-h-[22px] relative flex min-h-[28px] break-normal [hyphens:initial]',
					use === USES.TABSNAV && 'mx-auto w-full justify-between',
					use === USES.TABSNAV && '-smp:gap-x-6 gap-x-8',
					use === USES.FEEDNAV && '-md:gap-x-4 gap-x-6',
					use === USES.PAGENAV && '-smp:gap-x-4 h-auto gap-x-6',
					use === USES.TABSNAV &&
						TABSNAV_MAX_WIDTH_CLASSES[
							Math.max(TABSNAV_MAX_WIDTH_CLASSES.length, Math.min(1, childrenCount)) -
								1
						],
				),
				className,
			)}
		>
			{newChildren}
		</ul>
	);
};

// TODO: onlyUpdateForProps

SVActions.Item = SVActionsItem;
SVActions.ItemToggle = SVActionsItemToggle;
SVActions.NavItem = SVActionsNavItem;

SVActions.USES = USES;

export default SVActions;
