import { useFragment } from '@apollo/client';
import { type Item, type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVGridDetailOverlayContainer from '@apps/www/src/www/containers/SVGridDetailOverlayContainer';
import SVGridDetailStandaloneContainer from '@apps/www/src/www/containers/SVGridDetailStandaloneContainer';
import SVWithNoSSR from '@apps/www/src/www/containers/SVWithNoSSR';
import filterRealItems from '@apps/www/src/www/helpers/filterRealItems';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import ItemFragment from '@apps/www/src/www/queries/fragments/ItemFragment';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVOverlay from '@pkgs/shared-client/components/SVOverlay';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import parseQueryParam from '@pkgs/shared/helpers/parseQueryParam';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import usePrevious from 'react-use/lib/usePrevious';

const _Overlay = SVOverlay.create(
	({
		item,
		items,
		itemShortID,
		closeURL,
		shouldScrollToItem,
	}: Pick<
		React.ComponentProps<typeof SVGridDetailOverlayContainer>,
		'items' | 'closeURL' | 'shouldScrollToItem'
	> & {
		itemShortID: Item['shortID'];
		item: React.ComponentProps<typeof SVGridDetailOverlayContainer>['item'] | undefined;
	}) => {
		// This is when we open from the grid or related grid
		if (item) {
			return (
				<SVGridDetailOverlayContainer
					item={item}
					items={items}
					closeURL={closeURL}
					shouldScrollToItem={shouldScrollToItem}
				/>
			);
		}

		// If `item` is undefined, we are opening from somewhere else, like SVUsersList,
		// so we need to load the item details along with it.
		return <SVGridDetailStandaloneContainer itemShortID={itemShortID} closeURL={closeURL} />;
	},
);

const _SVGridDetailModalControllerInner = ({ itemShortID }: { itemShortID: string }) => {
	const router = useRouter();
	const [allCurrentPageGridItems] = useUIState(UIStateKeys.CURRENT_PAGE_GRID_ITEMS); // When coming from page grids
	const [detailGridItems] = useUIState(UIStateKeys.DETAIL_GRID_ITEMS); // When coming from other alternative grids, like related saves or user followers images
	const currentPageGridItems = filterRealItems(allCurrentPageGridItems || []);

	let item: ItemFragmentFragment | undefined = undefined;
	let items = currentPageGridItems;
	let itemSource: 'page-grid' | 'other' | undefined = undefined;

	if (itemShortID) {
		if (detailGridItems?.length) {
			item = detailGridItems.find((item) => item.shortID === itemShortID);
			if (item) {
				items = detailGridItems;
				itemSource = 'other';
			}
		}

		if (!item && currentPageGridItems?.length) {
			item = currentPageGridItems.find((item) => item.shortID === itemShortID);

			if (item) {
				itemSource = 'page-grid';
			}
		}
	}

	// When loading items from `UIStateKeys.DETAIL_GRID_ITEMS`, we need to keep up with the apollo cache
	// in case the data changes. Since `UIStateKeys.DETAIL_GRID_ITEMS` is static.
	const { data: detailItemFragment, complete } = useFragment<ItemFragmentFragment>({
		fragment: ItemFragment,
		from: {
			__typename: 'Item',
			_id: item?._id,
		},
	});

	// If we're able to read from the fragment, use it. As a fallback, use the item from `UIStateKeys.DETAIL_GRID_ITEMS`.
	if (detailItemFragment && complete && item?._id) {
		item = detailItemFragment;
	}

	const previousItemShortID = usePrevious(itemShortID);
	const previousDetailGridItems = usePrevious(detailGridItems);

	const { itemShortID: _, ...query } = router.query;

	const closeURL = {
		pathname: router.pathname,
		query,
	};

	// From esc key or click on background overlay
	const handleRequestClose = useEventCallback(() => {
		router.push(closeURL, undefined, { scroll: false });
	});

	// since here we are waiting for the animation to finish
	const handleAfterOpen = useEventCallback(() => {
		// only scroll to item if it came from the page grid
		if (item && itemSource === 'page-grid') {
			SVGridDetailOverlayContainer.scrollToItem(item);
		}
	});

	// When deleting and item is part of a grid, item comes as `undefined`, so we need to just close the overlay.
	if (
		itemShortID &&
		previousItemShortID === itemShortID &&
		!item &&
		previousDetailGridItems === detailGridItems
	) {
		handleRequestClose();
		return null;
	}

	return (
		<SVModal.Visible
			Component={_Overlay}
			item={item}
			items={items}
			itemShortID={itemShortID}
			closeURL={closeURL}
			onRequestClose={handleRequestClose}
			afterOpen={handleAfterOpen}
			shouldScrollToItem={itemSource === 'page-grid'}
		/>
	);
};

const _SVGridDetailModalController = () => {
	const router = useRouter();
	const [detailGridItems, setDetailGridItems] = useUIState(UIStateKeys.DETAIL_GRID_ITEMS);

	// Only opens as a modal if we are navigating using the hidden
	// query param and not on a standalone /i/[shortID]/ route
	const itemShortID = !router.pathname.startsWith('/i/')
		? router.query.itemShortID
			? parseQueryParam(router.query.itemShortID)
			: undefined
		: undefined;

	const hasItemShortID = !!itemShortID;
	const previousHasItemShortID = usePrevious(hasItemShortID);

	// Clear UIStateKeys.DETAIL_GRID_ITEMS when modal is closed
	useEffect(() => {
		if (previousHasItemShortID && !hasItemShortID && detailGridItems?.length) {
			setDetailGridItems([]);
		}
	}, [previousHasItemShortID, hasItemShortID, detailGridItems, setDetailGridItems]);

	if (!hasItemShortID) {
		return null;
	}

	return <_SVGridDetailModalControllerInner itemShortID={itemShortID} />;
};

const SVGridDetailModalController = SVWithNoSSR(_SVGridDetailModalController);

export default SVGridDetailModalController;
