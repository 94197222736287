import { useApolloClient } from '@apollo/client';
import { AuthQueryQuery } from '@apps/www/src/__generated__/graphql';
import useAuthUserID from '@apps/www/src/www/hooks/useAuthUserID';
import AuthQuery from '@apps/www/src/www/queries/AuthQuery';
import { GoogleAnalytics } from '@next/third-parties/google';
import config from '@pkgs/shared-client/config';
import { useEffect } from 'react';

function _UserIDUpdater({ initialUserID }: { initialUserID: string | undefined }) {
	const userID = useAuthUserID();

	// This covers the case when the user logs in or out during a session, we just restart the GA config with the user_id.
	useEffect(() => {
		if (!window.gtag || userID === initialUserID) {
			return;
		}

		if (userID) {
			window.gtag('config', config.googleAnalytics.id, {
				user_id: userID,
			});
		} else {
			window.gtag('config', config.googleAnalytics.id, { user_id: undefined });
		}
	}, [userID, initialUserID]);

	return null;
}

export default function SVAnalyticsController() {
	const client = useApolloClient();

	if (!config.googleAnalytics.enabled) {
		return null;
	}

	// Reading the query because we don't want to re-render when this changes...
	const data = client.readQuery<AuthQueryQuery>({ query: AuthQuery });
	const userID = data?.auth?.user?._id;

	return (
		<>
			<GoogleAnalytics
				gaId={config.googleAnalytics.id}
				gaOptions={userID ? { user_id: userID } : undefined}
			/>
			<_UserIDUpdater initialUserID={userID} />
		</>
	);
}
