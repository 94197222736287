import { type Board, type User } from '@apps/www/src/__generated__/graphql';
import SVA from './SVA';
import SVButton, { SVButtonSIZES, SVButtonUSES } from './SVButton';
import SVLink from './SVLink';

type Props = {
	userName: User['username'];
	userURL: User['url'];
	acceptURL: NonNullable<Board['acceptURL']>;
	rejectURL: NonNullable<Board['rejectURL']>;
};

const SVBoardInviteBanner = ({ userName, userURL, acceptURL, rejectURL }: Props) => (
	<div className="flex-center m-auto flex max-w-xl py-10">
		<div>
			<SVA className="font-semibold" Component={SVLink} to={userURL}>
				{userName}
			</SVA>{' '}
			invited you to collaborate on this board.
		</div>
		<div className="flex space-x-4 px-4">
			<SVButton
				Component={SVLink}
				to={acceptURL}
				size={SVButtonSIZES.SMALL}
				use={SVButtonUSES.PRIMARY}
			>
				Accept
			</SVButton>
			<SVButton Component={SVLink} to={rejectURL} size={SVButtonSIZES.SMALL}>
				Reject
			</SVButton>
		</div>
	</div>
);

export default SVBoardInviteBanner;
