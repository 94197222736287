/**
 * Given some information from a user, it returns the active subscription tiers.
 *
 * This is necessary to simulate subscriptions for `moderator`, `beta` and `hasFreeSiteMakerFeatureFlag` users.
 */

import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';

export default function getUserActiveSubscriptionTiersFromInfo(info: {
	moderator: boolean;
	beta: boolean;
	hasFreeSiteMakerFeatureFlag: boolean;
	activeSubscriptionTiers: ValueOf<typeof SubscriptionTier>[];
}): ValueOf<typeof SubscriptionTier>[] {
	if (info.moderator) {
		return [SubscriptionTier.SITE_MAKER, SubscriptionTier.ENTERPRISE];
	}

	if (
		info.hasFreeSiteMakerFeatureFlag &&
		!info.activeSubscriptionTiers.includes(SubscriptionTier.SITE_MAKER)
	) {
		return [...info.activeSubscriptionTiers, SubscriptionTier.SITE_MAKER];
	}

	if (info.beta) {
		return [SubscriptionTier.PRO];
	}

	return info.activeSubscriptionTiers;
}
