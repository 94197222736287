import { type BoardFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import SVEditBoardCollaboratorsModal from '@apps/www/src/www/modals/SVEditBoardCollaboratorsModal';
import SVModal from '@pkgs/shared-client/components/SVModal';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import { useRouter } from 'next/router';

type Props = {
	board: BoardFragmentFragment;
	isAdmin: boolean;
	render: (props: { onClick: (event: Event | React.UIEvent) => void }) => JSX.Element;
};

const SVEditBoardCollaboratorsActionContainer = ({ board, isAdmin, render }: Props) => {
	const router = useRouter();
	// Allow any user to at least see the modal even if not allowed to edit the board, nor not pro to have shared-boards
	const isAllowedToClickRegardless =
		!isAdmin && board.collaborators.length + board.invites.length > 1;

	const handleClick = useAllowedFeatureCheckEvent(
		isAllowedToClickRegardless ? null : AllowedFeature.SHARED_BOARD,
		() => {
			// If not admin and only 1 collaborator, redirect to user profile
			if (!isAdmin && board.collaborators.length === 1) {
				return router.push(board.collaborators[0].user.url);
			}

			SVModal.open(SVEditBoardCollaboratorsModal, {
				_id: board._id,
			});
		},
	);

	return <>{render({ onClick: handleClick })}</>;
};

export default SVEditBoardCollaboratorsActionContainer;
