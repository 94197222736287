/**
 * This is a Pub/Sub helper that emits the top nav position whenever it changes. It's not a hook
 * or a context because it updates too often and shouldn't cause re-renders.
 */

let subscribers: ((position: number) => void)[] = [];
let lastPosition = 0;

export function subscribeToTopNavBottomPosition(callback: (position: number) => void) {
	subscribers.push(callback);
}

export function unsubscribeFromTopNavBottomPosition(callback: (position: number) => void) {
	subscribers = subscribers.filter((cb) => cb !== callback);
}

export function getTopNavBottomPosition() {
	return lastPosition;
}

export function emitTopNavBottomPosition(position: number) {
	if (position !== lastPosition) {
		lastPosition = position;
		subscribers.forEach((cb) => cb(position));
	}
}
