import clsx from 'clsx';

const SIZES = {
	PAGE: 'page',
	DEFAULT: 'default',
} as const;

const defaultProps: {
	size: ValueOf<typeof SIZES>;
} = {
	size: SIZES.DEFAULT,
};

type Props = Partial<typeof defaultProps> & {
	size?: ValueOf<typeof SIZES>;
};

const SVLoadingIndicator = ({ size }: Props) => {
	return (
		<div
			role="progressbar"
			className={clsx(
				'flex-center text-primary',
				size === SIZES.PAGE &&
					'flex-center z-index-max bg-background p-nav-height fixed inset-0 h-full w-full',
				size !== SIZES.PAGE && 'px-12 py-20',
			)}
		>
			<span
				className={clsx(
					size === SIZES.DEFAULT && 'h-8 w-8',
					size === SIZES.PAGE && 'h-12 w-12',
					'animate-loading-indicator-grow bg-primary rounded-full',
				)}
			></span>
		</div>
	);
};

SVLoadingIndicator.defaultProps = defaultProps;

SVLoadingIndicator.SIZES = SIZES;

export default SVLoadingIndicator;
