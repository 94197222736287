import { useMutation, useQuery } from '@apollo/client';
import { Board } from '@apps/www/src/__generated__/graphql';
import useDebouncedCallback from '@apps/www/src/www/hooks/useDebouncedCallback';
import InviteUsersToBoardMutation from '@apps/www/src/www/queries/InviteUsersToBoardMutation';
import InviteUsersToTeamMutation from '@apps/www/src/www/queries/InviteUsersToTeamMutation';
import SuggestedInvitedUsersQuery from '@apps/www/src/www/queries/SuggestedInvitedUsersQuery';
import SVCenter from '@pkgs/shared-client/components/SVCenter';
import SVForm from '@pkgs/shared-client/components/SVForm';
import { SVInputTextRaw } from '@pkgs/shared-client/components/SVFormInputs';
import SVLoadingIndicator from '@pkgs/shared-client/components/SVLoadingIndicator';
import SVMessage from '@pkgs/shared-client/components/SVMessage';
import SVModalUsersList from '@pkgs/shared-client/components/SVModalUsersList';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import InviteType from '@pkgs/shared/enums/InviteType';
import { useState } from 'react';

function keyFromItem(item: LikeUser): string {
	if ('email' in item && item.email) {
		return item.email;
	} else if ('_id' in item) {
		return item._id;
	}

	return item.user._id;
}

const _UsersList = ({
	error,
	users,
	isLoading,
	onSelect,
	selectedUsers,
	isSearching,
}: {
	error?: Error;
	users: LikeUser[];
	isLoading: boolean;
	onSelect: ((user: LikeUser) => void) | undefined;
	selectedUsers: LikeUser[];
	isSearching: boolean;
}) => {
	const emptyMessage = isSearching && users.length === 0 && (
		<SVCenter>
			<p>We couldn{"'"}t find anyone. Try something else.</p>
		</SVCenter>
	);

	const filteredUsers = users.filter(
		(user) =>
			!selectedUsers.find(
				(selectedUser) =>
					'_id' in selectedUser && '_id' in user && selectedUser._id === user._id,
			),
	);

	return (
		<>
			{!isSearching && (isLoading || filteredUsers?.length > 0) && <p>Suggested</p>}
			{error && (
				<SVMessage scrollTo={false} use={SVMessage.USES.ERROR}>
					{error.message}
				</SVMessage>
			)}
			{isLoading && <SVLoadingIndicator />}
			{emptyMessage}
			<SVModalUsersList>
				{filteredUsers.map((item) => (
					<SVModalUsersList.SelectItem
						key={keyFromItem(item)}
						item={item}
						isSelected={false}
						onSelect={onSelect}
					/>
				))}
			</SVModalUsersList>
		</>
	);
};

type Props = {
	onSuccess: () => void;
	maxUsersCount?: number;
} & (
	| {
			type: typeof InviteType.TEAM;
	  }
	| {
			type: typeof InviteType.BOARD;
			resourceID: Board['_id'];
	  }
);

type LikeUser = Parameters<
	NonNullable<React.ComponentProps<typeof SVModalUsersList.SelectItem>['onSelect']>
>[0];

const SVInviteUsersContainer = (props: Props) => {
	const { type, onSuccess, maxUsersCount } = props;
	const [queryInput, setQueryInput] = useState('');
	const [query, setQuery] = useState('');
	const [selectedUsers, setSelectedUsers] = useState<LikeUser[]>([]);

	const trimmedQuery = query.trim();
	const isSearching = Boolean(trimmedQuery && trimmedQuery.length > 1);

	const { data, loading, error } = useQuery(SuggestedInvitedUsersQuery, {
		variables: {
			type,
			resourceID: type === InviteType.BOARD ? props.resourceID : undefined,
			query: isSearching ? trimmedQuery : undefined,
		},
		fetchPolicy: 'no-cache',
	});

	const [inviteTeamUsers, { loading: mutationTeamLoading }] =
		useMutation(InviteUsersToTeamMutation);
	const [inviteBoardUsers, { loading: mutationBoardLoading }] = useMutation(
		InviteUsersToBoardMutation,
	);

	const mutationLoading = mutationTeamLoading || mutationBoardLoading;

	const remainingUsersToAdd =
		maxUsersCount && maxUsersCount > 0 ? maxUsersCount - selectedUsers.length : null;
	const canSelectUser = remainingUsersToAdd === null || remainingUsersToAdd > 0;

	const handleQueryChange = useEventCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		if (mutationLoading) {
			return;
		}

		const value = event.target.value;

		setQueryInput(value);

		search(value);
	});

	const search = useDebouncedCallback((queryInput: string) => {
		if (mutationLoading) {
			return;
		}

		setQuery(queryInput);
	}, 300);

	// Parameters<React.ComponentProps<typeof SVModalUsersList.SelectItem>['onSelect']>[0]
	const handleSelectUser = useEventCallback((user: LikeUser) => {
		if (mutationLoading) {
			return;
		}

		const selectedUser = selectedUsers.find(
			(selectedUser) =>
				'_id' in selectedUser && '_id' in user && selectedUser._id === user._id,
		);
		const newSelectedUsers = [...selectedUsers];

		if (!selectedUser) {
			if (canSelectUser) {
				newSelectedUsers.push(user);
			}
		} else {
			newSelectedUsers.splice(selectedUsers.indexOf(selectedUser), 1);
		}

		setSelectedUsers(newSelectedUsers);
	});

	const handleSubmit = useEventCallback(async () => {
		const userIDs = selectedUsers
			.map((user) => !('email' in user) && '_id' in user && user._id)
			.filter(Boolean);
		const emails = selectedUsers.map((user) => 'email' in user && user.email).filter(Boolean);

		if (type === InviteType.TEAM) {
			await inviteTeamUsers({
				variables: {
					input: {
						userIDs,
						emails,
					},
				},
			});
		} else {
			await inviteBoardUsers({
				variables: {
					input: {
						boardID: props.resourceID,
						userIDs,
						emails,
					},
				},
			});
		}

		onSuccess();
	});

	const hasSelectedUsers = selectedUsers.length > 0;
	const isQueryEmailLike = isSearching && /.+@.+\..+/.test(trimmedQuery);

	const items = [
		...(isQueryEmailLike ? [{ _id: trimmedQuery, email: trimmedQuery }] : []),
		...(data?.suggestedInvitedUsers || []),
	];

	return (
		<>
			{hasSelectedUsers && (
				<SVModalUsersList>
					{selectedUsers.map((item) => (
						<SVModalUsersList.SelectItem
							key={keyFromItem(item)}
							item={item}
							isSelected={true}
							onSelect={handleSelectUser}
						/>
					))}
				</SVModalUsersList>
			)}
			<SVInputTextRaw
				name="query"
				error={undefined}
				placeholder="Name, Username or Email"
				onChange={handleQueryChange}
				value={queryInput}
			/>
			<_UsersList
				error={error}
				users={items}
				selectedUsers={selectedUsers}
				isLoading={loading}
				isSearching={isSearching}
				onSelect={canSelectUser ? handleSelectUser : undefined}
			/>
			{hasSelectedUsers && (
				<SVForm
					onSubmit={handleSubmit}
					submitLabel={`Invite ${plural(selectedUsers.length, 'User')}`}
					noPadding={true}
					submitAlignment="right"
				></SVForm>
			)}
		</>
	);
};

export default SVInviteUsersContainer;
