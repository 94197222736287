import { useMutation } from '@apollo/client';
import { type Board } from '@apps/www/src/__generated__/graphql';
import RegenerateBoardPublicURLMutation from '@apps/www/src/www/queries/RegenerateBoardPublicURLMutation';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVTooltip from '@pkgs/shared-client/components/SVTooltip';
import config from '@pkgs/shared-client/config';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import formatURL from '@pkgs/shared/helpers/formatURL';
import ctc from 'copy-to-clipboard';
import { useEffect, useRef } from 'react';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from './SVWithTooltipErrorBoundary';

// Only for private boards, copies the public link to the clipboard.

type Props = {
	boardID: Board['_id'];
	boardPublicURL: Board['publicURL'];
};

const _SVCopyBoardPublicURLContainer = ({
	boardID,
	boardPublicURL,
	showTooltipError,
}: Props & SVWithTooltipErrorBoundaryProps) => {
	const elementRef = useRef<HTMLSpanElement | null>(null);

	const [regenerateBoardPublicURL, { loading, error }] = useMutation(
		RegenerateBoardPublicURLMutation,
	);

	const handleClick = useEventCallback(async () => {
		let publicURL = boardPublicURL;

		if (!publicURL && !loading) {
			const { data } = await regenerateBoardPublicURL({
				variables: {
					input: { boardID },
				},
			});

			publicURL = data?.regenerateBoardPublicURL.publicURL;
		}

		ctc(formatURL(config.baseURL, publicURL || undefined));

		if (elementRef.current) {
			SVTooltip.show(elementRef.current, 'Link copied.');
		}
	});

	useEffect(() => {
		showTooltipError(error);
	}, [showTooltipError, error]);

	return (
		<SVDropdownContent.Links.ItemPreventClose onClick={handleClick} isLoading={loading}>
			<span ref={elementRef}>Create public link</span>
		</SVDropdownContent.Links.ItemPreventClose>
	);
};

const SVCopyBoardPublicURLContainer = SVWithTooltipErrorBoundary(_SVCopyBoardPublicURLContainer);

export default SVCopyBoardPublicURLContainer;
