import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVLink from '@pkgs/shared-client/components/SVLink';
import { formatRelativeDate, isWithinNextDays } from '@pkgs/shared-client/helpers/format';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

const USES = {
	CANCELLED: 'cancelled',
	EXPIRED: 'expired',
} as const;

const POSITIONS = {
	FIXED: 'FIXED',
	DEFAULT: 'DEFAULT',
} as const;

const _TopSpacer = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	(props, ref) => (
		<div
			ref={ref}
			className={props.className ? props.className : 'h-warning-height'}
			{...props}
		/>
	),
);

type Props = React.PropsWithChildren<{
	use: ValueOf<typeof USES>;
	position: ValueOf<typeof POSITIONS>;
}>;

const _Wrapper = ({ children, use }: { children: React.ReactNode; use: ValueOf<typeof USES> }) => {
	return (
		<div
			className={clsx(
				'h-warning-height w-full',
				use === USES.CANCELLED ? 'bg-danger' : 'bg-primary',
			)}
		>
			<div
				className={clsx(
					'-sm:p-5 -lg:px-10 flex h-full w-full items-center justify-between px-14',
					use === USES.CANCELLED ? 'text-white' : 'text-background',
				)}
			>
				<div className="-sm:p-0 -sm:items-start flex w-full items-center justify-between">
					{children}
				</div>
			</div>
		</div>
	);
};

const _ContentExpired = ({ onClose }: { onClose: () => void }) => {
	const authSettings = useAuthSettings(['subscriptions']);

	if (!authSettings) {
		return null;
	}

	const findExpiredSubscription = authSettings?.subscriptions?.find((subscription) => {
		const subscriptionEndDate = subscription.subscriptionEndDate
			? subscription.subscriptionEndDate
			: undefined;
		return (
			subscription.status === 'active' &&
			!subscription.autoRenew &&
			subscription.canManage &&
			subscriptionEndDate &&
			isWithinNextDays(subscriptionEndDate, new Date(), 30)
		);
	});

	const subscriptionEndDate: Date | undefined =
		findExpiredSubscription?.subscriptionEndDate ?? undefined;

	return (
		<_Wrapper use={USES.EXPIRED}>
			<span className="-sm:mx-0 mx-auto">
				<div className="-sm:flex-col -sm:space-x-0 -sm:space-y-4 -sm:items-start flex items-center space-x-4">
					<div className="text-sm">
						Your subscription will expire
						{subscriptionEndDate
							? ` in ${formatRelativeDate(subscriptionEndDate).replace('about', '')}`
							: ' soon'}
						.
					</div>
					<SVButton
						title="Turn on auto-renew"
						className="flex-center hover:text-primary text-background ring-background hover:bg-background m-2 h-9 ring-1 ring-opacity-50"
						Component={SVLink}
						to="/billing/"
						size={SVButtonSIZES.EXTRA_SMALL}
						use={SVButtonUSES.OUTLINE}
					>
						Turn on auto-renew
					</SVButton>
				</div>
			</span>
			<span>
				<SVIconButton
					src={IconCloseSVG}
					onClick={onClose}
					iconClassName="text-background"
				/>
			</span>
		</_Wrapper>
	);
};

const _ContentCancelled = ({ onClose }: { onClose: () => void }) => {
	return (
		<_Wrapper use={USES.CANCELLED}>
			<span className="-sm:mx-0 mx-auto">
				<div className="-sm:flex-col -sm:space-x-0 -sm:items-start -sm:space-y-4 flex items-center space-x-4">
					<div className="text-sm">Your subscription has expired.</div>
					<SVButton
						title="Resume subscription"
						className="flex-center hover:text-danger h-9 text-white ring-1 ring-white ring-opacity-50 hover:bg-white"
						Component={SVLink}
						to="/billing/"
						size={SVButtonSIZES.EXTRA_SMALL}
						use={SVButtonUSES.OUTLINE}
					>
						Resume subscription
					</SVButton>
				</div>
			</span>
			<span>
				<SVIconButton src={IconCloseSVG} onClick={onClose} iconClassName="text-white" />
			</span>
		</_Wrapper>
	);
};

const CONTENT_COMPONENTS_MAP = {
	[USES.EXPIRED]: _ContentExpired,
	[USES.CANCELLED]: _ContentCancelled,
};

const _renderContent = (use: ValueOf<typeof USES>, onClose: () => void) => {
	const Component = CONTENT_COMPONENTS_MAP[use];

	if (!Component) {
		return null;
	}

	return <Component onClose={onClose} />;
};

const SVSubscriptionWarning = ({ use = USES.EXPIRED, position = POSITIONS.DEFAULT }: Props) => {
	const spacerRef = React.useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(true);
	const router = useRouter();

	const onRouteChange = () => {
		setIsOpen(true);
	};

	const onClose = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		onRouteChange();
	}, [router?.asPath]);

	return (
		<div
			className={clsx(
				position !== POSITIONS.FIXED &&
					isOpen &&
					'duration-slide slide-height relative transition-all ease-in-out',
			)}
		>
			{position === POSITIONS.FIXED && (
				<_TopSpacer
					ref={spacerRef}
					className={clsx(
						'duration-slide transition-all ease-in-out',
						isOpen ? 'h-warning-height' : 'h-0',
					)}
				/>
			)}
			<div
				className={clsx(
					'duration-slide overflow-hidden transition-all ease-in-out',
					isOpen ? 'h-warning-height' : 'h-0',
					position === POSITIONS.FIXED && 'z-index-nav fixed left-0 top-0 w-full',
					position === POSITIONS.DEFAULT && 'relative',
				)}
			>
				{_renderContent(use, onClose)}
			</div>
		</div>
	);
};

SVSubscriptionWarning.USES = USES;
SVSubscriptionWarning.POSITIONS = POSITIONS;

export default SVSubscriptionWarning;
