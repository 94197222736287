import { ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVDeleteItemsActionContainer from '@apps/www/src/www/containers/SVDeleteItemsActionContainer';
import SVGridHideAssetContainer from '@apps/www/src/www/containers/SVGridHideAssetContainer';
import SVReportItemsActionContainer from '@apps/www/src/www/containers/SVReportItemsActionContainer';
import SVShareActionContainer from '@apps/www/src/www/containers/SVShareActionContainer';
import SVToggleItemsPrivacyActionContainer from '@apps/www/src/www/containers/SVToggleItemsPrivacyActionContainer';
import useAllowedToHideAssets from '@apps/www/src/www/hooks/useAllowedToHideAssets';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import { twMerge } from 'tailwind-merge';

const SVMoreOptionsContent = ({
	items,
	onDeleteOrReportSuccess,
	className,
	children,
	...props
}: {
	items: ItemFragmentFragment[];
	onDeleteOrReportSuccess?: () => void;
	children?: React.ReactNode;
	className?: string;
}) => {
	const isLoggedIn = useIsLoggedIn();
	const isAllowedToHideAssets = useAllowedToHideAssets();

	return (
		<div className={twMerge(className)}>
			<SVDropdownContent.Links {...props}>
				{isAllowedToHideAssets && (
					<SVGridHideAssetContainer
						items={items}
						renderButton={(buttonProps) => (
							<SVDropdownContent.Links.ItemPreventClose
								title="Hide"
								keys={'h'}
								{...buttonProps}
							>
								Hide from feed
							</SVDropdownContent.Links.ItemPreventClose>
						)}
					/>
				)}
				{isLoggedIn && items.some((item) => item.isOwner) && (
					<SVToggleItemsPrivacyActionContainer
						items={items}
						render={(props) => <SVDropdownContent.Links.ItemToggle {...props} />}
					/>
				)}
				<SVShareActionContainer
					Component={SVDropdownContent.Links.ItemPreventClose}
					title="Share image"
					url={items[0].url}
				>
					Share
				</SVShareActionContainer>
				{isLoggedIn && !items.some((item) => item.isOwner) && (
					<SVReportItemsActionContainer
						items={items}
						onSuccess={onDeleteOrReportSuccess && onDeleteOrReportSuccess}
						render={(props) => (
							<SVDropdownContent.Links.ItemPreventClose
								keys="r"
								title="Report as inappropriate"
								{...props}
							>
								Report
							</SVDropdownContent.Links.ItemPreventClose>
						)}
					/>
				)}
				{isLoggedIn && items.some((item) => item.isOwner) && (
					<SVDeleteItemsActionContainer
						items={items}
						onSuccess={onDeleteOrReportSuccess && onDeleteOrReportSuccess}
						render={(props) => (
							<SVDropdownContent.Links.ItemPreventClose
								keys="backspace"
								title="Delete image"
								{...props}
							>
								Delete
							</SVDropdownContent.Links.ItemPreventClose>
						)}
					/>
				)}
				{children}
			</SVDropdownContent.Links>
		</div>
	);
};

export default SVMoreOptionsContent;
