import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useEffect } from 'react';

export default function useElementBoudingClientRectUpdated(
	element: HTMLElement | null,
	callback: () => void,
) {
	const update = useEventCallback(() => {
		if (!element) {
			return;
		}

		callback();
	});

	useEffect(() => {
		update();

		window.addEventListener('resize', update);
		window.addEventListener('DOMContentLoaded', update);

		// When anything on page changes
		const observer = new MutationObserver(update);
		observer.observe(document.body, { childList: true, subtree: true });

		// When container changes size
		const resizeObserver = new ResizeObserver(update);
		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			window.removeEventListener('resize', update);
			window.removeEventListener('DOMContentLoaded', update);
			observer.disconnect();
			resizeObserver.disconnect();
		};
	}, [element, update]);
}
