/**
 * Shows a loading status if `isLoading` prop is true or global error status if `error` prop is provided.
 */

import SVLoadingIndicator from '@pkgs/shared-client/components/SVLoadingIndicator';

type Props = {
	isLoading: boolean;
	children: () => JSX.Element | null;
	className?: string;
};

const SVLoadingWrapper = ({ isLoading, children, className }: Props) => {
	if (isLoading) {
		return (
			<div className={className}>
				<SVLoadingIndicator />
			</div>
		);
	}

	return children();
};

export default SVLoadingWrapper;
