import { useApolloClient } from '@apollo/client';
import {
	type Item,
	type NewBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import NewBoardMutation from '@apps/www/src/www/queries/NewBoardMutation';
import SVForm from '@pkgs/shared-client/components/SVForm';
import { SVInputStatic, SVInputText } from '@pkgs/shared-client/components/SVFormInputs';
import SVFormLayout from '@pkgs/shared-client/components/SVFormLayout';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import SVToggle from '@pkgs/shared-client/components/SVToggle';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useState } from 'react';

const SVNewBoardModal = SVModal.create(
	({
		items,
		fromExtension,
		ownershipType,
		autoRedirect = true,
	}: {
		items?: Array<Pick<Item, '_id'>>;
		fromExtension?: boolean;
		ownershipType: ValueOf<typeof BoardOwnershipType>;
		autoRedirect?: boolean;
	}) => {
		const client = useApolloClient();
		const router = useRouter();
		const close = useModalClose();
		const [isTeamBoard] = useState(ownershipType === BoardOwnershipType.TEAM);
		const [isPrivate, setIsPrivate] = useState(ownershipType === BoardOwnershipType.TEAM);
		const [isHidden, setIsHidden] = useState(false);
		const [isTeamEditorAnEditor, setIsTeamEditorAnEditor] = useState(
			ownershipType === BoardOwnershipType.TEAM,
		);

		const handleSubmit = useEventCallback(
			async (
				values: Omit<
					NewBoardMutationMutationVariables['input'],
					'itemIDs' | 'ownershipType'
				>,
			) => {
				const itemIDs = items?.map((item) => item._id);

				const { data } = await client.mutate({
					mutation: NewBoardMutation,
					variables: {
						input: {
							itemIDs,
							ownershipType,
							...values,
							isPrivate,
							isTeamEditorAnEditor,
							isHidden,
						},
					},
				});

				// Redirect to new board if no items were added to it
				if (!fromExtension && !items?.length && data && autoRedirect) {
					router.push(data.newBoard.board.url);
				}

				close();
			},
		);

		return (
			<div className="-sm:w-full relative w-[480px]">
				<SVModal.Title className="mb-8">
					Create {isTeamBoard ? 'team ' : ' '}board
				</SVModal.Title>
				<SVForm
					onSubmit={handleSubmit}
					submitLabel="Create"
					initialValues={{
						name: '',
						description: '',
						isPrivate: isTeamBoard,
						isTeamEditorAnEditor: isTeamBoard,
						isHidden: false,
					}}
					submitAlignment="right"
					noPadding
				>
					{isTeamBoard ? (
						<SVFormLayout.P>
							Team boards enable collaboration among your entire team.
						</SVFormLayout.P>
					) : null}
					{items && items.length > 0 ? (
						<SVInputStatic value={plural(items.length, 'save')} />
					) : null}
					<div className="mt-4">
						<SVInputText name="name" label="Board name" required={true} />
					</div>
					<div className="mt-6">
						<SVInputText name="description" label="Description (optional)" />
					</div>
					<div
						className={clsx(
							'mb-6 mt-6 flex justify-between',
							isTeamBoard && 'disabled',
						)}
					>
						<div className="flex flex-col text-start">
							<div className="text-base font-semibold text-gray-500">
								Private Board
							</div>
							<div className="type-label text-muted">
								{isTeamBoard
									? 'Team boards are always private'
									: 'Only you and collaborators can see it.'}
							</div>
						</div>
						<SVToggle
							isPressed={isPrivate}
							onClick={() => !isTeamBoard && setIsPrivate(!isPrivate)}
							isDisabled={isTeamBoard}
							data-test="private"
						/>
					</div>

					{!isTeamBoard ? (
						<div className="mb-6 mt-6 flex justify-between">
							<div className="flex flex-col text-start">
								<div className="text-base font-semibold text-gray-500">
									Hide from feed
								</div>
								<div className="type-label text-muted">
									This board won't show up on your profile and feed.
								</div>
							</div>
							<SVToggle
								isPressed={isHidden}
								onClick={() => setIsHidden(!isHidden)}
								use="extension-settings"
							/>
						</div>
					) : null}

					{isTeamBoard ? (
						<div className="mb-6 flex items-center justify-between">
							<div className="flex flex-col text-start">
								<div className="text-base font-semibold text-gray-500">
									Team editor can edit
								</div>
								<div className="type-label text-muted">
									If disabled, only team owners, admins and curators can
									add/remove saves from this board.
								</div>
							</div>
							<SVToggle
								isPressed={isTeamEditorAnEditor}
								onClick={() => setIsTeamEditorAnEditor(!isTeamEditorAnEditor)}
							/>
						</div>
					) : null}
				</SVForm>
			</div>
		);
	},
);

export default SVNewBoardModal;
