/**
 * Returns true if we're rendering the small nav. The whole app repositions a lot of stuff when the small nav is visible,
 * to resemble the native app version.
 */

import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';

export default function useIsSmallNav() {
	const viewportName = useViewportName();
	const [viewportKind] = useUIState(UIStateKeys.VIEWPORT_KIND);

	// const isSmallNav =
	// 	(!viewportName && viewportKind === ViewportKind.MOBILE) ||
	// 	viewportName === 'small' ||
	// 	viewportName === 'medium' ||
	// 	viewportName === 'medium-portrait';

	// Server does a good job at estimating the device so we use that to not
	// flick the navigation on mobile portrait. Only downside is on mobile landscape
	// where server will draw first the small version and then the regular one.
	const isSmallNav =
		(!viewportName && viewportKind === ViewportKind.MOBILE) || viewportName === 'small';

	return isSmallNav;
}
