const AllowedFeature = {
	PRIVATE_ITEM: 'private_item',
	PRIVATE_BOARD: 'private_board',
	PRINT: 'print',
	ADD_ITEM: 'add_item',
	ADD_BOARD: 'add_board',
	SHARED_BOARD: 'shared_board',
	MANAGE_TEAM: 'manage_team',
	CREATE_SITE: 'create_site',
	RIGHT_CLICK: 'right_click',
	AUTO_PLAY_GIF: 'auto_play_gif',
	DROPBOX_BACKUP: 'dropbox_backup',
	PRINT_GRID: 'print_grid',
	FULL_FEED: 'full_feed',
	HIDE_ASSETS: 'hide_assets',
} as const;

export default AllowedFeature;
