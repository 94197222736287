/**
 * Formats and normalizes a url. Adds baseURL to pathname if needed.
 */

import normalizeURL from '@pkgs/esm-libs/normalize-url';
import url from 'url';

export default function formatURL(
	baseURLOrPathname: string,
	pathnameOrEmpty?: string,
	overwriteBaseURL = true,
): string {
	let baseURL: string | undefined = baseURLOrPathname;
	let pathname: string | undefined = pathnameOrEmpty;

	if (arguments.length === 1 || !pathnameOrEmpty) {
		pathname = baseURL;
		baseURL = undefined;
	}

	if (!pathname) {
		return '';
	}

	//Do not format base64 img urls
	if (pathname.indexOf(';base64,') !== -1) {
		return pathname;
	}

	try {
		if (!baseURL && (pathname.indexOf('://') === -1 || pathname.indexOf('://') > 5)) {
			return pathname || '';
		}

		if (baseURL) {
			const parsedBaseURL = url.parse(baseURL);
			const parsedPathnameURL = url.parse(pathname);

			if (parsedBaseURL.host && (overwriteBaseURL || !parsedPathnameURL.host)) {
				pathname = parsedPathnameURL.path || '';

				parsedBaseURL.protocol = parsedBaseURL.protocol || 'http:';

				if (parsedBaseURL.path !== '/') {
					pathname = (parsedBaseURL.path || '') + pathname;
				}

				pathname = url.resolve(
					parsedBaseURL.protocol + '//' + parsedBaseURL.host,
					pathname,
				);
			}
		}

		return normalizeURL(pathname, {
			stripTextFragment: false,
			removeTrailingSlash: false,
			sortQueryParameters: false,
			stripHash: false,
		});
	} catch (e) {
		return pathname || '';
	}
}
