import { useQuery } from '@apollo/client';
import '@apps/www/i18n';
import { updateI18nLanguage } from '@apps/www/i18n';
import SVApp from '@apps/www/src/www/containers/SVApp';
import SVWithApolloApp, {
	updateLockedCallback,
} from '@apps/www/src/www/containers/SVWithApolloApp';
import SVAnalyticsController from '@apps/www/src/www/controllers/SVAnalyticsController';
import { saveeFont } from '@apps/www/src/www/fonts';
import { setAuthTokenOnClient } from '@apps/www/src/www/helpers/authToken';
import useLanguageSetting from '@apps/www/src/www/hooks/useLanguageSetting';
import useSetUILocked from '@apps/www/src/www/hooks/useSetUILocked';
import { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import AuthQuery from '@apps/www/src/www/queries/AuthQuery';
import { initializeStore, useStore } from '@apps/www/src/www/store';
import SVModal from '@pkgs/shared-client/components/SVModal';
import config from '@pkgs/shared-client/config';
import SVWithBodyClass from '@pkgs/shared-client/containers/SVWithBodyClass';
import { type createApolloClient } from '@pkgs/shared-client/helpers/apolloClient';
import { isSSR, isTouch } from '@pkgs/shared-client/helpers/dom';
import isNotFoundGraphQLError from '@pkgs/shared-client/helpers/isNotFoundApolloError';
import '@pkgs/shared-client/styles/global.css';
import formatURL from '@pkgs/shared/helpers/formatURL';
import { type ApolloError } from 'apollo-server-core';
import NextApp, { type AppContext as NextAppContext } from 'next/app';
import ErrorPage from 'next/error';
import { useRouter, type NextRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

const bodyClasses = {
	'no-touch': !isTouch(),
	touch: isTouch(),
	'transition-colors duration-slide ease-in-out': true,
	[saveeFont.className]: true,
};

const ReduxProvider: typeof Provider = SVWithBodyClass(bodyClasses)(Provider);

declare global {
	interface Window {
		_initialized: boolean;
	}
}

if (!isSSR && !self._initialized) {
	self._initialized = true;

	require('intersection-observer');
}

type Props2 = {
	router: NextRouter;
	Component: React.ComponentType<any>;
	pageProps: AnyObject;
};

type State2 = {
	error: Error | ApolloError | null;
	previousAsPath: string | null;
};

class _AppInner2 extends React.Component<Props2, State2> {
	state: State2 = { error: null, previousAsPath: null };

	static getDerivedStateFromProps(props, state) {
		const newState: Partial<State2> = {};

		// Clear error on navigation
		if (state.previousAsPath !== props.router.asPath && state.error) {
			newState.error = null;
		}

		newState.previousAsPath = props.router.asPath;

		return newState;
	}

	componentDidCatch(error: Error | ApolloError) {
		this.setState({ error });
	}

	render() {
		const { router: _, ...otherProps } = this.props;
		const { error } = this.state;

		if (error && isNotFoundGraphQLError(error)) {
			return <ErrorPage statusCode={404} />;
		} else if (error) {
			return <ErrorPage statusCode={500} />;
		}

		return (
			<main className={saveeFont.className}>
				<SVApp {...otherProps} />
			</main>
		);
		// return (
		// 		<ErrorWrapperProvider>
		// 		<SVApp {...otherProps} />
		// 		</ErrorWrapperProvider>
		// );
	}
}

const _LanguageUpdater = () => {
	const [language] = useLanguageSetting();

	updateI18nLanguage(language);

	return null;
};

const _AppInner = (props) => {
	const router = useRouter();
	const setLocked = useSetUILocked();

	useEffect(() => {
		updateLockedCallback(setLocked);
	}, [setLocked]);

	return (
		<>
			<_LanguageUpdater />
			<_AppInner2 {...props} router={router} />
		</>
	);
};

const _App = (props) => {
	const { data } = useQuery(AuthQuery, { fetchPolicy: 'cache-only' });

	const store = useStore(props.pageProps.initialReduxState);

	useEffect(() => {
		if (data?.auth?.token) {
			setAuthTokenOnClient(data?.auth?.token);
		}
	}, [data]);

	return (
		<>
			{/* @ts-expect-error how do we type this properly? */}
			<ReduxProvider store={store}>
				<SVModal.Provider>
					<_AppInner {...props} />
					<SVAnalyticsController />
				</SVModal.Provider>
			</ReduxProvider>
		</>
	);
};

export type AppContext = NextAppContext & {
	ctx: NextAppContext['ctx'] & {
		req: Req;
		apolloClient?: ReturnType<typeof createApolloClient>;
		isServer?: boolean;
	};
};

_App.getInitialProps = async (appContext: AppContext) => {
	const { ctx } = appContext;
	const { req, res, apolloClient } = ctx;

	ctx.isServer = Boolean(req && req.client);

	if (res && ctx.isServer) {
		// if static resource like favicon.ico
		const pieces = ctx.asPath?.split('/');
		if (pieces && pieces.length >= 2 && pieces[1].includes('.')) {
			const ext = pieces[1].split('.')[1];
			// TODO: Update username validation to never have .xml, .ico, .json, or .txt as a suffix
			// TODO: Check database to update usernames as well
			if (['xml', 'ico', 'json', 'txt'].includes(ext)) {
				res.writeHead(302, {
					Location: formatURL(config.staticURL, `/_next/static${ctx.asPath}`),
				});
				return res.end();
			}
		}
	}

	// check login
	const { data } = apolloClient ? await apolloClient.query({ query: AuthQuery }) : { data: null };

	if (data?.auth) {
		if (ctx.isServer) {
			// for morgan logging
			req._authUserUsername = data.auth.user.username;
		} else {
			setAuthTokenOnClient(data.auth.token);
		}
	}

	if (res && ctx.isServer) {
		// middlewares
		const { loggingMiddleware } = await import('@apps/www/src/pages/api/_middlewares');

		await loggingMiddleware(req, res);

		// initialize redux store and auth state
		if (!req.store) {
			req.store = initializeStore();
		}
	}

	const appProps = await NextApp.getInitialProps(appContext);

	if (ctx.isServer) {
		const { default: pick } = await import('lodash/pick');

		const initialReduxState = pick(req.store.getState(), ['grid', 'ui']);

		const { default: estimateViewportKindFromRequest } = await import(
			'@pkgs/shared-server/helpers/estimateViewportKindFromRequest'
		);

		const { default: languageFromRequest } = await import(
			'@pkgs/shared-server/helpers/languageFromRequest'
		);

		initialReduxState.ui[UIStateKeys.LANGUAGE] = languageFromRequest(req);
		initialReduxState.ui[UIStateKeys.VIEWPORT_KIND] = estimateViewportKindFromRequest(req);

		appProps.pageProps.initialReduxState = initialReduxState;
	}

	return appProps;
};

const App = SVWithApolloApp(_App);

export default App;
