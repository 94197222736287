import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import SVGrid, { type Props as GridProps } from '@pkgs/shared-client/components/SVGrid';
import { columnsRatioToConfigID, spacingsRatioToConfigID } from '@pkgs/shared-client/config';
import { useMount, useUnmount } from 'react-use';
import SVBaseGridContainer from './SVBaseGridContainer';

type Props = {
	sourceType?: GridProps['sourceType'];
	items: ItemFragmentFragment[] | undefined;
	isLoading: GridProps['isLoading'];
	loadingSkeletonCount?: GridProps['loadingSkeletonCount'];
	onPaginate: GridProps['onPaginate'];
	onItemClick?: GridProps['onItemClick'];
};

/**
 * Renders a grid with user settings on spacing, columns count and sort method
 */
const SVGridContainer = ({
	sourceType,
	items,
	isLoading,
	loadingSkeletonCount,
	onPaginate,
	onItemClick,
}: Props) => {
	const [viewportName] = useUIState(UIStateKeys.VIEWPORT_NAME);
	const [_, setShowGridControls] = useUIState(UIStateKeys.SHOW_GRID_CONTROLS);
	const [_sourceGridType, setSourceGridType] = useUIState(UIStateKeys.SOURCE_GRID_TYPE);

	const [gridColumnsRatio] = usePersistentSetting(PersistentSettingKeys.GRID_COLUMNS_RATIO);
	const columnsConfigID =
		(viewportName && columnsRatioToConfigID(viewportName, gridColumnsRatio)) || 6;

	const [gridSpacingRatio] = usePersistentSetting(PersistentSettingKeys.GRID_SPACING_RATIO);
	const spacingConfigID = spacingsRatioToConfigID(gridSpacingRatio);

	const [sortMethod] = usePersistentSetting(PersistentSettingKeys.ITEMS_SORT_METHOD);

	useMount(() => {
		if (sourceType !== SVGrid.SOURCE_TYPES.RELATED) {
			setShowGridControls(true);
			setSourceGridType(sourceType);
		}
	});

	useUnmount(() => {
		if (sourceType !== SVGrid.SOURCE_TYPES.RELATED) {
			setShowGridControls(false);
			setSourceGridType(undefined);
		}
	});

	return (
		<SVBaseGridContainer
			items={items}
			isStatic={false}
			isLoading={isLoading}
			loadingSkeletonCount={loadingSkeletonCount}
			onPaginate={onPaginate}
			sourceType={sourceType}
			columnsConfigID={columnsConfigID}
			spacingConfigID={spacingConfigID}
			sortMethod={sortMethod}
			onItemClick={onItemClick}
		/>
	);
};

export default SVGridContainer;
