import SVSortDropdownContent from '@apps/www/src/www/components/SVSortDropdownContent';
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';

const SORT_METHODS = [
	{
		name: ItemsSortMethod.CUSTOM,
		label: 'Custom order',
	},
	{
		name: ItemsSortMethod.NEWEST_FIRST,
		label: 'Newest first',
	},
	{
		name: ItemsSortMethod.OLDEST_FIRST,
		label: 'Oldest first',
	},
];

const SVGridSortButtonContainer = () => {
	const [sortMethod, setSortMethod] = usePersistentSetting(
		PersistentSettingKeys.ITEMS_SORT_METHOD,
	);
	const [showGridControls] = useUIState(UIStateKeys.SHOW_GRID_CONTROLS);

	// Don't show up if page is not an items grid
	if (!showGridControls) {
		return null;
	}

	return (
		<SVSortDropdownContent
			methods={SORT_METHODS}
			sortMethod={sortMethod}
			setSortMethod={(sortMethod) =>
				setSortMethod(sortMethod as ValueOf<typeof ItemsSortMethod>)
			}
		/>
	);
};

export default SVGridSortButtonContainer;
