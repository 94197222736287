/**
 * Persists the setting to the user's profile. Holds state on client if not logged in.
 */
import { useApolloClient } from '@apollo/client';
import { type AuthSettings } from '@apps/www/src/__generated__/graphql';
import createUpdateAuthSettingsMutation from '@apps/www/src/www/queries/createUpdateAuthSettingsMutation';
import useAuthSettings from './useAuthSettings';
import useAuthUserID from './useAuthUserID';
import useUIState, { UIStateDefaults, UIStateKeys, type State } from './useUIState';

export const PersistentSettingKeys = {
	GRID_COLUMNS_RATIO: UIStateKeys.GRID_COLUMNS_RATIO,
	GRID_SPACING_RATIO: UIStateKeys.GRID_SPACING_RATIO,
	DARK_MODE: UIStateKeys.DARK_MODE,
	FIT_TO_SCREEN: UIStateKeys.FIT_TO_SCREEN,
	FEED_BANNER_EXPANDED: UIStateKeys.FEED_BANNER_EXPANDED,
	ITEMS_SORT_METHOD: UIStateKeys.ITEMS_SORT_METHOD,
	BOARDS_SORT_METHOD: UIStateKeys.BOARDS_SORT_METHOD,
	LANGUAGE: UIStateKeys.LANGUAGE,
	MUTED: UIStateKeys.MUTED,
	SIDE_MENU_VISIBLE: UIStateKeys.SIDE_MENU_VISIBLE,
} as const;

// ui state key -> auth() setting query field
const AUTH_USER_SETTINGS_FIELDS_MAP = {
	[PersistentSettingKeys.GRID_COLUMNS_RATIO]: 'gridColumnsRatio',
	[PersistentSettingKeys.GRID_SPACING_RATIO]: 'gridSpacingRatio',
	[PersistentSettingKeys.DARK_MODE]: 'darkMode',
	[PersistentSettingKeys.FIT_TO_SCREEN]: 'fitToScreen',
	[PersistentSettingKeys.FEED_BANNER_EXPANDED]: 'feedBannerExpanded',
	[PersistentSettingKeys.ITEMS_SORT_METHOD]: 'itemsSortMethod',
	[PersistentSettingKeys.BOARDS_SORT_METHOD]: 'boardsSortMethod',
	[PersistentSettingKeys.LANGUAGE]: 'language',
	[PersistentSettingKeys.MUTED]: 'muted',
	[PersistentSettingKeys.SIDE_MENU_VISIBLE]: 'sideMenuVisible',
} as const;
// } as const satisfies Record<ValueOf<typeof PersistentSettingKeys>, keyof AuthSettings>;

// : [
// 	State[K] | AuthSettings[(typeof AUTH_USER_SETTINGS_FIELDS_MAP)[K]],
// 	(value: State[K] | AuthSettings[(typeof AUTH_USER_SETTINGS_FIELDS_MAP)[K]]) => void,
// ]

const usePersistentSetting = <
	K extends ValueOf<typeof PersistentSettingKeys> & ValueOf<typeof UIStateKeys>,
>(
	key: K,
) => {
	const [uiValue, setUIValue] = useUIState(key);
	const authUserID = useAuthUserID();
	const authUserSettingsField = AUTH_USER_SETTINGS_FIELDS_MAP[key];
	const authSettings = useAuthSettings([authUserSettingsField]);
	const client = useApolloClient();

	if (authUserID && authSettings) {
		return [
			authSettings[authUserSettingsField] ?? UIStateDefaults[key],
			async (value: State[K] | AuthSettings[(typeof AUTH_USER_SETTINGS_FIELDS_MAP)[K]]) => {
				if (value === authSettings[authUserSettingsField]) {
					return;
				}

				await client.mutate({
					mutation: createUpdateAuthSettingsMutation([authUserSettingsField]),
					variables: {
						input: {
							[authUserSettingsField]: value,
						},
					},
					// update: (cache, { data: { updateAuthSettings } }) => {
					// 	// Don't update the cache here if target value is different
					// 	if (updateAuthSettings.settings[authUserSettingsField] !== value) {
					// 		return;
					// 	}

					// 	cache.modify({
					// 		fields: {
					// 			settings: (settings) => {
					// 				settings[authUserSettingsField] =
					// 					updateAuthSettings.settings[authUserSettingsField];
					// 				return settings;
					// 			},
					// 		},
					// 	});
					// },
					optimisticResponse: {
						updateAuthSettings: {
							__typename: 'Auth',
							_id: authUserID,
							// @ts-ignore fix createUpdateAuthSettingsMutation return type
							settings: {
								__typename: 'AuthSettings',
								_id: authSettings._id,
								[authUserSettingsField]: value,
							},
						},
					},
				});
			},
		] as const;
	} else {
		return [uiValue, setUIValue] as const;
	}
};

export default usePersistentSetting;
