import clsx from 'clsx';

const SIZES = {
	DEFAULT: 'default',
	LARGE: 'large',
} as const;

const defaultProps: {
	size: ValueOf<typeof SIZES>;
} = {
	size: SIZES.DEFAULT,
};

type Props = Partial<typeof defaultProps> & {
	className?: string;
};

const SVProBadge = ({ size, className }: Props) => (
	<div title="Premium user" className="inline-flex items-center">
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			className={clsx(
				' inline-block',
				size === SIZES.LARGE
					? '-sm:w-3 -sm:h-3 ml-3 h-4 w-4 fill-gray-200'
					: 'ml-1.5 h-3 w-3 fill-gray-500',
				className,
			)}
		>
			<path d="M8 1.238 5.854 0 4.666 2.06H2.173v2.542L0 5.856 1.238 8 0 10.144l2.173 1.254v2.362h2.39L5.855 16 8 14.762 10.143 16l1.293-2.24h2.465v-2.404L16 10.144 14.762 8 16 5.856l-2.1-1.212V2.06h-2.567L10.143 0zm2.965 4.49.902.914-4.572 4.6L4.56 8.47l.897-.903L7.293 9.4z" />
		</svg>
	</div>
);

SVProBadge.SIZES = SIZES;

export default SVProBadge;
