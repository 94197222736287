/**
 * Page components are the side menu and user header, for example. This provides a hook to check if they should be visible,
 * then on other components we can use this information to style paddings and alignment correctly.
 */

import { createContext, useContext } from 'react';

const SVPageComponentsPresenceContext = createContext<{
	withUserHeader: boolean;
	withBoardHeader: boolean;
	withSideMenu: boolean;
}>({
	withUserHeader: false,
	withBoardHeader: false,
	withSideMenu: false,
});

export const SVPageComponentsPresenceProvider = SVPageComponentsPresenceContext.Provider;

export const usePageComponentsPresence = () => {
	const { withUserHeader, withBoardHeader, withSideMenu } = useContext(
		SVPageComponentsPresenceContext,
	);

	return {
		withUserHeader,
		withBoardHeader,
		withSideMenu,
	};
};
