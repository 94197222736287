import { type Board, type BoardFragmentFragment } from '@apps/www/src/__generated__/graphql';
import IconCaretSVG from '@pkgs/shared-client/img/icon-caret-inlined.svg';
import IconCloseSmallSVG from '@pkgs/shared-client/img/icon-close-small-inlined.svg';
import IconLockSVG from '@pkgs/shared-client/img/icon-lock-inlined.svg';
import React from 'react';
import { createSelector } from 'reselect';
import SVA from './SVA';
import SVDropdown from './SVDropdown';
import SVDropdownContent from './SVDropdownContent';
import SVImage from './SVDynamicImage';
import SVFlexSpacer from './SVFlexSpacer';
import { type LikeItem } from './SVGrid';
import SVIconButton from './SVIconButton';
import SVLink from './SVLink';

const shownBoardsSelector = createSelector(
	(props: Props) => props.boards || [],
	(props: Props) => props.selectedBoardIDs,
	(boards, selectedBoardIDs) =>
		boards.filter((board) => {
			return !!selectedBoardIDs.find((selectedBoardID) => {
				return selectedBoardID == board._id;
			});
		}),
);

const availableBoardsSelector = createSelector(
	(props: Props) => props.boards || [],
	(props: Props) => props.selectedBoardIDs,
	(boards, selectedBoardIDs) =>
		boards.filter((board) => {
			return !selectedBoardIDs.find((selectedBoardID) => {
				return selectedBoardID == board._id;
			});
		}),
);

const _ItemButton = React.memo(
	({
		board,
		onRemove,
		canRemove,
	}: Pick<Props, 'onRemove'> & { board: BoardFragmentFragment; canRemove: boolean }) => (
		<li
			className="box-border relative flex items-center w-full p-1 space-x-2 rounded group h-11 hover:bg-gray-800"
			title={board.name}
		>
			<SVA
				Component={SVLink}
				to={board.url}
				className="flex items-center flex-auto min-w-0 space-x-2"
			>
				<SVImage
					className="flex-shrink-0 ml-3 h-9 w-9"
					src={board.thumbnails?.[0]?.image?.thumbnail}
					cover={true}
					rounded={true}
				/>
				<span className="truncate type-small">{board.name}</span>
				<SVFlexSpacer />
				{board.isPrivate && (
					<span className="relative flex items-center justify-center flex-shrink-0 w-4 h-4 ml-2 transition-opacity bg-gray-700 rounded-full text-primary duration-over">
						<IconLockSVG className="w-2 h-2" />
					</span>
				)}
			</SVA>
			<div className="pr-4 text-xs text-gray-600 type-small group-hover:text-muted group-hover:pr-0">
				{board.itemsCount}
			</div>
			{canRemove && (
				<span className="hidden group-hover:block">
					<SVIconButton
						className="ml-1 mr-3"
						iconClassName="w-[12px] h-[12px] min-h-[12px] min-w-[12px]"
						onClick={() => onRemove(board)}
						src={IconCloseSmallSVG}
						label="Remove"
					/>
				</span>
			)}
		</li>
	),
);

const _AddButton = React.memo(
	React.forwardRef<
		SVDropdown | null,
		Pick<Props, 'onAdd' | 'onNew'> & {
			availableBoards: BoardFragmentFragment[];
		}
	>(({ availableBoards, onAdd, onNew }, ref) => (
		<SVDropdown
			ref={ref}
			key="add"
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			renderTrigger={({ isOpen: _, ...props }) => (
				<button
					className="box-border relative flex items-center w-full p-4 mb-2 space-x-4 text-left border-2 rounded-xl cursor-pointer border-separator text-primary h-14"
					{...props}
				>
					<span className="flex-grow">Add to a board</span>
					<IconCaretSVG />
				</button>
			)}
			renderContent={() => (
				<SVDropdownContent.Boards
					boards={availableBoards}
					onBoardClick={onAdd}
					onNewBoard={onNew}
				/>
			)}
			positionStrategy={SVDropdown.POSITION_STRATEGIES.FIXED}
			maxHeight={520}
			safetyMargin={SVDropdown.SAFETY_MARGINS.DETAILS}
			matchTriggerWidth={true}
		/>
	)),
);

type Props = {
	items: LikeItem[];
	boards: BoardFragmentFragment[];
	onRemove: (board: BoardFragmentFragment) => void;
	onAdd: (board: BoardFragmentFragment) => void;
	onNew: React.MouseEventHandler;
	canRemove?: (board: BoardFragmentFragment) => boolean;
	selectedBoardIDs: Board['_id'][];
};

class SVItemBoardsList extends React.Component<Props> {
	dropdownRef = React.createRef<SVDropdown>();

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.items != nextProps.items && this.dropdownRef.current) {
			this.dropdownRef.current.close();
		}
	}

	render() {
		const { onRemove, onAdd, onNew, canRemove } = this.props;

		const shownBoards = shownBoardsSelector(this.props);
		const availableBoards = availableBoardsSelector(this.props);

		return (
			<ul className="flex flex-col w-full m-auto">
				<_AddButton
					ref={this.dropdownRef}
					availableBoards={availableBoards}
					onAdd={onAdd}
					onNew={onNew}
				/>
				{shownBoards.map((board) => (
					<_ItemButton
						key={board._id}
						board={board}
						onRemove={onRemove}
						canRemove={!canRemove || canRemove(board)}
					/>
				))}
			</ul>
		);
	}
}

export default SVItemBoardsList;
