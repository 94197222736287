import { useApolloClient } from '@apollo/client';
import {
	type Board,
	type DeleteBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import DeleteBoardMutation from '@apps/www/src/www/queries/DeleteBoardMutation';
import SVForm from '@pkgs/shared-client/components/SVForm';
import { SVInputCheckbox, SVInputStatic } from '@pkgs/shared-client/components/SVFormInputs';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import { useRouter } from 'next/router';

const SVDeleteBoardModal = SVModal.create(
	({ _id, name, ownershipType }: Pick<Board, '_id' | 'name' | 'ownershipType'>) => {
		const client = useApolloClient();
		const router = useRouter();
		const authUser = useAuthUser(['url']);
		const close = useModalClose();

		const isTeamBoard = ownershipType === BoardOwnershipType.TEAM;

		const handleSubmit = useEventCallback(
			async (values: Omit<DeleteBoardMutationMutationVariables['input'], 'boardID'>) => {
				if (authUser) {
					const keepItems = isTeamBoard || values.keepItems;

					const { data } = await client.mutate({
						mutation: DeleteBoardMutation,
						variables: {
							input: {
								boardID: _id,
								...values,
								keepItems,
							},
						},
					});

					// If keepItems === false, remove items from any list on cache so lists get updated
					if (!keepItems && data) {
						data.deleteBoard.deletedItemIDs.forEach((deletedItemID) => {
							client.cache.evict({
								id: client.cache.identify({
									__typename: 'Item',
									_id: deletedItemID,
								}),
							});
						});

						client.cache.gc();
					}

					router.push(`${authUser.url}boards/`);
				}

				close();
			},
		);

		return (
			<>
				<SVModal.Title>Delete {isTeamBoard ? 'Team ' : ' '}Board</SVModal.Title>
				<SVForm
					onSubmit={handleSubmit}
					submitLabel="Delete"
					isDelete={true}
					submitAlignment="right"
					initialValues={{ keepItems: true }}
				>
					<SVInputStatic label="Name" value={name} />
					{!isTeamBoard ? <SVInputCheckbox name="keepItems" label="Keep Saves" /> : null}
				</SVForm>
			</>
		);
	},
);

export default SVDeleteBoardModal;
