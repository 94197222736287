import SVBoardHeader from '@apps/www/src/www/components/SVBoardHeader';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import SVPageMeta from './SVPageMeta';

const SVUserHeaderContainer = () => {
	const { user, board } = useUserAndBoardPageQuery();

	if (!user || !board) {
		return null;
	}

	const hasAdminPrivileges = boardUserRoleHasBoardUserRolePrivileges(
		board.role,
		BoardUserRole.ADMIN,
	);
	const hasEditorPrivileges = boardUserRoleHasBoardUserRolePrivileges(
		board.role,
		BoardUserRole.EDITOR,
	);
	const hasViewerPrivileges = boardUserRoleHasBoardUserRolePrivileges(
		board.role,
		BoardUserRole.VIEWER,
	);

	const canSeeItems = !board.isPrivate || hasEditorPrivileges;

	const ownerCollaborator = board.collaborators.find((collaborator) =>
		boardUserRoleHasBoardUserRolePrivileges(collaborator.role, BoardUserRole.OWNER),
	);

	// TODO: Invite should have user attached as we can have multiple board admins
	const inviterUser = ownerCollaborator?.user || user;

	const meta = {
		title: `${board.name} – ${user.name}`,
		image: board.ogImage,
	};

	return (
		<>
			<SVPageMeta {...meta} />
			<SVBoardHeader
				board={board}
				inviterUser={inviterUser}
				hasAdminPrivileges={hasAdminPrivileges}
				hasEditorPrivileges={hasEditorPrivileges}
				hasViewerPrivileges={hasViewerPrivileges}
				canSeeItems={canSeeItems}
			/>
		</>
	);
};

export default SVUserHeaderContainer;
