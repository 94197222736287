import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type DeleteRecentSearchMutationMutation,
	type DeleteRecentSearchMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const DeleteRecentSearchMutation: TypedDocumentNode<
	DeleteRecentSearchMutationMutation,
	DeleteRecentSearchMutationMutationVariables
> = gql`
	mutation DeleteRecentSearchMutation($input: DeleteRecentSearchInput!) {
		deleteRecentSearch(input: $input) {
			_id
			query
			deleted
		}
	}
`;

export default DeleteRecentSearchMutation;
