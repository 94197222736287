import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type RemoveUserFromBoardMutationMutation,
	type RemoveUserFromBoardMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import BoardFragment from './fragments/BoardFragment';

const RemoveUserFromBoardMutation: TypedDocumentNode<
	RemoveUserFromBoardMutationMutation,
	RemoveUserFromBoardMutationMutationVariables
> = gql`
	${BoardFragment}

	mutation RemoveUserFromBoardMutation($input: RemoveUserFromBoardInput!) {
		removeUserFromBoard(input: $input) {
			...BoardFragment
		}
	}
`;

export default RemoveUserFromBoardMutation;
