/**
 * This is rendered whenever withUserHeader or withBoardHeader is true. Renders the tabs for the profile page.
 */

import { type UserQueryQuery } from '@apps/www/src/__generated__/graphql';
import SVBoardSortButtonContainer from '@apps/www/src/www/containers/SVBoardSortButtonContainer';
import SVGridSortButtonContainer from '@apps/www/src/www/containers/SVGridSortButtonContainer';
import { usePageComponentsPresence } from '@apps/www/src/www/hooks/usePageComponentsPresence';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import formatCount from '@pkgs/shared-client/helpers/formatCount';
import plural from '@pkgs/shared-client/helpers/plural';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';

const _SubmenuLabel = (props: OnlyChildrenProps) => (
	<span className="-smp:flex -smp:flex-col -smp:items-center block" {...props} />
);
const _Number = (props: OnlyChildrenProps) => <span className="-smp:block" {...props} />;

const _Navigation = ({
	asOwner,
	user,
}: {
	asOwner: boolean;
	user: UserQueryQuery['userByUsername'];
}) => {
	const subnavItemsConfig = [
		{
			url: `${user.url}`,
			label: (
				<_SubmenuLabel>
					<_Number>{formatCount(user.itemsCount)}</_Number>{' '}
					{plural(user.itemsCount, 'Save', null, true)}
				</_SubmenuLabel>
			),
		},
		(user.boardsCount > 0 || asOwner) && {
			url: `${user.url}boards/`,
			label: (
				<_SubmenuLabel>
					<_Number>{formatCount(user.boardsCount)}</_Number>{' '}
					{plural(user.boardsCount, 'Board', null, true)}
				</_SubmenuLabel>
			),
		},
		(user.followingCount > 0 || asOwner) && {
			url: `${user.url}following/`,
			label: (
				<_SubmenuLabel>
					<_Number>{formatCount(user.followingCount)}</_Number> Following
				</_SubmenuLabel>
			),
		},
		(user.followersCount > 0 || asOwner) && {
			url: `${user.url}followers/`,
			label: (
				<_SubmenuLabel>
					<_Number>{formatCount(user.followersCount)}</_Number>{' '}
					{plural(user.followersCount, 'Follower', null, true)}
				</_SubmenuLabel>
			),
		},
	].filter(Boolean);

	const subnavItems = subnavItemsConfig.map((item) => (
		<SVActions.NavItem key={item.url} to={item.url}>
			{item.label}
		</SVActions.NavItem>
	));

	return <SVActions use={SVActions.USES.PAGENAV}>{subnavItems}</SVActions>;
};

const SVUserHeaderTabsContainer = () => {
	const { isOwner, user, board } = useUserAndBoardPageQuery();
	const { withBoardHeader } = usePageComponentsPresence();

	if (!user) {
		return null;
	}

	const canSeeItems =
		isOwner ||
		(board &&
			(!board.isPrivate ||
				boardUserRoleHasBoardUserRolePrivileges(board.role, BoardUserRole.EDITOR)));

	return (
		<SVPageMargins>
			<div className="mb-1 mt-[2px] flex h-12 flex-wrap items-center justify-between -sm:mb-8">
				{!withBoardHeader ? (
					<div className="-sm:basis-full -sm:justify-center flex items-center">
						<_Navigation user={user} asOwner={isOwner} />
					</div>
				) : null}
				<div className="flex flex-auto items-center justify-end space-x-6 -sm:mt-2">
					{canSeeItems && <SVGridSortButtonContainer />}
					{canSeeItems && <SVBoardSortButtonContainer />}
				</div>
			</div>
		</SVPageMargins>
	);
};

export default SVUserHeaderTabsContainer;
