import useAuthSettings from './useAuthSettings';
import useIsLoggedIn from './useIsLoggedIn';

const useAllowedToSeeNewSearch = () => {
	const isLoggedIn = useIsLoggedIn();
	const authSettings = useAuthSettings(['isModerator']);

	if (!isLoggedIn) {
		return false;
	}

	return authSettings?.isModerator ?? false;
};

export default useAllowedToSeeNewSearch;
