/**
 * This checks if the side menu is rendered, not if it's open or closed. It depends on the
 * user boards, we don't want to render the side menu if the user doesn't have any boards and is not
 * the owner page.
 */

import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';

export default function useIsSideMenuRendered() {
	const { isOwner, user } = useUserAndBoardPageQuery();

	if (!user) {
		return false;
	}

	if (isOwner) {
		return true;
	}

	return !!user.boards.find((board) => board.ownershipType === BoardOwnershipType.USER);
}
