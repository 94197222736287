import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type SearchSuggestionsQueryQuery,
	type SearchSuggestionsQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const SearchSuggestionsQuery: TypedDocumentNode<
	SearchSuggestionsQueryQuery,
	SearchSuggestionsQueryQueryVariables
> = gql`
	query SearchSuggestionsQuery($query: SafeString!) {
		searchSuggestions(query: $query) {
			queries {
				query
				type
			}
			users {
				_id
				name
				username
				avatarURL
				isPro
				url
			}
		}
	}
`;

export default SearchSuggestionsQuery;
