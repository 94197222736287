import { type OgImage } from '@apps/www/src/__generated__/graphql';
import config from '@pkgs/shared-client/config';
import formatURL from '@pkgs/shared/helpers/formatURL';
import Head from 'next/head';
import { useRouter } from 'next/router';

/*
# Titles

Default: Savee
User page: [user-name] – Savee
User boards: Boardss – [user-name] – Savee
User board: [board-name] – [user-name] – Savee
Image detail: [image-name] – [user-name] – Savee
Regular page: [page-title] – Savee
*/

const PRECONNECT_HOSTS = [
	config.staticURL,
	config.storageURL,
	config.s3.enabled && config.s3.baseURL,
	'https://rsms.me/',
].filter(Boolean);

type Props = {
	title?: string | boolean;
	path?: string;
	description?: string;
	keywords?: string;
	image?: OgImage;
};

const SVPageMeta = ({ title, path, description, keywords, image }: Props) => {
	const router = useRouter();
	const formattedTitle = title === true ? 'Savee' : title + ' – Savee';

	const url = path ? formatURL(config.baseURL, path) : formatURL(config.baseURL, router.asPath);
	const ogDescription = title === true ? '' : description;

	const cacheDirt = new Date().getTime();

	return (
		<>
			{title === true && (
				<Head>
					<meta httpEquiv="x-dns-prefetch-control" content="on" />
					{PRECONNECT_HOSTS.map((host) => (
						<link key={`${host}-preconnect`} rel="preconnect" href={host} />
					))}
					{PRECONNECT_HOSTS.map((host) => (
						<link key={`${host}-dns-prefetch`} rel="dns-prefetch" href={host} />
					))}
					<meta charSet="utf-8" />
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
					/>
					<meta name="author" content="Savee" />
					<meta name="pinterest" content="nopin" />
					<meta name="referrer" content="origin" />
					<meta name="application-name" content="Savee" />
					<meta name="msapplication-TileColor" content="#FFFFFF" />
					<meta
						name="msapplication-TileImage"
						content={`${config.staticURL}favicons/ms-icon-144x144.png`}
					/>
					<link rel="subresource" href="" />
					<link
						rel="chrome-webstore-item"
						href="https://chrome.google.com/webstore/detail/savee/hhefhkepfnmcgalemmofagaioeegonbc?hl=en-US"
					/>
					<link
						rel="apple-touch-icon"
						sizes="57x57"
						href={`${config.staticURL}favicons/apple-icon-57x57.png`}
					/>
					<link
						rel="apple-touch-icon"
						sizes="60x60"
						href={`${config.staticURL}favicons/apple-icon-60x60.png`}
					/>
					<link
						rel="apple-touch-icon"
						sizes="72x72"
						href={`${config.staticURL}favicons/apple-icon-72x72.png`}
					/>
					<link
						rel="apple-touch-icon"
						sizes="76x76"
						href={`${config.staticURL}favicons/apple-icon-76x76.png`}
					/>
					<link
						rel="apple-touch-icon"
						sizes="114x114"
						href={`${config.staticURL}favicons/apple-icon-114x114.png`}
					/>
					<link
						rel="apple-touch-icon"
						sizes="120x120"
						href={`${config.staticURL}favicons/apple-icon-120x120.png`}
					/>
					<link
						rel="apple-touch-icon"
						sizes="144x144"
						href={`${config.staticURL}favicons/apple-icon-144x144.png`}
					/>
					<link
						rel="apple-touch-icon"
						sizes="152x152"
						href={`${config.staticURL}favicons/apple-icon-152x152.png`}
					/>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href={`${config.staticURL}favicons/apple-icon-180x180.png`}
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="192x192"
						href={`${config.staticURL}favicons/android-icon-192x192.png`}
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="32x32"
						href={`${config.staticURL}favicons/favicon-32x32.png`}
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="96x96"
						href={`${config.staticURL}favicons/favicon-96x96.png`}
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="16x16"
						href={`${config.staticURL}favicons/favicon-16x16.png`}
					/>
					<link rel="manifest" href={`${config.staticURL}manifest.json`} />
					<link
						rel="search"
						type="application/opensearchdescription+xml"
						href={`${config.staticURL}opensearch.xml`}
						title="Savee"
					/>
					<meta name="mobile-web-app-capable" content="yes" />
					<meta name="apple-mobile-web-app-capable" content="yes" />
					<meta property="fb:app_id" content={config.facebook.id} />
					<meta property="og:site_name" content="Savee" />
					<meta property="og:type" name="og:type" content="website" />
					<meta
						property="twitter:card"
						name="twitter:card"
						content="summary_large_image"
					/>
					<meta property="twitter:creator" name="twitter:creator" content="@saveeit" />
					<meta property="twitter:site" name="twitter:site" content="@saveeit" />
					<meta
						property="twitter:app:android"
						name="twitter:app:android"
						content="it.savee.app"
					/>
					<meta
						property="twitter:app:id:ipad"
						name="twitter:app:id:ipad"
						content="1375238218"
						data-app
					/>
					<meta
						property="twitter:app:id:iphone"
						name="twitter:app:id:iphone"
						content="1375238218"
						data-app
					/>
				</Head>
			)}
			<Head>
				{title && <meta property="og:title" name="og:title" content={formattedTitle} />}
				{description && (
					<meta property="og:description" name="og:description" content={ogDescription} />
				)}
				{/* {url && <meta property="og:url" name="og:url" content={title === true ? image.url : url} />} */}
				{url && <meta property="og:url" name="og:url" content={url} />}
				{image && (
					<meta
						property="og:image"
						name="og:image"
						content={`${image.url}?${cacheDirt}`}
					/>
				)}
				{image && image.width && (
					<meta
						property="og:image:width"
						name="og:image:width"
						content={String(image.width)}
					/>
				)}
				{image && image.height && (
					<meta
						property="og:image:height"
						name="og:image:height"
						content={String(image.height)}
					/>
				)}

				{title && (
					<meta property="twitter:title" name="twitter:title" content={formattedTitle} />
				)}
				{description && (
					<meta
						property="twitter:description"
						name="twitter:description"
						content={ogDescription}
					/>
				)}
				{/* {url && <meta property="twitter:url" name="twitter:url" content={title === true ? image.url : url} />} */}
				{url && <meta property="twitter:url" name="twitter:url" content={url} />}
				{image && (
					<meta
						property="twitter:image"
						name="twitter:image"
						content={`${image.url}?${cacheDirt}`}
					/>
				)}
				{image && image.width && (
					<meta
						property="twitter:image:width"
						name="twitter:image:width"
						content={String(image.width)}
					/>
				)}
				{image && image.height && (
					<meta
						property="twitter:image:height"
						name="twitter:image:height"
						content={String(image.height)}
					/>
				)}

				{title && <title>{formattedTitle}</title>}
				{keywords && <meta name="keywords" content={keywords} />}
				{description && <meta name="description" content={description} />}
				{url && <link rel="canonical" href={url} />}
			</Head>
		</>
	);
};

export default SVPageMeta;
