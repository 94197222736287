import { useApolloClient } from '@apollo/client';
import SVSortDropdownContent from '@apps/www/src/www/components/SVSortDropdownContent';
import useAuthUserID from '@apps/www/src/www/hooks/useAuthUserID';
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BoardsSortMethod from '@pkgs/shared/enums/BoardsSortMethod';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const BOARDS_SORT_METHODS = [
	{
		name: BoardsSortMethod.ALPHABETICAL,
		label: 'Alphabetical',
	},
	{
		name: BoardsSortMethod.UPDATED_AT,
		label: 'Newest first',
	},
	{
		name: BoardsSortMethod.CUSTOM,
		label: 'Custom order',
	},
];

const SVBoardSortButtonContainer = () => {
	const [sortMethod, setSortMethod] = usePersistentSetting(
		PersistentSettingKeys.BOARDS_SORT_METHOD,
	);
	const router = useRouter();
	const pathname = router.pathname;
	const client = useApolloClient();
	const authUserID = useAuthUserID();
	const [showBoardSortMethod, setShowBoardSortMethod] = useState(false);

	const handleUpdateSortMethod = useEventCallback(
		async (sortMethod: ValueOf<typeof BoardsSortMethod>) => {
			await setSortMethod(sortMethod);

			client.cache.evict({
				id: `User:${authUserID}`,
			});

			client.cache.gc();
		},
	);

	useEffect(() => {
		setShowBoardSortMethod(pathname.endsWith('/boards'));
	}, [pathname]);

	// Don't show up if page is not a board
	if (!showBoardSortMethod) {
		return null;
	}

	return (
		<SVSortDropdownContent
			methods={BOARDS_SORT_METHODS}
			sortMethod={sortMethod}
			setSortMethod={(sortMethod) =>
				handleUpdateSortMethod(sortMethod as ValueOf<typeof BoardsSortMethod>)
			}
		/>
	);
};

export default SVBoardSortButtonContainer;
