import { BoardFragmentFragment, TeamFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVCopyBoardPublicURLContainer from '@apps/www/src/www/containers/SVCopyBoardPublicURLContainer';
import SVEditBoardCollaboratorsActionContainer from '@apps/www/src/www/containers/SVEditBoardCollaboratorsActionContainer';
import SVGridSortButtonContainer from '@apps/www/src/www/containers/SVGridSortButtonContainer';
import SVPrintActionContainer from '@apps/www/src/www/containers/SVPrintActionContainer';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import SVDeleteBoardModal from '@apps/www/src/www/modals/SVDeleteBoardModal';
import SVEditBoardModal from '@apps/www/src/www/modals/SVEditBoardModal';
import SVAvatarsStack from '@pkgs/shared-client/components/SVAvatarsStack';
import SVBoardInviteBanner from '@pkgs/shared-client/components/SVBoardInviteBanner';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVPageHeader from '@pkgs/shared-client/components/SVPageHeader';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconChevronDownSVG from '@pkgs/shared-client/img/icon-chevron-down-inlined.svg';
import IconHiddenSVG from '@pkgs/shared-client/img/icon-hidden-inlined.svg';
import IconLockSVG from '@pkgs/shared-client/img/icon-lock-inlined.svg';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';

interface BaseBoardDropdownProps {
	hasAdminPrivileges: boolean;
	handleEdit: () => void;
	handleDelete: () => void;
}

interface UserBoardDropdownProps extends BaseBoardDropdownProps {
	hasViewerPrivileges: boolean;
	board: BoardFragmentFragment;
	hasEditorPrivileges: boolean;
}

const _UserBoardDropdown = ({
	hasViewerPrivileges,
	hasAdminPrivileges,
	hasEditorPrivileges,
	board,
	handleEdit,
	handleDelete,
}: UserBoardDropdownProps) =>
	hasViewerPrivileges ? (
		<div className="flex items-center">
			<SVDropdown
				triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
				renderTrigger={({ isOpen: _, ...props }) => (
					<SVIconButton
						src={IconChevronDownSVG}
						showCircle={false}
						label="More options"
						{...props}
						className="mt-4 text-gray-300"
					/>
				)}
				renderContent={() => (
					<SVDropdownContent.Links>
						{hasAdminPrivileges && (
							<>
								<SVDropdownContent.Links.Item
									className="edit"
									onClick={handleEdit}
									title="Edit board"
								>
									Edit
								</SVDropdownContent.Links.Item>

								{board.isPrivate && (
									<SVCopyBoardPublicURLContainer
										boardID={board._id}
										boardPublicURL={board.publicURL}
									/>
								)}
							</>
						)}

						{!hasAdminPrivileges && (
							<SVDropdownContent.Links.ItemLink
								to={`/api/boards/${board._id}/leave/`}
								title="Leave board"
							>
								Leave board
							</SVDropdownContent.Links.ItemLink>
						)}

						{(hasAdminPrivileges || hasEditorPrivileges) && (
							<SVPrintActionContainer
								Component={SVDropdownContent.Links.Item}
								title="Print board"
								url={board.url}
							>
								Print
							</SVPrintActionContainer>
						)}

						{hasAdminPrivileges && (
							<SVDropdownContent.Links.Item
								title="Delete board"
								onClick={handleDelete}
							>
								Delete
							</SVDropdownContent.Links.Item>
						)}
					</SVDropdownContent.Links>
				)}
			/>
		</div>
	) : null;

const _TeamBoardDropdown = ({
	hasAdminPrivileges,
	handleEdit,
	handleDelete,
}: BaseBoardDropdownProps) => {
	return hasAdminPrivileges ? (
		<div className="flex items-center">
			<SVDropdown
				triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
				renderTrigger={({ isOpen: _, ...props }) => (
					<SVIconButton
						src={IconChevronDownSVG}
						showCircle={false}
						label="More options"
						{...props}
						className="mt-4 text-gray-300"
					/>
				)}
				renderContent={() => (
					<SVDropdownContent.Links>
						<SVDropdownContent.Links.Item
							className="edit"
							onClick={handleEdit}
							title="Edit board"
						>
							Edit
						</SVDropdownContent.Links.Item>
						<SVDropdownContent.Links.Item title="Delete board" onClick={handleDelete}>
							Delete
						</SVDropdownContent.Links.Item>
					</SVDropdownContent.Links>
				)}
			/>
		</div>
	) : null;
};

const SVBoardHeader = ({
	board,
	inviterUser,
	hasAdminPrivileges,
	hasEditorPrivileges,
	hasViewerPrivileges,
	team,
	canSeeItems,
}: {
	board: BoardFragmentFragment;
	inviterUser?: BoardFragmentFragment['collaborators'][number]['user'];
	hasAdminPrivileges: boolean;
	hasEditorPrivileges: boolean;
	hasViewerPrivileges: boolean;
	team?: TeamFragmentFragment;
	canSeeItems?: boolean;
}) => {
	const authSettings = useAuthSettings(['maxBoardCollaborators']);
	const isTeamBoard = board.ownershipType === BoardOwnershipType.TEAM;

	const handleEdit = useEventCallback(() => {
		if (!board) {
			return;
		}

		SVModal.open(SVEditBoardModal, {
			board,
		});
	});

	const handleDelete = useEventCallback(() => {
		if (!board) {
			return;
		}

		SVModal.open(SVDeleteBoardModal, {
			_id: board._id,
			name: board.name,
			ownershipType: board.ownershipType,
		});
	});

	return (
		<SVPageHeader className="mb-11 mt-3 flex flex-col items-start">
			{!isTeamBoard && inviterUser && board.acceptURL && board.rejectURL && (
				<SVBoardInviteBanner
					userName={inviterUser.name}
					userURL={inviterUser.url}
					acceptURL={board.acceptURL}
					rejectURL={board.rejectURL}
				/>
			)}

			<div className="flex-center items-center">
				<div className="line-clamp-1 w-full max-w-[70vw] truncate">
					<div className="-md:ml-0 -lg:text-6xl -md:text-5xl -sm:text-4xl ml-[-0.5%] truncate pr-2 text-start text-8xl text-gray-200">
						{board.name}
					</div>
				</div>

				{!isTeamBoard ? (
					<_UserBoardDropdown
						hasViewerPrivileges={hasViewerPrivileges}
						hasAdminPrivileges={hasAdminPrivileges}
						hasEditorPrivileges={hasEditorPrivileges}
						board={board}
						handleEdit={handleEdit}
						handleDelete={handleDelete}
					/>
				) : (
					<_TeamBoardDropdown
						hasAdminPrivileges={hasAdminPrivileges}
						handleEdit={handleEdit}
						handleDelete={handleDelete}
					/>
				)}
			</div>

			<div className="ml-1 flex flex-wrap self-stretch">
				<div className="-sm:order-1 flex items-center space-x-6">
					{!isTeamBoard ? (
						<div className="flex-row items-center">
							<div>
								<SVEditBoardCollaboratorsActionContainer
									board={board}
									isAdmin={hasAdminPrivileges}
									render={(props) => (
										<SVAvatarsStack
											size={SVAvatarsStack.SIZES.DEFAULT}
											users={board.collaborators}
											invites={board.invites}
											showAdd={
												(hasAdminPrivileges &&
													authSettings &&
													board.collaborators.length +
														board.invites.length <
														authSettings?.maxBoardCollaborators) ||
												false
											}
											showDropdown={false}
											visibleCount={8}
											{...props}
										/>
									)}
								/>
							</div>
						</div>
					) : (
						<div>
							<span className="text-gray-600">{team?.name}</span>
						</div>
					)}

					<div>
						<span className="text-gray-600">{plural(board.itemsCount, 'save')}</span>
					</div>

					{!isTeamBoard ? (
						<div>
							{board.isPrivate && (
								<div className="flex-center items-center space-x-2 text-gray-600">
									<IconLockSVG className="h-4 w-4 items-center text-gray-600" />
									<span>Private board</span>
								</div>
							)}
						</div>
					) : null}

					{board.isHidden && (
						<div className="flex-center items-center space-x-2 text-gray-600">
							<IconHiddenSVG className="h-4 w-4 items-center text-gray-600" />
							<span>Hidden board</span>
						</div>
					)}
				</div>

				<div className="-sm:order-2 -smp:ml-0 -sm:basis-1/2 my-auto ml-auto flex flex-wrap items-center space-x-6 ">
					{isTeamBoard ? (
						<>{hasViewerPrivileges && <SVGridSortButtonContainer />}</>
					) : null}
				</div>
			</div>
		</SVPageHeader>
	);
};

export default SVBoardHeader;
