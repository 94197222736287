import {
	Item,
	type ItemFragmentFragment,
	type UserQueryQuery,
} from '@apps/www/src/__generated__/graphql';
import SVGridHideAssetContainer from '@apps/www/src/www/containers/SVGridHideAssetContainer';
import filterRealItems from '@apps/www/src/www/helpers/filterRealItems';
import useAllowedToHideAssets from '@apps/www/src/www/hooks/useAllowedToHideAssets';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useGridPageRole from '@apps/www/src/www/hooks/useGridPageRole';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import { type RootState } from '@apps/www/src/www/reducers';
import { toggleEditing } from '@apps/www/src/www/reducers/grid';
import SVActions from '@pkgs/shared-client/components/SVActions';
import { type LikeItem } from '@pkgs/shared-client/components/SVGrid';
import SVGridEditBar from '@pkgs/shared-client/components/SVGridEditBar';
import SVKeyboardKey from '@pkgs/shared-client/components/SVKeyboardKey';
import SVRetractableBar from '@pkgs/shared-client/components/SVRetractableBar';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import SVDeleteItemsActionContainer from './SVDeleteItemsActionContainer';
import SVItemBoardsContainer from './SVItemBoardsContainer';
import SVPrintActionContainer from './SVPrintActionContainer';
import SVRemoveItemsFromBoardActionContainer from './SVRemoveItemsFromBoardActionContainer';
import SVReportItemsActionContainer from './SVReportItemsActionContainer';
import SVSaveItemsActionContainer from './SVSaveItemsActionContainer';
import SVShareActionContainer from './SVShareActionContainer';
import SVToggleItemsPrivacyActionContainer from './SVToggleItemsPrivacyActionContainer';

type SelectorProps = {
	selectedItemIDs: Item['_id'][];
	items: LikeItem[];
};

const selectedItemsSelector = createSelector(
	(props: SelectorProps) => props.selectedItemIDs,
	(props: SelectorProps) => props.items,
	(selectedItemIDs, items) => {
		return filterRealItems(items || []).filter((item) => {
			// Only selected and non uploading
			return selectedItemIDs.includes(item._id);
		});
	},
);

const _Bar = React.memo(
	({
		isEditing,
		selectedItems,
		board,
		role,
		isLogged,
		isModerator,
		onClose,
	}: {
		selectedItems: ReturnType<typeof selectedItemsSelector> | null;
		board: ArrayElement<UserQueryQuery['userByUsername']['boards']> | null;
		role: ValueOf<typeof BoardUserRole> | null;
		isLogged: boolean;
		isModerator: boolean;
		onClose: () => void;
		isEditing: boolean;
	}) => {
		let actions: React.ReactNode = null;
		let boardList: React.ReactNode = null;
		const isAllowedToHideAssets = useAllowedToHideAssets();

		if (selectedItems && selectedItems.length) {
			const hasAdminPrivileges = boardUserRoleHasBoardUserRolePrivileges(
				role,
				BoardUserRole.ADMIN,
			);
			const hasEditorPrivileges = boardUserRoleHasBoardUserRolePrivileges(
				role,
				BoardUserRole.EDITOR,
			);

			const canSaveItems = selectedItems.every((item) => !item.isOwner);
			const ownAllItems = selectedItems.every((item) => item.isOwner);

			actions = (
				<SVActions use={SVActions.USES.RETRACTABLE_BAR}>
					{hasEditorPrivileges && (
						<SVPrintActionContainer Component={SVActions.Item} items={selectedItems}>
							Save&nbsp;as PDF
						</SVPrintActionContainer>
					)}
					{hasEditorPrivileges && (
						<SVShareActionContainer
							Component={SVActions.Item}
							title="Share"
							items={selectedItems}
						>
							Share
						</SVShareActionContainer>
					)}
					{hasAdminPrivileges && ownAllItems && (
						<SVToggleItemsPrivacyActionContainer
							items={selectedItems}
							render={(props) => (
								<SVActions.ItemToggle
									use={SVActions.USES.RETRACTABLE_BAR}
									{...props}
								/>
							)}
						/>
					)}
					{board && (hasAdminPrivileges || ownAllItems) && (
						<SVRemoveItemsFromBoardActionContainer items={selectedItems} />
					)}
					{isAllowedToHideAssets && (
						<SVGridHideAssetContainer
							renderButton={(buttonProps) => (
								<SVActions.Item
									use={SVActions.USES.RETRACTABLE_BAR}
									{...buttonProps}
								>
									Hide from feed
								</SVActions.Item>
							)}
							items={selectedItems}
						/>
					)}

					{!hasAdminPrivileges && isLogged && isModerator && (
						<SVReportItemsActionContainer
							items={selectedItems}
							render={(props) => (
								<SVActions.Item
									title="Report as inappropriate"
									keys={'r'}
									use={SVActions.USES.RETRACTABLE_BAR}
									{...props}
								>
									Report
								</SVActions.Item>
							)}
						/>
					)}
					{canSaveItems && (
						<SVSaveItemsActionContainer
							use={SVActions.USES.RETRACTABLE_BAR}
							items={selectedItems}
						/>
					)}
					{ownAllItems && (
						<SVDeleteItemsActionContainer
							items={selectedItems}
							render={(props) => (
								<SVActions.Item use={SVActions.USES.RETRACTABLE_BAR} {...props}>
									Delete
								</SVActions.Item>
							)}
						/>
					)}
				</SVActions>
			);

			boardList = <SVItemBoardsContainer items={selectedItems} />;
		}

		return (
			<SVRetractableBar
				position={SVRetractableBar.POSITIONS.BOTTOM}
				isOpen={isEditing}
				render={() => (
					<SVGridEditBar
						onClose={onClose}
						actions={actions}
						boardList={boardList}
						itemsCount={selectedItems && selectedItems.length}
					/>
				)}
			/>
		);
	},
);

const _SVGridEditBarContainer = () => {
	const isLoggedIn = useIsLoggedIn();
	const authSettings = useAuthSettings(['isModerator']);
	const role = useGridPageRole();
	const [items] = useUIState(UIStateKeys.CURRENT_PAGE_GRID_ITEMS);
	const { board } = useUserAndBoardPageQuery();
	const [showGridControls] = useUIState(UIStateKeys.SHOW_GRID_CONTROLS);
	const { isEditing, selectedItemIDs } = useSelector((state: RootState) => ({
		isEditing: state.grid.isEditing,
		selectedItemIDs: state.grid.selectedItemIDs,
	}));
	const dispatch = useDispatch();

	const handleClose = useEventCallback(() => {
		if (isEditing) {
			dispatch(toggleEditing());
		}
	});

	const handleShiftTrigger = useEventCallback(() => {
		if (!isEditing) {
			dispatch(toggleEditing());
		}
	});

	let selectedItems: ItemFragmentFragment[] | null = null;

	if (isEditing) {
		selectedItems = selectedItemsSelector({ selectedItemIDs, items });
	}

	return (
		<>
			<SVKeyboardKey
				keys={['m']}
				onTrigger={handleShiftTrigger}
				isDisabled={!showGridControls}
			/>
			<_Bar
				selectedItems={selectedItems}
				board={board}
				isEditing={isEditing}
				role={role}
				isLogged={isLoggedIn}
				isModerator={authSettings?.isModerator || false}
				onClose={handleClose}
			/>
		</>
	);
};

const SVGridEditBarContainer = React.memo(_SVGridEditBarContainer);

export default SVGridEditBarContainer;
