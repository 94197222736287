import useAllowedToHideAssets from '@apps/www/src/www/hooks/useAllowedToHideAssets';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useHideAsset from '@apps/www/src/www/hooks/useHideAsset';
import { LikeItem } from '@pkgs/shared-client/components/SVGrid';

type Props = {
	items: LikeItem[];
	renderButton: (props: ButtonProps) => JSX.Element;
};

type ButtonProps = {
	onClick: (event: React.UIEvent) => void;
};

const _SVGridHideAssetContainer = ({ items, renderButton }: Props) => {
	const isLoggedIn = useIsLoggedIn();
	const hideAsset = useHideAsset(items);

	if (!isLoggedIn) {
		return null;
	}

	const handleClick = (event: React.UIEvent) => {
		event.preventDefault();
		event.stopPropagation();
		hideAsset && hideAsset();
	};

	return renderButton({ onClick: handleClick });
};

const SVGridHideAssetContainer = (props: Props) => {
	const isLoggedIn = useIsLoggedIn();
	const isAllowedToHideAssets = useAllowedToHideAssets();

	if (!isLoggedIn || !isAllowedToHideAssets) {
		return null;
	}

	return <_SVGridHideAssetContainer {...props} />;
};

export default SVGridHideAssetContainer;
