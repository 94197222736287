import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import SVGrid from '@pkgs/shared-client/components/SVGrid';

const useAllowedToHideAssets = (): boolean => {
	const isLoggedIn = useIsLoggedIn();
	const [sourceGridType] = useUIState(UIStateKeys.SOURCE_GRID_TYPE);

	if (!isLoggedIn) {
		return false;
	}

	if (
		sourceGridType !== SVGrid.SOURCE_TYPES.FEED &&
		sourceGridType !== SVGrid.SOURCE_TYPES.TEAM_FEED
	) {
		return false;
	}

	return true;
};

export default useAllowedToHideAssets;
