import {
	type ItemFragmentFragment,
	type UserFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from '@apps/www/src/www/containers/SVWithTooltipErrorBoundary';
import useAllowedBoardsToSave from '@apps/www/src/www/hooks/useAllowedBoardsToSave';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import useSaveItem from '@apps/www/src/www/hooks/useSaveItem';
import SVNewBoardModal from '@apps/www/src/www/modals/SVNewBoardModal';
import SVActions from '@pkgs/shared-client/components/SVActions';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVModal from '@pkgs/shared-client/components/SVModal';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';

type Props = {
	items: ItemFragmentFragment[];
	use?: ValueOf<typeof SVActions.USES>;
} & SVWithTooltipErrorBoundaryProps;

const _SVSaveItemsActionContainer = ({ items, use, showTooltipError }: Props) => {
	const { isSaved, isLoading, itemsCount, toggleSave, enableLongPress } = useSaveItem({
		items,
		showTooltipError,
	});
	const boards = useAllowedBoardsToSave(items);

	const handleClick = useEventCallback((event: React.UIEvent) => {
		toggleSave();

		if (event) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
	});

	const handleSaveToBoard = useEventCallback(
		(board: ArrayElement<UserFragmentFragment['boards']>) => {
			toggleSave(board._id);
		},
	);

	const handleSaveToNewBoard = useAllowedFeatureCheckEvent(AllowedFeature.ADD_BOARD, () => {
		SVModal.open(SVNewBoardModal, {
			items,
			ownershipType: BoardOwnershipType.USER,
		});
	});

	if (!enableLongPress) {
		return (
			<SVActions.Item
				keys={'s'}
				use={use}
				onClick={handleClick}
				isLoading={isLoading}
				title={plural(itemsCount, 'Save')}
			>
				{isSaved ? 'Saved' : 'Save'}
			</SVActions.Item>
		);
	}

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.LONGPRESS}
			renderTrigger={({ isOpen: _, ...props }) => {
				return (
					<SVActions.Item
						keys={'s'}
						use={use}
						onClick={handleClick}
						isLoading={isLoading}
						title={plural(itemsCount, 'Save')}
						{...props}
					>
						{isSaved ? 'Saved' : 'Save'}
					</SVActions.Item>
				);
			}}
			renderContent={() => (
				<SVDropdownContent.Boards
					boards={boards}
					selectedIDs={items.flatMap((item) => item.asset.ownBoards.map((board) => board._id))}
					onNewBoard={handleSaveToNewBoard}
					onBoardClick={handleSaveToBoard}
				/>
			)}
			maxHeight={520}
		/>
	);
};

const SVSaveItemsActionContainer = SVWithTooltipErrorBoundary(_SVSaveItemsActionContainer);

export default SVSaveItemsActionContainer;
