import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type UpdateBoardUserRoleMutationMutation,
	type UpdateBoardUserRoleMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import BoardCollaboratorFragment from './fragments/BoardCollaboratorFragment';

const UpdateBoardUserRoleMutation: TypedDocumentNode<
	UpdateBoardUserRoleMutationMutation,
	UpdateBoardUserRoleMutationMutationVariables
> = gql`
	${BoardCollaboratorFragment}

	mutation UpdateBoardUserRoleMutation($input: UpdateBoardUserRoleInput!) {
		updateBoardUserRole(input: $input) {
			_id
			collaborators {
				...BoardCollaboratorFragment
			}
		}
	}
`;

export default UpdateBoardUserRoleMutation;
