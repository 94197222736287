import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type TopSearchQueriesQueryQuery,
	type TopSearchQueriesQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const TopSearchQueriesQuery: TypedDocumentNode<
	TopSearchQueriesQueryQuery,
	TopSearchQueriesQueryQueryVariables
> = gql`
	query TopSearchQueriesQuery {
		topSearchQueries {
			_id
			query
			asset {
				_id
				image {
					thumbnail
				}
				colors {
					color
				}
			}
		}
	}
`;

export default TopSearchQueriesQuery;
