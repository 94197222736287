import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type UploadItemMutationMutation,
	type UploadItemMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import ItemFragment from './fragments/ItemFragment';

const UploadItemMutation: TypedDocumentNode<
	UploadItemMutationMutation,
	UploadItemMutationMutationVariables
> = gql`
	${ItemFragment}

	mutation UploadItemMutation($input: UploadItemInput!) {
		uploadItem(input: $input) {
			auth {
				user {
					_id
					itemsCount
				}
				settings {
					_id
					needsUpgradeReason
				}
			}
			board {
				_id
				thumbnails {
					image {
						thumbnail
					}
				}
				itemsCount
				lastItemAddedOrder
				lastItemAddedAt
			}
			item {
				...ItemFragment
			}
		}
	}
`;

export default UploadItemMutation;
