import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import formatCount from '@pkgs/shared-client/helpers/formatCount';
import IconNotificationSVG from '@pkgs/shared-client/img/icon-notification-inlined.svg';
import IconNotificationMenuSVG from '@pkgs/shared-client/img/icon-notification-menu-inlined.svg';
import SmallMenuNotificationIcon from '@pkgs/shared-client/img/icon-notifications-cropped-inlined.svg';
import clsx from 'clsx';
import React from 'react';

type Props = {
	count?: number;
	onClick?: (event: React.UIEvent) => void;
	onMouseOver?: (event: React.UIEvent) => void;
	isOutsideTheMenu?: boolean;
	iconClassName?: string;
	isSmallNav?: boolean;
};

const SVNotificationIcon = ({
	count = 0,
	isOutsideTheMenu,
	isSmallNav,
	onClick: _,
	iconClassName,
	...otherProps
}: Props) => {
	const viewportName = useViewportName();
	const isSmall =
		viewportName === 'small' || viewportName === 'medium' || viewportName === 'medium-portrait';

	const NotificationIcon = isSmallNav ? IconNotificationMenuSVG : IconNotificationSVG;

	return count > 0 ? (
		isSmallNav ? (
			<SVIconButton
				{...otherProps}
				Component="span"
				src={SmallMenuNotificationIcon}
				label="Notification"
				disableHover
			/>
		) : (
			<span
				className={clsx(
					'text-background duration-over bg-primary flex h-5 items-center justify-center rounded-full font-medium leading-[20px] transition ease-out group-hover:brightness-75 -sm:hidden',
					count > 9 && !isSmall
						? 'w-auto px-2  py-3 text-[13px]'
						: '-md:px-2.5 -md:py-2.5 w-5 px-3 py-3 text-[12px]',
					isSmall && '-md:ml-1.5 -sm:ml-0.5',
				)}
			>
				{formatCount(Math.min(isSmall ? 99 : 9999, count))}
			</span>
		)
	) : isOutsideTheMenu ? (
		<SVIconButton
			{...otherProps}
			Component="span"
			src={NotificationIcon}
			label="Notification"
			className={clsx(
				isSmallNav ? 'h-full w-full pt-[1.5px]' : 'h-6 w-6 text-gray-400 ',
				iconClassName,
			)}
		/>
	) : null;
};

export default SVNotificationIcon;
