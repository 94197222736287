import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type SuggestedInvitedUsersQueryQuery,
	type SuggestedInvitedUsersQueryQueryVariables,
} from '@apps/www/src/__generated__/graphql';

const SuggestedInvitedUsersQuery: TypedDocumentNode<
	SuggestedInvitedUsersQueryQuery,
	SuggestedInvitedUsersQueryQueryVariables
> = gql`
	query SuggestedInvitedUsersQuery($type: InviteType!, $resourceID: ID, $query: String) {
		suggestedInvitedUsers(type: $type, resourceID: $resourceID, query: $query) {
			_id
			username
			name
			avatarURL
			url
			isPro
		}
	}
`;

export default SuggestedInvitedUsersQuery;
