import { useApolloClient, useMutation } from '@apollo/client';
import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import BoardItemsQuery from '@apps/www/src/www/queries/BoardItemsQuery';
import RemoveItemsFromBoardMutation from '@apps/www/src/www/queries/RemoveItemsFromBoardMutation';
import SVActions from '@pkgs/shared-client/components/SVActions';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';
import React, { useEffect } from 'react';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from './SVWithTooltipErrorBoundary';

const _Item = React.memo(
	({ isLoading, onClick }: { isLoading: boolean; onClick: (event: React.UIEvent) => void }) => (
		<SVActions.Item isLoading={isLoading} onClick={onClick} use={SVActions.USES.RETRACTABLE_BAR}>
			Remove from board
		</SVActions.Item>
	),
);

type Props = {
	items: ItemFragmentFragment[];
} & SVWithTooltipErrorBoundaryProps;

const _SVRemoveItemsFromBoardActionContainer = ({ items, showTooltipError }: Props) => {
	const client = useApolloClient();
	const { board } = useUserAndBoardPageQuery();

	const [removeItemsFromBoard, { loading, error }] = useMutation(RemoveItemsFromBoardMutation, {
		variables: {
			input: {
				boardID: board?._id || '',
				itemIDs: items.map((item) => item._id),
			},
		},
	});

	const handleClick = useEventCallback(async () => {
		if (!board) {
			return;
		}

		await removeItemsFromBoard();

		// Remove items from cached query
		Object.values(ItemsSortMethod).forEach((sortMethod) => {
			client.cache.updateQuery(
				{
					query: BoardItemsQuery,
					variables: {
						boardID: board._id,
						sortMethod,
					},
				},
				(data) =>
					data && {
						...data,
						boardByID: {
							...data.boardByID,
							items: {
								...data.boardByID.items,
								items: data.boardByID.items.items.filter(
									(item) => !items.some((i) => i._id === item._id),
								),
							},
						},
					},
			);
		});
	});

	useEffect(() => {
		showTooltipError(error);
	}, [showTooltipError, error]);

	if (!board) {
		return null;
	}

	return <_Item isLoading={loading} onClick={handleClick} />;
};

const SVRemoveItemsFromBoardActionContainer = SVWithTooltipErrorBoundary(
	_SVRemoveItemsFromBoardActionContainer,
);

export default SVRemoveItemsFromBoardActionContainer;
