import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type InviteUsersToTeamMutationMutation,
	type InviteUsersToTeamMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import InviteFragment from './fragments/InviteFragment';

const InviteUsersToTeamMutation: TypedDocumentNode<
	InviteUsersToTeamMutationMutation,
	InviteUsersToTeamMutationMutationVariables
> = gql`
	${InviteFragment}

	mutation InviteUsersToTeamMutation($input: InviteUsersToTeamInput!) {
		inviteUsersToTeam(input: $input) {
			_id
			invites {
				...InviteFragment
			}
		}
	}
`;

export default InviteUsersToTeamMutation;
