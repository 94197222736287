/**
 * A scroll sensor that triggers a callback when the element is in view.
 */

import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import Observer from 'react-intersection-observer';

import { useState } from 'react';

export default function SVScrollSensor({
	className,
	onVisible,
	onHidden,
	children,
}: React.PropsWithChildren<{
	className?: string;
	onVisible: () => void;
	onHidden: () => void;
}>) {
	const [isInView, setIsInView] = useState(false);

	const handleObserverChange = useEventCallback((inView: boolean) => {
		if (inView && !isInView) {
			onVisible();
		} else if (!inView && isInView) {
			onHidden();
		}

		setIsInView(inView);
	});

	return (
		<Observer className={className} onChange={handleObserverChange}>
			{children}
		</Observer>
	);
}
