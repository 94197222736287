import { useApolloClient } from '@apollo/client';
import { type Board, type User } from '@apps/www/src/__generated__/graphql';
import BoardItemsQuery from '@apps/www/src/www/queries/BoardItemsQuery';
import RemoveUserFromBoardMutation from '@apps/www/src/www/queries/RemoveUserFromBoardMutation';
import RemoveUserFromTeamMutation from '@apps/www/src/www/queries/RemoveUserFromTeamMutation';
import SVForm from '@pkgs/shared-client/components/SVForm';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import fieldNameFromQuery from '@pkgs/shared-client/helpers/fieldNameFromQuery';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import InviteType from '@pkgs/shared/enums/InviteType';

const _SVRemoveUserFromTeamOrBoardModal = (
	props: {
		userID: User['_id'] | undefined;
		name: User['name'] | undefined;
		email: string | undefined;
	} & (
		| {
				type: typeof InviteType.TEAM;
		  }
		| {
				type: typeof InviteType.BOARD;
				_id: Board['_id'];
		  }
	),
) => {
	const { userID, name, email } = props;
	const close = useModalClose();

	const client = useApolloClient();

	const handleSubmit = useEventCallback(async () => {
		if (props.type === InviteType.TEAM) {
			await client.mutate({
				mutation: RemoveUserFromTeamMutation,
				variables: {
					input: {
						userID: userID,
						email: email,
					},
				},
			});
		} else if (props.type === InviteType.BOARD) {
			await client.mutate({
				mutation: RemoveUserFromBoardMutation,
				variables: {
					input: {
						boardID: props._id,
						userID: userID,
						email: email,
					},
				},
			});

			// Remove query from cache to refetch the updated items
			const fieldName = fieldNameFromQuery(BoardItemsQuery, {
				boardID: props._id,
			});
			if (fieldName) {
				client.cache.evict({
					id: 'ROOT_QUERY',
					fieldName,
					broadcast: false,
				});
			}

			client.cache.gc();
		}

		await close();
	});

	return (
		<>
			<SVModal.Title>Remove {name || email}?</SVModal.Title>
			<SVForm
				onSubmit={handleSubmit}
				submitLabel={`Remove`}
				isDelete={true}
				noPadding={true}
				submitAlignment="right"
			></SVForm>
		</>
	);
};

const SVRemoveUserFromTeamOrBoardModal = SVModal.create(_SVRemoveUserFromTeamOrBoardModal, {
	className: 'w-[380px] -sm:w-full',
});

export default SVRemoveUserFromTeamOrBoardModal;
