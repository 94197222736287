import { gql } from '@apollo/client';

const TeamUserFragment = gql`
	fragment TeamUserFragment on TeamUser {
		_id
		user {
			_id
			name
			avatarURL
			username
			url
			isPro
			canFollow
			isFollowing
			isFollowingBack
			boardsCount
			itemsCount
		}
		email
		workEmail
		lastActivityAt
		role
		courseProgress {
			progress
			episodes {
				key
				progress
			}
		}
	}
`;

export default TeamUserFragment;
