import {
	isModifiedEvent,
	isTouch,
	matchesClosest,
	supportsPassive,
} from '@pkgs/shared-client/helpers/dom';
import {
	addLongPressListener,
	removeLongPressListener,
} from '@pkgs/shared-client/helpers/longPress';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconCloseSVG from '@pkgs/shared-client/img/icon-close-inlined.svg';
import clsx from 'clsx';
import { type EmptyObject } from 'lodash';
import { useRouter } from 'next/router';
import maxSize from 'popper-max-size-modifier';
import React, { CSSProperties, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import useResizeAware from 'react-resize-aware';
import { usePrevious } from 'react-use';
import { twMerge } from 'tailwind-merge';
import SVIconButton from './SVIconButton';
import SVTransition from './SVTransition';

type PopperOptions = NonNullable<Parameters<typeof usePopper>[2]>;

const TRIGGER_TYPES = {
	HOVER: 'hover',
	CLICK: 'click',
	LONGPRESS: 'longpress',
	NONE: 'none',
} as const;

const POSITION_STRATEGIES = {
	DYNAMIC: 'dynamic',
	FIXED: 'fixed',
} as const;

const SAFETY_MARGINS = {
	DEFAULT: 'default',
	REDUCED: 'reduced',
	DETAILS: 'details',
} as const;

const POPPER_OPTIONS = {
	placement: 'auto',
	modifiers: [
		{
			name: 'flip',
			options: {
				allowedAutoPlacements: ['top', 'bottom'], // by default, all the placements are allowed
			},
		},
		{
			...maxSize,
			options: {
				// rootBoundary: 'document',
				// padding: 32,
			},
		},
		{
			name: 'applyMaxSize',
			enabled: true,
			phase: 'beforeWrite',
			requires: ['maxSize'],
			fn({ state, options }) {
				const opt = options as AnyObject;
				const { height, width } = state.modifiersData.maxSize;
				const isReducedMargin = opt.safetyMargin === SAFETY_MARGINS.REDUCED;

				const maxHeight =
					'maxHeight' in opt && opt.maxHeight > 0
						? Math.min(opt.maxHeight, height)
						: height;
				const maxWidth = Math.min(448, Math.max(width, 120));

				const widthPadding = isReducedMargin ? 8 : 16;
				const heightPadding = isReducedMargin ? 16 : 24 + 12;

				state.styles.popper.maxWidth = `${maxWidth - widthPadding}px`;
				state.styles.popper.maxHeight = `${maxHeight - heightPadding}px`;
			},
		},
		{ name: 'arrow', options: { padding: 11 + 12 } },
		{
			name: 'preventOverflow',
			options: {
				rootBoundary: 'document',
				padding: 32,
			},
		},
	],
} as const;

const _Content = ({
	hasClose,
	maxHeight,
	renderContent,
	onResize,
	hideOutlineStroke = false,
	style,
}: {
	hasClose: boolean;
	maxHeight: CSSProperties['maxHeight'];
	renderContent: (props: EmptyObject<any>) => React.ReactNode;
	onResize: () => void;
	hideOutlineStroke?: boolean;
	style?: CSSProperties;
}) => {
	const [resizeListener, sizes] = useResizeAware();

	useEffect(() => {
		onResize();
	}, [sizes.width, sizes.height, onResize]);

	return (
		<div
			className={clsx(
				'text-secondary relative flex flex-col items-stretch overflow-y-auto overscroll-contain rounded-xl bg-gray-900 bg-opacity-90',
				hasClose && 'min-h-[32px]',
				'backdrop-hide backdrop-blur-lg transition-all duration-500 ease-in-out',
				hideOutlineStroke && 'border-none border-transparent',
			)}
			style={{
				maxHeight,
				width: '100%',
				boxSizing: 'border-box',
				...style,
			}}
		>
			{resizeListener}
			{renderContent({})}
		</div>
	);
};

const _PositionAwareDropdown = ({
	renderTrigger,
	renderContent,
	isOpen,
	onMouseOver,
	onMouseOut,
	onClick,
	onClose,
	wrapperRef,
	triggerRef,
	positionStrategy = POSITION_STRATEGIES.DYNAMIC,
	maxHeight,
	className,
	hideOutlineStroke,
	safetyMargin = SAFETY_MARGINS.DEFAULT,
	matchTriggerWidth,
}: Pick<
	Props,
	| 'renderTrigger'
	| 'renderContent'
	| 'onClose'
	| 'onMouseOver'
	| 'positionStrategy'
	| 'maxHeight'
	| 'className'
	| 'hideOutlineStroke'
	| 'safetyMargin'
	| 'matchTriggerWidth'
> & {
	isOpen: boolean;
	onClick: React.MouseEventHandler;
	onMouseOut: React.MouseEventHandler;
	wrapperRef: React.MutableRefObject<HTMLElement | null>;
	triggerRef: React.MutableRefObject<HTMLElement | null>;
}) => {
	const popperOptions: PopperOptions = {
		...POPPER_OPTIONS,
		modifiers: [...(POPPER_OPTIONS.modifiers as NonNullable<PopperOptions['modifiers']>)].map(
			(modifier) => {
				if (maxHeight !== undefined && maxHeight > 0 && modifier.name === 'applyMaxSize') {
					return {
						...modifier,
						options: {
							...(modifier.options || {}),
							maxHeight,
							safetyMargin,
						},
					};
				}

				if (
					safetyMargin === SAFETY_MARGINS.REDUCED &&
					modifier.name === 'preventOverflow'
				) {
					return {
						...modifier,
						options: {
							...(modifier.options || {}),
							padding: 10,
						},
					};
				}

				if (safetyMargin === SAFETY_MARGINS.DETAILS) {
					return {
						...modifier,
						options: {
							...(modifier.options || {}),
							padding: 26,
						},
					};
				}

				return modifier;
			},
		),
		...(positionStrategy === POSITION_STRATEGIES.FIXED
			? {
					strategy: 'fixed',
			  }
			: {}),
	};

	const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
	const {
		styles,
		attributes,
		state,
		update: updatePopper,
	} = usePopper(referenceElement, popperElement, popperOptions);

	const setWrapperRef = useEventCallback((ref: HTMLElement | null) => {
		if (wrapperRef) {
			wrapperRef.current = ref;
		}
	});

	const setTriggerRef = useEventCallback((ref: HTMLElement | null) => {
		if (triggerRef) {
			triggerRef.current = ref;
		}

		setReferenceElement(ref);
	});

	const onContentResize = useEventCallback(() => {
		updatePopper && updatePopper();
	});

	const isInverted = state?.placement === 'top';
	// const classEnterFrom = clsx('opacity-0', isInverted ? 'translate-y-3' : '-translate-y-3');
	// const classEnterTo = clsx('opacity-100', 'translate-y-0');
	const classEnterFrom = 'opacity-0';
	const classEnterTo = 'opacity-100';

	const [triggerWidth, setTriggerWidth] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (matchTriggerWidth && referenceElement) {
			// Force a recalculation when the dropdown opens
			const width = referenceElement.getBoundingClientRect().width;
			setTriggerWidth(width);

			// Update width on resize
			const handleResize = () => {
				if (referenceElement) {
					setTriggerWidth(referenceElement.getBoundingClientRect().width);
				}
			};

			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, [matchTriggerWidth, referenceElement, isOpen]);

	return (
		<div
			className={twMerge('dropdown-trigger relative flex', className)}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
			ref={setWrapperRef}
		>
			{renderTrigger({ ref: setTriggerRef, isOpen: isOpen })}

			<SVTransition
				show={isOpen}
				className="z-index-dropdown duration-over absolute bottom-0 transition-all ease-out"
				enterFrom={classEnterFrom}
				enterTo={classEnterTo}
				leaveFrom={clsx(classEnterTo, 'pointer-events-none')}
				leaveTo={clsx(classEnterFrom, 'pointer-events-none')}
			>
				<div
					ref={setPopperElement}
					style={{
						...styles.popper,
						minWidth:
							matchTriggerWidth && triggerWidth ? `${triggerWidth}px` : 'max-content',
						width: matchTriggerWidth && triggerWidth ? `${triggerWidth}px` : undefined,
					}}
					{...attributes.popper}
					onClick={onClick}
				>
					<div className="type-base relative flex cursor-default flex-col whitespace-normal text-left normal-case">
						{!isInverted && <div className={clsx('h-[5px] w-full')} />}

						{!!onClose && (
							<SVIconButton
								className="no-touch:hidden absolute right-[10px] top-[10px]"
								iconClassName="w-[12px] h-[12px] min-w-[12px] min-h-[12px]"
								onClick={onClose}
								src={IconCloseSVG}
								label="Close"
								disableHover
							/>
						)}

						<_Content
							hasClose={!!onClose}
							maxHeight={styles.popper.maxHeight}
							renderContent={renderContent}
							onResize={onContentResize}
							hideOutlineStroke={hideOutlineStroke}
							style={{ width: '100%' }}
						/>

						{isInverted && <div className={clsx('h-[5px] w-full')} />}
					</div>
				</div>
			</SVTransition>
		</div>
	);
};

const _OnNavigate = ({ onNavigate }: { onNavigate?: () => void | null | boolean }) => {
	const router = useRouter();
	const previousAsPath = usePrevious(router.asPath);

	useEffect(() => {
		if (previousAsPath !== router.asPath) {
			onNavigate && onNavigate();
		}
	}, [onNavigate, router.asPath, previousAsPath]);

	return null;
};

const defaultProps: {
	triggerType: ValueOf<typeof TRIGGER_TYPES>;
	keepOpenOnClick?: boolean;
} = {
	triggerType: TRIGGER_TYPES.HOVER,
	keepOpenOnClick: false,
};

type Props = (
	| {
			triggerType: 'none';
			isOpen?: boolean; // required on triggerType = 'none'
	  }
	| {
			triggerType?: 'hover' | 'click' | 'longpress';
	  }
) & {
	positionStrategy?: ValueOf<typeof POSITION_STRATEGIES>;
	safetyMargin?: ValueOf<typeof SAFETY_MARGINS>;
	onClose?: () => void;
	onOpen?: () => void;
	onMouseOver?: React.MouseEventHandler;
	renderTrigger: (props: {
		ref: (ref: HTMLElement | null) => void;
		isOpen: boolean;
	}) => JSX.Element;
	renderContent: (props: EmptyObject<any>) => JSX.Element | null;
	maxHeight?: number;
	className?: string;
	hideOutlineStroke?: boolean;
	keepOpenOnClick?: boolean;
	matchTriggerWidth?: boolean;
};

type State = {
	isOpen: boolean;
};

// On extension, the shadow dom makes it complicated to listen to the document and
// check if the clicked element was part of the dropdown or not.
// So we need to get the root node of the element and use that to listen to events.

function getRootDocument(element?: HTMLElement): Document {
	if (element) {
		return element.getRootNode() as Document;
	}

	return document;
}

class SVDropdown extends React.Component<Props, State> {
	static _Dropdown = _PositionAwareDropdown; // only for tests

	static TRIGGER_TYPES = TRIGGER_TYPES;
	static POSITION_STRATEGIES = POSITION_STRATEGIES;
	static SAFETY_MARGINS = SAFETY_MARGINS;

	static defaultProps = defaultProps;

	wrapperRef = React.createRef<HTMLElement>() as React.MutableRefObject<HTMLElement>;
	triggerRef = React.createRef<HTMLElement>() as React.MutableRefObject<HTMLElement>;
	state = {
		isOpen: false,
	};
	mounted = false;
	mouseEventTimeout: ReturnType<typeof setTimeout> | null = null;

	constructor(props: Props) {
		super(props);

		if (props.triggerType === TRIGGER_TYPES.NONE) {
			this.state = {
				isOpen: props.isOpen || false,
			};
		}
	}

	componentDidMount() {
		this.mounted = true;

		const triggerElement = this.triggerRef.current;

		if (triggerElement) {
			triggerElement.addEventListener('click', this.handleBoxClick, true);
		}

		const doc = getRootDocument(triggerElement);

		doc.addEventListener('mousedown', this.handleDocumentMouseDown, true);
		doc.addEventListener(
			'touchstart',
			this.handleDocumentMouseDown,
			supportsPassive()
				? {
						passive: true,
						capture: true,
				  }
				: true,
		);

		this.checkLongPressListener();
	}

	componentWillUnmount() {
		this.mounted = false;

		const triggerElement = this.triggerRef.current;

		if (triggerElement) {
			removeLongPressListener(triggerElement);

			triggerElement.removeEventListener('click', this.handleBoxClick);
		}

		const doc = getRootDocument(triggerElement);

		doc.removeEventListener('mousedown', this.handleDocumentMouseDown);
		doc.removeEventListener('touchstart', this.handleDocumentMouseDown);
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.checkLongPressListener(nextProps);

		if (
			this.props.triggerType === 'none' &&
			nextProps.triggerType === 'none' &&
			nextProps.isOpen !== this.props.isOpen &&
			this.state.isOpen !== nextProps.isOpen
		) {
			this.toggle(nextProps.isOpen || false);
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (!prevState.isOpen && this.state.isOpen && this.props.onOpen) {
			this.props.onOpen();
		}
	}

	// shouldComponentUpdate (nextProps, nextState) {
	// 	return shallowEqual(this.props, nextProps, this.state, nextState);
	// }

	checkLongPressListener(props: Props | null = null) {
		const add = !props || this.props.triggerType !== props.triggerType;
		props = props || this.props;

		const triggerType = this.getTriggerType(props);
		const triggerElement = this.triggerRef.current;

		if (triggerElement) {
			if (add && triggerType === TRIGGER_TYPES.LONGPRESS) {
				addLongPressListener(triggerElement, this.handleLongPress);
			} else if (triggerType !== TRIGGER_TYPES.LONGPRESS) {
				removeLongPressListener(triggerElement);
			}
		}
	}

	handleLongPress = () => {
		this.toggle(true);
	};

	getTriggerType = (props: Props | null = null): ValueOf<typeof TRIGGER_TYPES> => {
		props = props || this.props;

		if (isTouch()) {
			return props.triggerType === TRIGGER_TYPES.LONGPRESS
				? TRIGGER_TYPES.NONE
				: TRIGGER_TYPES.CLICK;
		}

		return props.triggerType || TRIGGER_TYPES.HOVER;
	};

	handleBoxMouseOver = (event: React.MouseEvent) => {
		const triggerType = this.getTriggerType();

		this.props.onMouseOver && this.props.onMouseOver(event);

		if (triggerType !== TRIGGER_TYPES.HOVER && triggerType !== TRIGGER_TYPES.LONGPRESS) {
			return;
		}

		if (this.mouseEventTimeout) {
			clearTimeout(this.mouseEventTimeout);
		}

		if (triggerType === TRIGGER_TYPES.LONGPRESS) {
			return;
		}

		this.mouseEventTimeout = setTimeout(() => {
			if (this.mounted) {
				this.toggle(true);
			}
		}, 100);
	};

	handleBoxMouseOut = () => {
		const triggerType = this.getTriggerType();

		if (triggerType !== TRIGGER_TYPES.HOVER) {
			return;
		}

		if (this.mouseEventTimeout) {
			clearTimeout(this.mouseEventTimeout);
		}

		this.mouseEventTimeout = setTimeout(() => {
			if (this.mounted) {
				this.toggle(false);
			}
		}, 100);
	};

	handleBoxClick = (event: MouseEvent) => {
		if (this.getTriggerType() !== TRIGGER_TYPES.CLICK) {
			return;
		}

		this.setState((prevState) => ({
			isOpen: !prevState.isOpen,
		}));

		event.stopPropagation();
		try {
			event.preventDefault();
		} catch (e) {} // eslint-disable-line no-empty
		return false;
	};

	close = (event: React.MouseEvent | null = null) => {
		if (event) {
			try {
				event.preventDefault();
			} catch (e) {} // eslint-disable-line no-empty
			event.stopPropagation();
		}

		this.toggle(false);

		return false;
	};

	handleDocumentMouseDown = (event: MouseEvent | TouchEvent) => {
		const element = this.wrapperRef.current;

		// if (this.getTriggerType() === TRIGGER_TYPES.NONE) {
		// 	return;
		// }

		if (
			this.state.isOpen &&
			element &&
			element != event.target &&
			!element.contains(event.target as HTMLElement)
		) {
			this.close();

			if (this.getTriggerType() === TRIGGER_TYPES.NONE) {
				this.props.onClose && this.props.onClose();
			}

			return false;
		}
	};

	handleTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
		if (this.getTriggerType() === TRIGGER_TYPES.NONE) {
			return;
		}

		const element = this.wrapperRef.current;

		if (!event.target || !element?.contains(event.target as HTMLElement)) {
			return;
		}

		// Auto close when selects an option inside dropdown
		if (matchesClosest(event.target, 'a,button')) {
			// Only close if no modifier key is pressed, to preserve open in new tab with
			// CMD + click.
			if (!this.props.keepOpenOnClick && !isModifiedEvent(event)) {
				this.toggle(false);
			}

			return;
		}

		event.stopPropagation();
		try {
			event.preventDefault();
		} catch (e) {} // eslint-disable-line no-empty
		return false;
	};

	handleCloseAll = () => {
		this.close();
	};

	toggle = (open: boolean) => {
		if ((open && !this.state.isOpen) || (!open && this.state.isOpen)) {
			this.setState({
				isOpen: open,
			});
		}
	};

	render() {
		const { isOpen } = this.state;

		const triggerType = this.getTriggerType(this.props);

		return (
			<>
				<_OnNavigate onNavigate={isOpen ? this.close : undefined} />
				<_PositionAwareDropdown
					className={this.props.className}
					onMouseOver={this.handleBoxMouseOver}
					onMouseOut={this.handleBoxMouseOut}
					onClick={this.handleTriggerClick}
					wrapperRef={this.wrapperRef}
					triggerRef={this.triggerRef}
					isOpen={isOpen}
					onClose={
						triggerType === TRIGGER_TYPES.NONE
							? this.props.onClose
							: triggerType !== TRIGGER_TYPES.HOVER
							? this.close
							: undefined
					}
					renderTrigger={this.props.renderTrigger}
					renderContent={this.props.renderContent}
					positionStrategy={this.props.positionStrategy}
					maxHeight={this.props.maxHeight}
					hideOutlineStroke={this.props.hideOutlineStroke}
					safetyMargin={this.props.safetyMargin}
					matchTriggerWidth={this.props.matchTriggerWidth}
				/>
			</>
		);
	}
}

export default SVDropdown;
