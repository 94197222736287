import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type RemoveUserFromTeamMutationMutation,
	type RemoveUserFromTeamMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import InviteFragment from './fragments/InviteFragment';
import TeamUserFragment from './fragments/TeamUserFragment';

const RemoveUserFromTeamMutation: TypedDocumentNode<
	RemoveUserFromTeamMutationMutation,
	RemoveUserFromTeamMutationMutationVariables
> = gql`
	${TeamUserFragment}
	${InviteFragment}

	mutation RemoveUserFromTeamMutation($input: RemoveUserFromTeamInput!) {
		removeUserFromTeam(input: $input) {
			_id
			usersCount
			users {
				...TeamUserFragment
			}
			invites {
				...InviteFragment
			}
		}
	}
`;

export default RemoveUserFromTeamMutation;
