import {
	type UserFragmentFragment,
	type UserQueryQuery,
} from '@apps/www/src/__generated__/graphql';
import SVFollowButtonContainer from '@apps/www/src/www/containers/SVFollowButtonContainer';
import SVA from '@pkgs/shared-client/components/SVA';
import SVAvatar from '@pkgs/shared-client/components/SVAvatar';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import SVProBadge from '@pkgs/shared-client/components/SVProBadge';
import { preventDefault } from '@pkgs/shared-client/helpers/dom';
import { fixURL, prettyURL } from '@pkgs/shared-client/helpers/format';
import IconMenuSVG from '@pkgs/shared-client/img/icon-menu-three-lines-inlined.svg';
import InstagramIconSVG from '@pkgs/shared-client/img/instagram-inlined.svg';
import TwitterIconSVG from '@pkgs/shared-client/img/twitter-inlined.svg';
import normalizeSocialProfileLink from '@pkgs/shared/helpers/normalizeSocialProfileLink';
import React from 'react';

const _BadgeNotifications = React.memo(() => (
	<span className="theme-dark flex-center type-label bg-brand text-primary duration-over absolute right-0 top-0 h-3 w-3 rounded-full transition ease-out group-hover:brightness-75"></span>
));

const _SmallAvatarItem = React.memo(
	({
		onOpenNav,
		unreadNotificationsCount,
	}: Pick<Props, 'onOpenNav' | 'unreadNotificationsCount'>) => {
		return (
			<SVLink
				className="text-primary relative z-10 flex h-10 w-10 items-center justify-center rounded-full align-middle"
				to={'#'}
				title="Open menu"
				onClick={preventDefault(onOpenNav)}
			>
				<IconMenuSVG />
				{unreadNotificationsCount > 0 ? <_BadgeNotifications /> : ''}
			</SVLink>
		);
	},
);

const _Header = React.memo(
	({
		asOwner,
		user,
		onOpenNav,
		unreadNotificationsCount,
	}: {
		asOwner: boolean;
		user: UserFragmentFragment;
		onOpenNav: (event: React.UIEvent) => void;
		unreadNotificationsCount: number;
	}) => {
		const info = [
			`@${user.username}`,
			user.role,
			user.company && (
				<SVA Component={SVLink} to={user.company.url}>
					{user.company.name}
				</SVA>
			),
		].filter(Boolean);

		const links = [
			user.website && (
				<SVA href={fixURL(user.website)} target="_blank" rel="noopener noreferrer">
					{prettyURL(user.website)}
				</SVA>
			),
			user.instagram && (
				<span className="inline-flex">
					<SVIconButton
						Component="a"
						href={normalizeSocialProfileLink(user.instagram, 'instagram')}
						src={InstagramIconSVG}
						target="_blank"
						rel="noopener noreferrer"
						title={`@${user.instagram}`}
						iconClassName="w-4 h-4 min-w-[16px] min-h-[16px]"
					/>
				</span>
			),
			user.twitter && (
				<span className="inline-flex">
					<SVIconButton
						Component="a"
						href={normalizeSocialProfileLink(user.twitter, 'twitter')}
						src={TwitterIconSVG}
						target="_blank"
						rel="noopener noreferrer"
						title={`@${user.twitter}`}
						iconClassName="w-4 h-4 min-w-[16px] min-h-[16px]"
					/>
				</span>
			),
		].filter(Boolean);

		const itemSeparator = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

		return (
			<div className="-sm:flex-col flex flex-row justify-between">
				{/* padding bottom is 53px to match the `SVBoardHeader` height and avoid jumping when navigating */}
				<div className="-lg:ml-0 -sm:pb-0 relative z-10 mt-[26px] flex flex-col items-start space-y-2 pb-[53px]">
					<div className="-sm:justify-center -sm:items-center -sm:flex-col flex w-full flex-row items-start justify-start">
						<div className="-sm:justify-center relative flex flex-row items-center">
							<div className="absolute mr-[80vw] sm:hidden">
								<_SmallAvatarItem
									onOpenNav={onOpenNav}
									unreadNotificationsCount={unreadNotificationsCount}
								/>
							</div>
							<SVAvatar
								className="z-index-user-header-avatar border-background -mdp:w-24 relative w-[110px] rounded-full"
								src={user.avatarURL}
							/>
						</div>
						<div className="-sm:ml-2 -sm:items-center -sm:mt-2 ml-6 flex flex-col items-start space-y-2">
							<SVPageTitle className="relative mb-0 flex text-4xl font-normal">
								<div className="flex min-w-0 flex-row items-center">
									<span className="flex truncate break-words text-gray-50">
										{user.name}
									</span>
									{user.isPro && (
										<SVProBadge
											className="-sm:top-0 top-[-2px]"
											size={SVProBadge.SIZES.LARGE}
										/>
									)}
								</div>
							</SVPageTitle>
							<div className="flex flex-wrap justify-center">
								{info.map((item, index) => (
									<React.Fragment key={index}>
										{item}
										{index !== info.length - 1 && itemSeparator}
									</React.Fragment>
								))}
							</div>
							<div className="flex items-center">
								{links.map((item, index) => (
									<React.Fragment key={index}>
										{item}
										{index !== links.length - 1 && itemSeparator}
									</React.Fragment>
								))}
							</div>
						</div>
					</div>
				</div>
				{asOwner && (
					<div className="-sm:justify-center -sm:flex -sm:pb-10 space-x-3 pt-4">
						<SVButton Component={SVLink} to="/profile/" size={SVButtonSIZES.SMALL}>
							Edit Profile
						</SVButton>
						<SVButton
							Component={SVLink}
							to="/settings/"
							size={SVButtonSIZES.SMALL}
							use={SVButtonUSES.OUTLINE}
						>
							Settings
						</SVButton>
					</div>
				)}
				{user.canFollow && (
					<div className="-sm:justify-center -sm:flex -sm:pb-10 space-x-3 pt-4">
						<SVFollowButtonContainer
							userID={user._id}
							canFollow={user.canFollow}
							isFollowing={user.isFollowing}
							isFollowingBack={user.isFollowingBack}
						/>
					</div>
				)}
			</div>
		);
	},
);

type Props = {
	asOwner: boolean;
	user: UserQueryQuery['userByUsername'];
	onOpenNav: (event: React.UIEvent) => void;
	unreadNotificationsCount: number;
};

const SVUserHeader = ({ asOwner, user, onOpenNav, unreadNotificationsCount }: Props) => {
	return (
		<SVPageMargins>
			<_Header
				user={user}
				asOwner={asOwner}
				onOpenNav={onOpenNav}
				unreadNotificationsCount={unreadNotificationsCount}
			/>
		</SVPageMargins>
	);
};

export default SVUserHeader;
