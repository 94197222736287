import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type RegenerateBoardPublicUrlMutationMutation,
	type RegenerateBoardPublicUrlMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const RegenerateBoardPublicURLMutation: TypedDocumentNode<
	RegenerateBoardPublicUrlMutationMutation,
	RegenerateBoardPublicUrlMutationMutationVariables
> = gql`
	mutation RegenerateBoardPublicURLMutation($input: RegenerateBoardPublicURLInput!) {
		regenerateBoardPublicURL(input: $input) {
			_id
			publicURL
		}
	}
`;

export default RegenerateBoardPublicURLMutation;
