import { useApolloClient, useMutation } from '@apollo/client';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import useAllowedToHideAssets from '@apps/www/src/www/hooks/useAllowedToHideAssets';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import hideAssetMutation from '@apps/www/src/www/queries/HideAssetMutation';
import { LikeItem } from '@pkgs/shared-client/components/SVGrid';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';

const useHideAsset = (items: LikeItem[]) => {
	const isLoggedIn = useIsLoggedIn();
	const client = useApolloClient();
	const [hideAsset] = useMutation(hideAssetMutation);
	const isAllowedToHideAssets = useAllowedToHideAssets();

	const handleHideAsset = useAllowedFeatureCheckEvent(
		AllowedFeature.HIDE_ASSETS,
		async () => {
			if (!items.length) {
				return;
			}

			await hideAsset({
				variables: {
					input: {
						assetIDs: items.map((item) => item.asset._id),
					},
				},
			});

			// Evict the hidden assets from the cache
			items.forEach((hiddenItem) => {
				client.cache.evict({
					id: client.cache.identify({ __typename: 'Item', _id: hiddenItem._id }),
				});
			});

			client.cache.gc();
		},
	);

	if (!isLoggedIn || !isAllowedToHideAssets) {
		return null;
	}

	return handleHideAsset;
};

export default useHideAsset;
