/**
 * List of sources for the upgrade button, for tracking.
 */

const UpgradeButtonSource = {
	NAV_BAR: 'nav_bar',
	NAV_MENU: 'nav_menu',
	REACHED_LIMIT_MODAL: 'reached_limit_modal',
	UPGRADE_REQUIRED_ITEM_LIMIT_MODAL: 'upgrade_required_modal_item_limit',
	UPGRADE_REQUIRED_BOARD_LIMIT_MODAL: 'upgrade_required_modal_board_limit',
	UPGRADE_REQUIRED_PRINT_MODAL: 'upgrade_required_modal_print',
	UPGRADE_REQUIRED_PRIVATE_MODAL: 'upgrade_required_modal_private',
	UPGRADE_REQUIRED_SHARED_MODAL: 'upgrade_required_modal_shared',
	UPGRADE_REQUIRED_MANAGE_TEAM_MODAL: 'upgrade_required_modal_manage_team',
	UPGRADE_REQUIRED_CREATE_SITE_MODAL: 'upgrade_required_modal_create_site',
	UPGRADE_REQUIRED_RIGHT_CLICK_MODAL: 'upgrade_required_modal_right_click',
	UPGRADE_REQUIRED_HIDE_ASSETS_MODAL: 'upgrade_required_modal_hide_assets',
	LOW_QUALITY_UPSELL: 'low_quality_upsell',
	END_OF_GRID_UPSELL: 'end_of_grid_upsell',
	DROPBOX_UPSELL: 'dropbox_upsell',
	AUTOPLAY_GIF_UPSELL: 'autoplay_gif_upsell',
	BLOCKED_FEED: 'blocked_feed',
	BLOCKED_SEARCH: 'blocked_search',
	BILLING_PAGE: 'billing_page',
} as const;

export default UpgradeButtonSource;
