import { gql, type TypedDocumentNode } from '@apollo/client';
import {
	type HideAssetMutationMutation,
	type HideAssetMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';

const HideAssetMutation: TypedDocumentNode<
	HideAssetMutationMutation,
	HideAssetMutationMutationVariables
> = gql`
	mutation HideAssetMutation($input: HideAssetInput!) {
		hideAsset(input: $input) {
			assets {
				_id
			}
		}
	}
`;

export default HideAssetMutation;
