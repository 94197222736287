import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import useAuthUserID from '@apps/www/src/www/hooks/useAuthUserID';
import usePaymentButtonInfo from '@apps/www/src/www/hooks/usePaymentButtonInfo';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import config from '@pkgs/shared-client/config';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconPaypalSVG from '@pkgs/shared-client/img/icon-paypal.svg';
import PaymentButtonType from '@pkgs/shared/enums/PaymentButtonType';
import formatURL from '@pkgs/shared/helpers/formatURL';
import { twMerge } from 'tailwind-merge';

type Props = {
	className?: string;
	isOnOverlay?: boolean;
} & Parameters<typeof usePaymentButtonInfo>[0];

export default function SVPaypalPaymentButtonContainer({
	className,
	isOnOverlay = false,
	...otherProps
}: Props) {
	const authUserID = useAuthUserID();
	const authUser = useAuthUser(['name']);
	const authSettings = useAuthSettings(['email']);

	const { buttonType, plan, hasActivePlan } = usePaymentButtonInfo(otherProps);

	// TODO: (paypal) when type is RENEW or EDIT_PAYMENT_METHOD, don't re-create subscription, try to resurrect/edit current one.

	const handleCreatePaypalSubscription = useEventCallback((async (data, actions) => {
		if (!plan?.paypalPlanID) {
			return;
		}

		try {
			const subscriptionID = await actions.subscription.create({
				custom_id: authUserID,
				plan_id: plan.paypalPlanID,
				subscriber: {
					name: {
						given_name: authUser?.name,
					},
					email_address: authSettings?.email,
				},
				application_context: {
					brand_name: 'Savee',
					shipping_preference: 'NO_SHIPPING',
					// "user_action": "SUBSCRIBE_NOW",
					payment_method: {
						// "payer_selected": "PAYPAL_CREDIT",
						payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED',
					},
					// "return_url": formatURL(config.baseURL, 'billing/'),
					cancel_url: formatURL(config.baseURL, 'billing/'),
				},
			});

			// console.log('subscription created', subscriptionID);

			return subscriptionID;
		} catch (e) {
			const error = e instanceof Error ? e : new Error('Unknown error');
			alert(error.message);
		}
	}) as React.ComponentProps<typeof PayPalButtons>['createSubscription']);

	const handlePaypalApprove = useEventCallback(async (_data: any, _actions: any) => {
		// console.log('on approve', data);

		if (buttonType === PaymentButtonType.SUBSCRIBE) {
			window.location.href = formatURL(config.baseURL, `upgrade/success/`);
		} else {
			window.location.href = formatURL(config.baseURL, `billing/`);
		}
	});

	// PayPal not supported for this plan
	if (!plan?.paypalPlanID) {
		return null;
	}

	// TODO: (paypal) No support to change plan for paypal, for now
	if (buttonType === PaymentButtonType.SUBSCRIBE && hasActivePlan) {
		return null;
	}

	// TODO: (paypal) Do EDIT_PAYMENT_METHOD for paypal
	if (buttonType !== PaymentButtonType.SUBSCRIBE) {
		return null;
	}

	return (
		<div className={twMerge('leading-[0px]', isOnOverlay && 'relative', className)}>
			<PayPalScriptProvider
				options={{
					'client-id': config.paypal.key,
					components: 'buttons',
					intent: 'subscription',
					vault: true,
				}}
			>
				<PayPalButtons
					className={isOnOverlay ? 'opacity-0' : ''}
					fundingSource="paypal"
					createSubscription={handleCreatePaypalSubscription}
					onApprove={handlePaypalApprove}
					style={{
						label: buttonType === PaymentButtonType.SUBSCRIBE ? 'subscribe' : 'paypal',
						shape: 'pill',
						color: 'black',
						height: 50,
					}}
				/>
			</PayPalScriptProvider>
			{isOnOverlay && (
				<SVButton
					className="flex-center pointer-events-none absolute left-0 top-0 z-[1000000] h-[40px] w-full font-normal"
					use={SVButtonUSES.OUTLINE}
				>
					<img src={IconPaypalSVG} alt="PayPal Icon" className="w-5 h-5 mr-2" />
					<span>Pay with PayPal</span>
				</SVButton>
			)}
		</div>
	);
}
