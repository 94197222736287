import { useApolloClient } from '@apollo/client';
import {
	type Item,
	type UpdateItemMutationMutationVariables,
} from '@apps/www/src/__generated__/graphql';
import UpdateItemMutation from '@apps/www/src/www/queries/UpdateItemMutation';
import SVForm from '@pkgs/shared-client/components/SVForm';
import { SVInputText, SVInputTextarea } from '@pkgs/shared-client/components/SVFormInputs';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';

const SVEditItemModal = SVModal.create(
	({
		_id,
		name,
		pageURL,
	}: {
		_id: Item['_id'];
		name: Item['name'];
		pageURL: Item['pageURL'];
	}) => {
		const client = useApolloClient();
		const close = useModalClose();

		const handleSubmit = useEventCallback(
			async (values: Omit<UpdateItemMutationMutationVariables['input'], 'itemID'>) => {
				await client.mutate({
					mutation: UpdateItemMutation,
					variables: { input: { itemID: _id, ...values } },
				});

				close();
			},
		);

		return (
			<>
				<SVModal.Title>Edit asset</SVModal.Title>
				<SVForm
					onSubmit={handleSubmit}
					submitLabel="Save"
					initialValues={{
						name: name || '',
						pageURL: pageURL || '',
					}}
					submitAlignment="right"
				>
					<SVInputTextarea name="name" label="Description" required={true} />
					<SVInputText name="pageURL" label="Source URL" />
				</SVForm>
			</>
		);
	},
);

export default SVEditItemModal;
