import { type Board } from '@apps/www/src/__generated__/graphql';
import useAuthBoards from '@apps/www/src/www/hooks/useAuthBoards';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVModalUsersList from '@pkgs/shared-client/components/SVModalUsersList';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import InviteType from '@pkgs/shared/enums/InviteType';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import SVInviteUsersModal from './SVInviteUsersModal';
import SVRemoveUserFromTeamOrBoardModal from './SVRemoveUserFromTeamOrBoardModal';

const _SVEditBoardCollaboratorsModal = ({ _id }: { _id: Board['_id'] }) => {
	const authUser = useAuthUser(['_id', 'username']);
	const authSettings = useAuthSettings(['maxBoardCollaborators']);
	const board = useAuthBoards()?.find((board) => board._id === _id);

	const hasAdminPrivileges = boardUserRoleHasBoardUserRolePrivileges(
		(board?.role || BoardUserRole.VIEWER),
		BoardUserRole.ADMIN,
	);

	const collaboratorsCount = (board?.collaborators.length || 0) + (board?.invites.length || 0);
	const maxUsersCount = (authSettings?.maxBoardCollaborators || 0) - collaboratorsCount;
	const showInviteButton = hasAdminPrivileges && maxUsersCount > 0;

	const handleInviteClick = useEventCallback(() => {
		SVModal.open(SVInviteUsersModal, {
			type: InviteType.BOARD,
			resourceID: _id,
			maxUsersCount,
		});
	});

	const handleRemoveUser = useEventCallback(
		(
			item: Parameters<
				NonNullable<React.ComponentProps<typeof SVModalUsersList.Item>['onRemove']>
			>[0],
		) => {
			const userID = 'user' in item && item.user ? item.user._id : undefined;
			const userName = 'user' in item && item.user ? item.user.name : undefined;
			const email = 'email' in item && item.email ? item.email : undefined;

			SVModal.open(SVRemoveUserFromTeamOrBoardModal, {
				type: InviteType.BOARD,
				_id: _id,
				userID,
				email,
				name: userName || email,
			});
		},
	);

	if (!authUser) {
		return null;
	}

	return (
		<>
			<SVModal.Title>Board Collaborators</SVModal.Title>
			<SVModalUsersList>
				{board?.collaborators.map((item) => (
					<SVModalUsersList.Item
						key={item._id}
						item={item}
						authUserID={authUser._id}
						leaveURL={`/api/boards/${board?._id}/leave/`}
						onRemove={hasAdminPrivileges ? handleRemoveUser : undefined}
						boardID={board?._id}
					/>
				))}
				{board?.invites.map((invite) => (
					<SVModalUsersList.Item
						key={invite._id}
						item={invite}
						isInvitation={true}
						authUserID={authUser._id}
						acceptURL={board?.acceptURL}
						rejectURL={board?.rejectURL}
						onRemove={hasAdminPrivileges ? handleRemoveUser : undefined}
					/>
				))}
			</SVModalUsersList>
			{showInviteButton && (
				<div className="text-end w-full">
					<SVButton
						use={SVButtonUSES.PRIMARY}
						// size={SVButtonSIZES.WIDE}
						onClick={handleInviteClick}
					>
						Invite Others
					</SVButton>
				</div>
			)}
		</>
	);
};

const SVEditBoardCollaboratorsModal = SVModal.create(_SVEditBoardCollaboratorsModal, {
	className: 'w-[380px] -sm:w-full',
});

export default SVEditBoardCollaboratorsModal;
