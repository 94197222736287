import { SVColorPicker } from '@pkgs/shared-client/components/SVColorPicker';
import IconLoadingSVG from '@pkgs/shared-client/img/icon-loading-inlined.svg';
import clsx from 'clsx';
import { useField, type FieldInputProps } from 'formik';
import React, { LegacyRef, forwardRef, type InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import SVCheck from './SVCheck';
import SVFlexSpacer from './SVFlexSpacer';
import SVFormLayout from './SVFormLayout';
import SVMessage from './SVMessage';
import SVToggle from './SVToggle';

const _Label = ({ children }: OnlyChildrenProps) => (
	<span className="type-label mb-2 block text-gray-400">{children}</span>
);

const _Wrapper = ({
	className,
	error,
	children,
}: React.PropsWithChildren<{ className?: string; error?: Error | string | null }>) => (
	<SVFormLayout.Block className={twMerge('text-left', className)}>
		{children}
		{error && (
			<SVMessage use={SVMessage.USES.ERROR} className="pb-0 pt-2">
				{typeof error === 'object' ? error.message : error}
			</SVMessage>
		)}
	</SVFormLayout.Block>
);

const INPUT_TEXT_USES = {
	DEFAULT: 'default',
	LOGIN: 'login',
	TRANSPARENT: 'transparent',
};

const inputTextDefaultProps: {
	type: string;
	use: ValueOf<typeof INPUT_TEXT_USES>;
} = {
	type: 'text',
	use: INPUT_TEXT_USES.DEFAULT,
};

type InputTextPropsBase = Partial<typeof inputTextDefaultProps> & {
	label?: string;
	name: string;
	placeholder?: string;
	onFocus?: React.FocusEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	required?: boolean;
	disabled?: boolean;
	autoCorrect?: InputHTMLAttributes<HTMLInputElement>['autoCorrect'];
	autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete'];
	autoCapitalize?: InputHTMLAttributes<HTMLInputElement>['autoCapitalize'];
	maxLength?: number;
	className?: string;
};

type InputTextProps =
	| (InputTextPropsBase & { onChange?: never; value?: never })
	| (InputTextPropsBase & {
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
			value: string;
	  });

const _SVInputTextInner = forwardRef(
	(
		{
			error,
			label,
			use,
			placeholder,
			className,
			...props
		}: Partial<FieldInputProps<string>> &
			Partial<JSX.IntrinsicElements['input']> &
			InputTextProps & {
				error: string | undefined;
			},
		ref,
	) => (
		<_Wrapper error={error}>
			{use === INPUT_TEXT_USES.DEFAULT && label && <_Label>{label}</_Label>}
			<input
				ref={ref as LegacyRef<HTMLInputElement>}
				className={clsx(
					'type-base bg-background text-secondary w-full border-0 placeholder-gray-700 focus:outline-none',
					use === INPUT_TEXT_USES.LOGIN
						? 'input-text-default type-base rounded-2xl bg-gray-800 px-4 py-4 font-normal ring-1 ring-gray-800 placeholder:text-gray-100 autofill:bg-gray-800 focus:ring-2 focus:ring-white'
						: use === INPUT_TEXT_USES.TRANSPARENT
						? 'input-text-transparent bg-transparent text-gray-100 ring-0 placeholder:text-gray-500 focus:outline-none focus:ring-0'
						: 'input-text-default rounded-lg px-4 py-3 ring-1 ring-gray-600 focus:ring-2 focus:ring-gray-600',
					className,
				)}
				title={label}
				placeholder={use === INPUT_TEXT_USES.LOGIN ? label || placeholder : placeholder}
				{...props}
			/>
		</_Wrapper>
	),
);

export const SVInputText = forwardRef((props: InputTextProps, ref) => {
	const [field, meta] = useField<string>(props);

	return (
		<_SVInputTextInner
			{...field}
			{...props}
			className={props.className}
			ref={ref}
			error={meta.touched ? meta.error : undefined}
		/>
	);
});

SVInputText.defaultProps = inputTextDefaultProps;

export const SVInputTextUSES = INPUT_TEXT_USES;

export const SVInputTextRaw = _SVInputTextInner;

export const SVInputPassword = (props: InputTextProps) => (
	<SVInputText {...props} type="password" />
);

type InputCheckboxProps = {
	name: string;
	label: string;
	sublabel?: string;
	isDisabled?: boolean;
};

const _SVInputCheckboxInner = ({
	error,
	label,
	sublabel,
	name,
	onChange,
	value,
	isDisabled,
}: FieldInputProps<boolean> &
	InputCheckboxProps & {
		error: string | undefined;
	}) => (
	<_Wrapper className="flex min-h-[48px] flex-col justify-center" error={error}>
		<input type="hidden" name={name} value={value ? String(value) : undefined} />
		<div
			className={clsx(
				'group flex cursor-pointer items-center space-x-2',
				isDisabled ? 'pointer-events-none opacity-60' : null,
			)}
			onClick={() => {
				const newValue = !value;

				onChange({
					target: {
						name,
						value: newValue,
					},
				});
			}}
		>
			<SVCheck isSelected={value} />

			<div>
				<div className={clsx(!value && 'text-muted decoration-muted line-through')}>
					{label}
				</div>
				{sublabel && <div className="type-label text-muted">{sublabel}</div>}
			</div>
		</div>
	</_Wrapper>
);

export const SVInputCheckbox = (props: InputCheckboxProps) => {
	const [field, meta] = useField<boolean>(props);

	return (
		<_SVInputCheckboxInner
			{...field}
			{...props}
			error={meta.touched ? meta.error : undefined}
		/>
	);
};

type InputRadioProps<T extends string = string> = {
	name: string;
	label?: string;
	renderItem: (value: T, selected: boolean) => JSX.Element | null;
	values: T[];
};

const _SVInputRadioInner = <T extends string = string>({
	error,
	label,
	setValue,
	value,
	renderItem,
	values,
	name,
}: FieldInputProps<string> &
	InputRadioProps<T> & {
		setValue: (value: string) => void;
		error?: string;
	}) => (
	<_Wrapper error={error}>
		{label && <_Label>{label}</_Label>}
		<div className="-sm:gap-4 flex flex-col gap-6">
			{values.map((itemValue) => (
				<React.Fragment key={String(itemValue)}>
					<input
						className="hidden"
						type="radio"
						name={name}
						value={itemValue}
						checked={itemValue === value}
						readOnly={true}
					/>
					<div
						className={clsx(
							'group',
							itemValue !== value ? 'cursor-pointer' : 'disabled',
						)}
						key={itemValue}
						onClick={() => setValue(itemValue)}
					>
						{renderItem(itemValue, itemValue === value)}
					</div>
				</React.Fragment>
			))}
		</div>
	</_Wrapper>
);

export const SVInputRadio = <T extends string = string>(props: InputRadioProps<T>) => {
	const [field, meta, { setValue }] = useField<string>(props);

	return (
		<_SVInputRadioInner
			{...field}
			{...props}
			error={meta.touched ? meta.error : undefined}
			setValue={setValue}
		/>
	);
};

type StandaloneInputToggleType = {
	label: JSX.Element | string;
	sublabel?: string;
	isPressed: boolean;
	isLoading?: boolean;
	toggle?: JSX.Element;
	error?: Error;
	onClick: React.MouseEventHandler;
};

export const SVStandaloneInputToggle = ({
	label,
	sublabel,
	isPressed,
	isLoading,
	toggle,
	error,
	onClick,
}: StandaloneInputToggleType) => (
	<_Wrapper className="flex min-h-[48px] flex-col justify-center" error={error}>
		<div className="flex items-start justify-between space-x-2">
			<div>
				<div className="text-xl font-semibold text-gray-200">{label}</div>
				{sublabel && <div className="text-sm text-gray-500">{sublabel}</div>}
			</div>
			<SVFlexSpacer />
			{isLoading && <IconLoadingSVG className="scale-75" role="progressbar" />}
			{toggle || <SVToggle isPressed={isPressed} isDisabled={isLoading} onClick={onClick} />}
		</div>
	</_Wrapper>
);

type InputStaticProps = {
	label?: string;
	value: JSX.Element | string;
	className?: string;
};

export const SVInputStatic = ({ label, value, className }: InputStaticProps) => (
	<_Wrapper>
		{label && <_Label>{label}</_Label>}
		<div
			className={twMerge(
				'text-secondary flex items-center justify-between space-x-4 leading-none',
				className,
			)}
		>
			{value}
		</div>
	</_Wrapper>
);

type InputColorPickProps = {
	label?: string;
	placeholder?: string;
	error?: Error;
	hideColorWheel?: boolean;
	className?: string;
	wrapperClassName?: string;
} & Pick<React.ComponentProps<typeof SVColorPicker>, 'value' | 'onFocus' | 'onChange' | 'style'>;

export const SVInputColorPick = ({
	label,
	value,
	error,
	onChange,
	onFocus,
	style = 'default',
	hideColorWheel = false,
	className,
	wrapperClassName,
}: InputColorPickProps) => (
	<_Wrapper error={error} className={wrapperClassName}>
		{label && <_Label>{label}</_Label>}
		<SVColorPicker
			className={clsx(
				'type-base bg-background text-secondary w-full border-0 placeholder-gray-700 focus:outline-none',
				className,
			)}
			wrapperClassName={wrapperClassName}
			value={value}
			onChange={onChange}
			onFocus={onFocus}
			style={style}
			hideColorWheel={hideColorWheel}
		/>
	</_Wrapper>
);

type InputSelectProps = {
	label?: string;
	name: string;
	required?: boolean;
	options: { label: string; value: string }[];
};

const _SVInputSelectInner = ({
	error,
	label,
	name,
	options,
	...props
}: FieldInputProps<string> &
	InputSelectProps & {
		error: string | undefined;
	}) => (
	<_Wrapper error={error}>
		{label && <_Label>{label}</_Label>}
		<select
			className={clsx(
				'type-base bg-background input-text-default w-full rounded-lg border-0 px-4 py-3 placeholder-gray-700 ring-1 ring-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-600',
				props.value === '' ? 'text-gray-500' : 'text-secondary',
			)}
			name={name}
			{...props}
		>
			<option value="">Select an option</option>
			{options.map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</select>
	</_Wrapper>
);

export const SVInputSelect = (props: InputSelectProps) => {
	const [field, meta] = useField<string>(props);

	return (
		<_SVInputSelectInner {...field} {...props} error={meta.touched ? meta.error : undefined} />
	);
};

type InputTextareaProps = {
	label?: string;
	name: string;
	placeholder?: string;
	required?: boolean;
	className?: string;
};

const _SVInputTextareaInner = ({
	error,
	label,
	className,
	...props
}: Partial<FieldInputProps<string>> &
	Partial<JSX.IntrinsicElements['textarea']> &
	InputTextareaProps & {
		error: string | undefined;
	}) => (
	<_Wrapper error={error}>
		{label && <_Label>{label}</_Label>}
		<textarea
			className={clsx(
				'type-base bg-background input-text-default w-full rounded-lg border-0 px-4 py-3 placeholder-gray-700 ring-1 ring-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-600',
				className,
			)}
			{...props}
		/>
	</_Wrapper>
);

export const SVInputTextarea = (props: InputTextareaProps) => {
	const [field, meta] = useField<string>(props);

	return (
		<_SVInputTextareaInner
			{...field}
			{...props}
			error={meta.touched ? meta.error : undefined}
		/>
	);
};
