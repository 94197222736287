/**
 * Formats currency into a human readable format, including currency symbol
 */

import currency from 'currency.js';

export default function formatCurrency(value: string | number) {
	const amount = currency(value);
	const cents = amount.intValue % 100;
	
	return currency(value, {
		precision: cents === 0 ? 0 : 2
	}).format();
}
