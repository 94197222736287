import { useApolloClient } from '@apollo/client';
import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVLoadingWrapper from '@apps/www/src/www/containers/SVLoadingWrapper';
import ReportItemsMutation from '@apps/www/src/www/queries/ReportItemsMutation';
import SVForm from '@pkgs/shared-client/components/SVForm';
import SVFormLayout from '@pkgs/shared-client/components/SVFormLayout';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import { getReportReasonLabel } from '@pkgs/shared-client/helpers/getReportReasonLabel';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import ReportReason from '@pkgs/shared/enums/ReportReason';
import { ChevronRight } from 'lucide-react';
import { useState } from 'react';

const SVReportItemsModal = SVModal.create(
	({ items, onSuccess }: { items: ItemFragmentFragment[]; onSuccess?: () => void }) => {
		const client = useApolloClient();
		const close = useModalClose();
		const [hasSubmitted, setHasSubmitted] = useState(false);
		const [isLoading, setIsLoading] = useState(false);

		const reportReasons = [
			ReportReason.UGLY,
			ReportReason.COPYRIGHT_INFRINGEMENT,
			ReportReason.NUDITY,
			ReportReason.OFFENSIVE,
		];

		const handleSubmit = useEventCallback(async (reason: ValueOf<typeof ReportReason>) => {
			if (!reason) {
				return;
			}
			setIsLoading(true);

			try {
				const { data } = await client.mutate({
					mutation: ReportItemsMutation,
					variables: {
						input: {
							itemIDs: items.map((item) => item._id),
							reason: reason,
						},
					},
				});

				if (data?.reportItems.reportedItemIDs) {
					setHasSubmitted(true);

					const reportedItemIDs = data.reportItems.reportedItemIDs;
					items
						.filter((item) => reportedItemIDs.includes(item._id))
						.forEach((item) => client.cache.evict({ id: client.cache.identify(item) }));
					client.cache.gc();
				}
			} catch (error) {
				console.error('Error reporting items:', error);
			} finally {
				setIsLoading(false);
			}
		});

		const handleSuccess = useEventCallback(async () => {
			await close();

			onSuccess && onSuccess();
		});

		return (
			<>
				<SVLoadingWrapper isLoading={isLoading} className="-sm:pl-14 -md:pl-16 pl-10">
					{() =>
						hasSubmitted ? (
							<div className="flex w-full flex-col items-center justify-center gap-5">
								<div className="-sm:w-[310px] -sm:gap-5 w-[250px] space-x-0">
									<SVModal.Title className="mb-8 text-center">
										Thanks for reporting this, we will take a look.
									</SVModal.Title>

									<SVForm
										onSubmit={handleSuccess}
										submitLabel="Done"
										submitAlignment="right"
										size={SVFormLayout.SIZES.LARGE}
									></SVForm>
								</div>
							</div>
						) : (
							<>
								<SVModal.Title className="pr-12">
									Why are you reporting this:
								</SVModal.Title>

								<div className="flex w-full flex-col">
									{reportReasons.map((reason) => (
										<button
											key={reason}
											className="flex items-center gap-16 rounded-lg p-2 text-left hover:bg-gray-800"
											onClick={async () => {
												await handleSubmit(reason);
											}}
										>
											<span className="flex-grow">
												{getReportReasonLabel(reason)}
											</span>
											<ChevronRight className="h-4 w-4 cursor-pointer" />
										</button>
									))}
								</div>
							</>
						)
					}
				</SVLoadingWrapper>
			</>
		);
	},
);

export default SVReportItemsModal;
