import SVUserHeader from '@apps/www/src/www/components/SVUserHeader';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import { openNav } from '@apps/www/src/www/reducers/ui';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useDispatch } from 'react-redux';
import SVPageMeta from './SVPageMeta';

const SVUserHeaderContainer = () => {
	const { isOwner, user } = useUserAndBoardPageQuery();
	const dispatch = useDispatch();
	const authSettings = useAuthSettings(['email', 'unreadNotificationsCount']);

	const handleOpenNav = useEventCallback(() => dispatch(openNav()));

	if (!user) {
		return null;
	}

	const meta = {
		title: user.name,
		image: user.ogImage,
	};

	return (
		<>
			<SVPageMeta {...meta} />
			<SVUserHeader
				asOwner={isOwner}
				user={user}
				unreadNotificationsCount={authSettings?.unreadNotificationsCount || 0}
				onOpenNav={handleOpenNav}
			/>
		</>
	);
};

export default SVUserHeaderContainer;
