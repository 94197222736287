import SVColorPickerOverlayContent from '@pkgs/shared-client/components/SVColorPickerOverlayContent';
import { useModalClose } from '@pkgs/shared-client/components/SVModal';
import SVOverlay from '@pkgs/shared-client/components/SVOverlay';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useRouter } from 'next/router';

const SVColorPickerOverlay = SVOverlay.create(
	() => {
		const close = useModalClose();
		const router = useRouter();
		const handleColorClick = useEventCallback((hexColor: string) => {
			close();

			router.push(`/search/?q=${encodeURIComponent(hexColor)}`);
		});

		return <SVColorPickerOverlayContent onClose={close} onColorClick={handleColorClick} />;
	},
	{ className: 'items-end bg-black/0' },
);

export default SVColorPickerOverlay;
