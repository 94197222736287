import { ensureArray } from '@pkgs/shared-client/helpers/array';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import objectKeys from '@pkgs/shared/helpers/objectKeys';
import difference from 'lodash/difference';
import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { useMount, useMountedState, useUnmount } from 'react-use';

const KEY_LABELS = {
	escape: '\u238B',
	backspace: '\u232B',
	arrowleft: '\u2190',
	arrowright: '\u2192',
	shift: '\u21E7',
	enter: '\u23CE',
	r: 'R',
	f: 'F',
	s: 'S',
	'=': '+',
	'-': '-',
	i: 'I',
	c: 'C',
	n: 'N',
	m: 'M',
	h: 'H',
	g: 'G',
} as const;

const IGNORE_ELEMENT_TAGS = ['input', 'textarea'] as const;
const ALLOWED_KEYS = objectKeys(KEY_LABELS);

type AllowedKeys = keyof typeof KEY_LABELS;

export type KeyboardKeys = AllowedKeys | Array<AllowedKeys>;

export const formatTitleWithKeys = (
	title: string | null | undefined,
	keys: KeyboardKeys | null | undefined,
) => {
	const mappedKeys = ensureArray(keys).map((key) => (key in KEY_LABELS ? KEY_LABELS[key] : key));

	const keysTitle = mappedKeys.length ? ` (${mappedKeys.join('+')})` : '';

	const combinedTitle = `${title || ''}${keysTitle}`;

	if (combinedTitle === '') {
		return undefined;
	}

	return combinedTitle;
};

const keyboardStack: number[] = [];
let keyboardContextID = 1;

const KeyboardContext = createContext<{
	isEnabled: () => boolean;
}>({
	isEnabled: () => false,
});

export const SVKeyboardContext = ({ children }: OnlyChildrenProps) => {
	const { current: contextID } = useRef(keyboardContextID++);

	const handleIsEnabled = useEventCallback(
		() => keyboardStack.length > 0 && keyboardStack[keyboardStack.length - 1] == contextID,
	);

	useMount(() => {
		keyboardStack.push(contextID);
	});

	useUnmount(() => {
		keyboardStack.splice(keyboardStack.indexOf(contextID), 1);
	});

	const contextValue = useMemo(
		() => ({
			isEnabled: handleIsEnabled,
		}),
		[handleIsEnabled],
	);

	return <KeyboardContext.Provider value={contextValue}>{children}</KeyboardContext.Provider>;
};

type Props = {
	keys: KeyboardKeys;
	onTrigger: (event: React.UIEvent) => void;
	isDisabled?: boolean;
};

const SVKeyboardKey = ({ keys, onTrigger, isDisabled }: Props) => {
	const isMounted = useMountedState();
	const keyboardContext = useContext(KeyboardContext);
	const { current: pressedKeys } = useRef<string[]>([]);

	const handleKeyDown = useEventCallback((event: KeyboardEvent) => {
		const key = (event && event.key && event.key.toLowerCase()) || null;

		if (!key || !ALLOWED_KEYS.includes(key) || pressedKeys.includes(key)) {
			return;
		}

		pressedKeys.push(key);

		if (
			key !== 'escape' &&
			event.target &&
			IGNORE_ELEMENT_TAGS.includes((event.target as HTMLElement).tagName.toLowerCase())
		) {
			return;
		}

		if (!isMounted()) {
			return;
		}

		const keysArray = ensureArray(keys);

		if (
			keysArray.length === pressedKeys.length &&
			difference(keysArray, pressedKeys).length === 0
		) {
			// console.log(
			// 	'TRIGGER',
			// 	keysArray,
			// 	keyboardContext.isEnabled(),
			// 	isDisabled,
			// );

			if (!isDisabled && keyboardContext.isEnabled()) {
				// Manually creates a React SyntheticEvent
				// TODO: Is there a better way to do this from React itself?
				const uiEvent: React.UIEvent = {
					bubbles: event.bubbles,
					cancelable: event.cancelable,
					currentTarget: event.currentTarget as Element,
					defaultPrevented: event.defaultPrevented,
					eventPhase: event.eventPhase,
					isTrusted: event.isTrusted,
					nativeEvent: event,
					target: event.target || document,
					timeStamp: event.timeStamp,
					type: event.type,
					isDefaultPrevented: () => event.defaultPrevented,
					isPropagationStopped: () => event.cancelBubble,
					preventDefault: () => event.preventDefault(),
					stopPropagation: () => event.stopPropagation(),
					persist: () => null,
					detail: 0,
					view: {
						styleMedia: {
							type: '',
							matchMedium: (_mediaquery: string) => false,
						},
						document,
					},
				};

				onTrigger(uiEvent);
			}
		}
	});

	const handleKeyUp = useEventCallback((event: KeyboardEvent) => {
		const key = (event && event.key && event.key.toLowerCase()) || null;

		if (!key || !ALLOWED_KEYS.includes(key)) {
			return;
		}

		pressedKeys.splice(pressedKeys.indexOf(key), 1);
	});

	const handleBlur = useEventCallback(() => {
		pressedKeys.splice(0, pressedKeys.length);
	});

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('keyup', handleKeyUp);
		window.addEventListener('blur', handleBlur);

		() => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('keyup', handleKeyUp);
			window.removeEventListener('blur', handleBlur);
		};
	}, [handleKeyDown, handleKeyUp, handleBlur]);

	return null;
};

export default SVKeyboardKey;
