import { type Board } from '@apps/www/src/__generated__/graphql';
import SVInviteUsersContainer from '@apps/www/src/www/containers/SVInviteUsersContainer';
import SVModal, { useModalClose } from '@pkgs/shared-client/components/SVModal';
import InviteType from '@pkgs/shared/enums/InviteType';

const _SVInviteUsersModal = (
	props: {
		maxUsersCount: number;
	} & (
		| {
				type: typeof InviteType.TEAM;
		  }
		| {
				type: typeof InviteType.BOARD;
				resourceID: Board['_id'];
		  }
	),
) => {
	const { maxUsersCount, ...otherProps } = props;
	const close = useModalClose();

	return (
		<>
			<SVModal.Title>
				{props.type === InviteType.TEAM ? 'Invite Members' : 'Invite Collaborators'}
			</SVModal.Title>
			<SVInviteUsersContainer
				maxUsersCount={maxUsersCount}
				{...otherProps}
				onSuccess={close}
			/>
		</>
	);
};

const SVInviteUsersModal = SVModal.create(_SVInviteUsersModal, {
	alignment: SVModal.ALIGNMENTS.TOP,
	className: 'w-[380px] -sm:w-full',
});

export default SVInviteUsersModal;
